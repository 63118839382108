export const m = {
	// 2.首页
	Homepage: 'Muka depan',
	// 4.开发者中心
	Product: 'Pusat Pembangun',
	// 4.开发者中心
	Developer: 'Pusat pembangun',
	// 5.业务登录
	Login: 'Log masuk perniagaan',
	// 6.商家注册
	Register: 'Pendaftaran peniaga',
	registration_number:"Masukkan Jawapan untuk soalan cabaran.",
	Confirm:"Sahkan",
	// 7.RGPay聚合支付/Payment
	title: 'Pembayaran/Pembayaran agregat RGPay',
	// 8.让支付接口对接前所未有的简单/
	slogan: 'Jadikan dok antara muka pembayaran lebih mudah berbanding sebelum ini.',
	explain: 'Tidak memerlukan pembangunan back-end, SDK boleh disambungkan kepada sistem pembayaran yang lengkap, integrasi berkelajuan tinggi antara muka pembayaran arus perdana, pembayaran terus dengan antara muka yang lebih stabil dan kelajuan yang lebih pantas',
	one: 'Stabil untuk masa yang lama',
	// 快速高效
	two: 'Cepat dan cekap',
	// 12.安全保障
	three: 'Keselamatan',
	// 多机房异地容灾系统，服务端可用性99.9%， 专业运维团队值守
	room: 'Sistem pemulihan bencana jauh berbilang bilik, ketersediaan pelayan 99.9%, operasi profesional dan pasukan penyelenggaraan yang bertugas.',
	time: '10 minit sambutan sangat pantas, perkhidmatan pelanggan profesional 1V1, sokongan teknikal 7*24 jam.',
	financial: 'Piawaian perlindungan keselamatan gred kewangan, rintangan yang kuat. Menentang pencerobohan luar dan menyokong urus niaga serentak yang tinggi.',
	major: 'Pembantu sokongan yang paling profesional.',
	APP: 'APLIKASI',
	H5: 'Halaman web mudah alih',
	web: 'Laman web PC',
	offline: 'Imbas kod luar talian',
	Service: 'Produk dan servis',
	docking: 'Dok kewangan',
	system: 'Sistem pesanan yang serupa, pendapatan dan perbelanjaan perusahaan adalah jelas sepintas lalu.',
	ss: 'Sistem sub-peniaga.',
	addss: 'Tambah akaun sub-pedagang untuk merealisasikan fungsi akaun berpecah untuk transaksi.',
	Interface: 'Aplikasi antara muka',
	cover: 'Liputan adegan sokongan penuh, sokongan antara muka pembayaran arus perdana.',
	qr: 'Pembayaran kod QR.',
	gathering: 'Alat koleksi profesional, satu kemestian untuk peniaga luar talian',
	customization: 'Penyelesaian Pembayaran Tersuai.',
	scene: 'Kaedah urus niaga yang menyokong senario perniagaan yang berbeza.',
	free: 'Analisis satu sama satu dalam talian percuma bagi senario pembayaran, menyusun keperluan pengumpulan perusahaan, membuat cadangan akses dan menyesuaikan penyelesaian pembayaran.',
	flow: 'Perundingan untuk butiran',
	individuation: 'Analisis Tersuai',
	follow: 'Susulan keperluan perniagaan tersuai sepanjang proses, gunakan pelayan tempatan perusahaan dan kawalan keselamatan data',
	team: 'Pasukan kejayaan pelanggan menyediakan 7*10 jam perkhidmatan dalam semua arah daripada penyahpepijatan bersama antara muka, ujian dan pelancaran kepada operasi dan penyelenggaraan pasca sistem, platform pengurusan dan penggunaan.',
	payment: 'Berhubung dengan berbilang antara muka pembayaran.',
	high: 'Berhubung dengan banyak antara muka pembayaran terbaik dalam industri untuk memastikan perniagaan lancar. Jadikan pembangunan antara muka pembayaran lebih mudah dan lebih mudah',
	open: 'Mulakan era baharu pembayaran sekarang.',
	facilitator: 'Pembayaran agregat RGPay, penyedia perkhidmatan teknologi pembayaran, buat pembayaran mudah, profesional dan pantas',
	// 现在加入
	join: 'Sertai sekarang',
	// 43.我要接入
	wantTo: 'Saya mahu menyertai',
	// 44.我们的伙伴
	OurPartners: 'Pasangan kami',
	// 47.联系方式
	method: 'Maklumat kenalan',
	// 48.产品项目
	chanpinXiangmu: 'Item produk',
	// 49.聚合收款
	receipts: 'Koleksi agregat',
	// 50.子商户系统
	Merchant: 'Sistem sub-peniaga',
	// 51.代付系统
	PaymentSystem: 'Sistem pembayaran',
	// 52.关于公司
	AboutCompany: 'Mengenai syarikat itu',
	// 53.关于我们
	AboutUs: 'Tentang kita',
	// 54.接口合作
	Cooperation: 'kerjasama antara muka',
	// 55.流量合作
	FlowCooperation: 'Kerjasama trafik',
	// 56.开发者
	Deverloperzq: 'Pemaju',
	// 57.DEMO体验
	DemoTrial: 'Pengalaman DEMO',
	// 58.API开发文档
	APIDevelopmentDocument: 'Dokumentasi pembangunan API',
	// 59.SDK下载
	SDKdownload: 'Muat turun SDK',
	// 60.扫一扫
	ScanCode: 'Mengimbasnya',
	// 61.Copyright © 2021 中南汇金 All rights reserved. 版权所有
	Copyright: 'Hak Cipta © 2023 RGPay Semua hak terpelihara. Hak cipta terpelihara.',
	// ------------------------------------------------------------------------------
	// Pusat Pembangun
	chanpin1: 'Pusat Produk',
	// 6.聚合支付SDK
	zhifuSDK2: 'SDK Pembayaran Agregat',
	// 无需重复集成繁琐
	fansuo3: "Pembangun tidak perlu berulang kali menyepadukan antara muka pembayaran yang menyusahkan, menggunakan SDK pengagregatan RGPay untuk mengakses semua kaedah pembayaran dengan mudah, menangani pelbagai senario pembayaran, dan pada masa yang sama mendapatkan perkhidmatan infrastruktur pembayaran yang cekap tinggi, selamat dan boleh dipercayai, membolehkan antara muka pembayaran menjana nilai",
	// 8.立即接入
	ljjieru4: "Akses sekarang",
	// 16.一码收款
	shoukuan5: "Koleksi agregat",
	// 手机APP支付
	shoujiapp6: "Koleksi agregat",
	// iOS/Android原生H5 App
	leibie7: "RGPay menyediakan penyelesaian pembayaran lengkap untuk iOS/Android asli/Apl H5: Sokongan:",
	// 19.手机APP支付
	shouji8: "Pembayaran APP mudah alih",
	// 20.公众号支付
	gongzhong9: "Pembayaran web mudah alih",
	// PC网页支付
	wangye10: "Pembayaran web PC",
	wangyezhifu: "Pembayaran web",
	// 22.线下扫码支付
	xianxia11: "Pembayaran kod imbasan luar talian",
	// 23.子商户系统
	zixitong12: "Sistem sub-peniaga",
	// 商户系统
	shanghu13: "Sistem sub-pedagang RGPay berorientasikan kepada platform, pengedaran, pengurusan rantaian bekalan dan jenis pedagang lain, dan menyokong produk pembayaran yang diuruskan oleh sub-pedagang berbilang peringkat Digabungkan dengan produk kutipan pembayaran agregat, pemisahan transaksi sub-peniaga utama yang percuma dan fleksibel boleh direalisasikan. Digabungkan dengan produk depositori dana, ia merealisasikan penjelasan dan",
	// 25.商户管理
	shnaghu14: "Pengurusan peniaga",
	// 26.交易查询
	jiaoyi15: "Siasatan urus niaga",
	// 27.财务对账
	caiwu16: "Penyesuaian kewangan",
	// 28.分润管理
	fenrun17: "Pengurusan perkongsian keuntungan",
	// 29.资金安全
	zijin18: "Keselamatan kewangan",
	// 完善的商户体系
	shanghu19: "Sediakan sistem pedagang yang lengkap, permudahkan organisasi pedagang, sediakan perhubungan pedagang secara fleksibel dalam senario yang kompleks, dan urus tahap pedagang dan maklumat asas dengan mudah.",
	// 31.了解更多
	liaojie20: "Lebih faham",
	// 代付系统
	daifu21: "Sistem pembayaran",
	// 接入所有主流
	zhuliu22: "Akses sekali ke semua antara muka pembayaran arus perdana, ketersediaan sistem 99.99%, untuk memenuhi keperluan adegan transaksi anda yang kaya dan untuk memberikan pengguna anda pengalaman pembayaran yang sempurna.",
	// 1.详细记录每一条交易信息
	jiaoyi23: "Maklumat terperinci tentang setiap transaksi",
	// 全方位、多方面
	fangwei24: "Statistik ringkasan data transaksi yang komprehensif dan berbilang pihak",
	// 深度分析
	fenxi25: "Menganalisis hasil dan nilai maklumat bagi setiap transaksi",
	// 精细化的财务展示
	caiwu26: "Paparan kewangan yang diperhalusi, pengauditan yang cekap",
	// 5.清晰的数据逻辑和准确的财务数据
	shuju27: "Data logik yang jelas dan data kewangan yang tepat",
	// 松实现内部对账
	duixhang28: "Mudah merealisasikan perdamaian dalaman, perdamaian pedagang dan penyesuaian antara muka untuk mengurangkan kos kewangan",
	// 10.接口放资金直清
	jiekou29: "Kosongkan dana terus melalui antara muka",
	// 11. 提供支付接口对接服务
	fuwu30: "RGPay menyediakan perkhidmatan antara muka pembayaran",
	// 资金由接口方进行清算
	qingsuan31: "Dana dijelaskan oleh pihak antara muka, RGPay menyediakan perkhidmatan teknikal antara muka pembayaran, selamat dan terjamin",
	// 32.代付系统
	daifu32: "Sistem pembayaran",
	// 入所有主流支付接口
	ruliu33: "Akses sekali kepada semua antara muka pembayaran arus perdana, ketersediaan sistem 99.99%, untuk memenuhi keperluan transaksi anda yang kompleks dan memberikan pengguna anda pengalaman pembayaran yang sempurna.",
	// 一个 API，在任何平台和场景
	changjign34: "API untuk mengakses fungsi pembayaran pada mana-mana platform dan kes. sokongan:",
	// 立即联系
	liji35: "Hubungi sekarang",
	// 36.二维码支付
	zhifu36: "Pembayaran kod QR",
	// 38.扫二维码支付
	sao37: "Imbas kod QR untuk membayar",
	// 线下商户二维码台卡
	xinxia38: "Kad kod QR pedagang luar talian, pembayaran mudah diterima dan operasi harian pedagang adalah jelas sepintas lalu. Dari pengumpulan, menyemak aliran, menyemak bil, dsb., peniaga dan pengguna berhubung rapat.",
	// 44.立即开户
	kauhu39: "Buka akaun sekarang",
	// 45.联系我们
	lianxi40: "Hubungi Kami",
	// 联系方式
	lianxifangshi41: "Butiran kenalan",
	// 47.产品项目
	chanpin42: "Item produk",
	// 48.聚合收款
	juhe43: "Koleksi agregat",
	// 49.子商户系统
	zishanghu44: "Sistem sub-peniaga",
	// 50.代付系统
	dsifu45: "Sistem pembayaran",
	// 52.关于我们
	guanyu46: "Tentang kita",
	// 接口合作
	jiekou47: "kerjasama antara muka",
	// 51.关于公司
	gognsi48: "Mengenai syarikat itu",
	// 54.流量合作
	liuliang49: "Kerjasama trafik",
	// 开发者
	kaifa50: "Pemaju",
	// 56.DEMO体验
	demo51: "Pengalaman DEMO",
	// 57.API开发文档
	kaifa52: "Dokumentasi pembangunan API",
	// 58.SDK下载
	xiazai53: "Muat turun SDK",
	// 扫一扫
	sao54: "Mengimbasnya",
	// 版权所有
	banquan55: "Hak Cipta © 2023 RGPay Semua hak terpelihara.",
	// 33.详情咨询
	zixin56: "Perundingan untuk butiran",
	// 40.现在加入
	jiaru57: "Sertai sekarang",
	// 当前语言
	language: "Malay",
	// 菜单-------------------------------------------
	// 2.首页
	shouye58: "Muka depan",
	// Pusat Pembangun/
	shanpin59: "Pusat Pembangun",
	// 开发者中心
	kaifa60: "Pusat Pembangun",
	// 5.业务登录
	yewu61: "Log masuk perniagaan",
	// 6.商家注册
	zhuce62: "Pendaftaran peniaga",
	// 商户注册----------------------------------------
	// 1.商户注册
	// shnahghu63: "Merchant sign in",
	shnahghu63: " Pendaftaran Peniaga",
	// 2.请输入akaun
	shuru64: "Sila masukkan akaun.",
	// 3.请输入密码
	mima65: "Sila masukkan kata laluan",
	// 4.请再次输入密码
	again66: "Sila masukkan kata laluan sekali lagi",
	// 5.请输入您的Email地址
	email67: "Sila masukkan alamat E-mel anda",
	// 6.注册
	zhuce68: "Mendaftar",
	// 7.已有akaun？点这里-->登录
	you69: "Sudah mempunyai akaun? Klik di sini --> Log masuk ",
	// 登录
	denlu70: "Log masuk",
	// 业务登录页面---------------------------------------------------------------------
	// 2.商户登录
	shanghu71: "Log masuk pedagang",
	// 3.请输入akaun
	shuru72: "Sila masukkan akaun.",
	// 4.请输入密码
	mima73: "Sila masukkan kata laluan",
	// 7.代理登录
	daili74: "Log masuk proksi",
	// 11.还没有akaun？立即注册
	meiyou75: "Belum mempunyai akaun? Daftarlah sekarang",
	// 注册
	zhuce76: "Mendaftar.",
	//开发者中心---------------------------------
	// 1.开发者中心
	kaifa77: "Pusat Pembangun",
	// DEMO体验
	demo78: "Pengalaman DEMO",
	// 商品名称
	shangpin79: "Nama Produk",
	// 4.测试订单
	ceshi80: "Perintah ujian",
	// 5.订单编号
	dingdan81: "Nombor pesanan",
	// 6.订单金额
	jine82: "Jumlah pesanan",
	// 支付方式
	zhifu83: "Kaedah pembayaran",
	// 8.支付宝扫码
	saoma84: "Kod imbasan Alipay",
	// 9.微信扫码
	weixin85: "Kod imbasan WeChat",
	// 10.微信h5
	h586: "Wechat h5",
	// 11.QQ钱包扫码
	qianbao87: "Kod imbasan dompet QQ",
	// 12.百度钱包
	baidu88: "Dompet Baidu",
	// 13.银联快键
	yinlian89: "Kunci Pantas UnionPay",
	// 14.在线网银
	zaixian90: "Perbankan atas talian",
	// 15.需要支付
	xuyao91: "Perlu bayar",
	// 16.立即支付
	liji92: "Bayar segera",
	// 17.Copyright © 2021 聚合支付 版权所有
	// 18.API开发文档
	kaifa93: "Dokumentasi pembangunan API",
	// 19.API开发文档简介
	wendang94: "Pengenalan kepada Dokumentasi Pembangunan API",
	// 20.接入网关
	jieru95: "Laluan masuk",
	// 21.签名算法
	xianming96: "Algoritma tandatangan",
	// 22.商户统一下单API
	shanghu97: "API pesanan bersatu pedagang",
	// 23.异步通知
	// 61.单位：元
	yuan98: "RM",
	// 支付体验收银台
	tiyan99: "Juruwang pengalaman pembayaran",
	// 253.SDK下载
	xiazai100: "Muat turun SDK",
	// 越南语
	// yuenan101:""
	// 关于我们-------------------------------------------------------------------------------
	// 1.关于我们
	women102: "Tentang kita",
	// 联系我们
	lianxi103: "Hubungi Kami",
	// 4.企业QQ
	qiyeqq104: "QQ Perusahaan",
	// 5.客服QQ
	kefuqq105: "QQ perkhidmatan pelanggan",
	// 6.咨询热线
	zixun106: "Sokongan hotline",
	// 7.联系邮箱
	lainxi107: "E-mel hubungan:",
	// 加我领红包
	jiawo108: "Tambah saya untuk menerima sampul merah",
	// 8.公司地址：广州海珠区新港东路1888号中洲交易中心
	adress109: "Alamat syarikat",
	// 支付公司合作
	hezuo110: "Kerjasama syarikat pembayaran",
	// 11.支付公司合作敬请联系客服人员
	kefu111: "Kerjasama syarikat pembayaran, sila hubungi kakitangan perkhidmatan pelanggan",
	// 国内领先的支付技术解决方案...
	wenan112: "RGPay ialah penyedia perkhidmatan penyelesaian teknologi pembayaran terkemuka di China, yang berdedikasi untuk membantu perusahaan mengakses satu set sistem pembayaran yang elegan dan boleh dipercayai pada kos terendah serta data visual untuk membantu mereka menyelesaikan keputusan perniagaan.",
	// 13.立即联系
	lianxi113: "Hubungi sekarang",
	// 流量合作
	liuliang114: "Kerjasama trafik",
	// 15.支付流量合作敬请联系客服人员
	kefu115: "Sila hubungi kakitangan perkhidmatan pelanggan untuk kerjasama trafik pembayaran",
	// 提供支付接口代申请服务
	fuwu116: "RGPay menyediakan perkhidmatan aplikasi antara muka pembayaran, dan memanjangkan data antara muka pembayaran kepada nilai maksimum, membantu setiap rakan perniagaan menyelesaikan masalah sambungan pembayaran",
	// 17.联系客服
	kefu117: "Hubungi Khidmat Pelanggan",
	// ----------------------------------------------
	// 用户登录和注册语言提示
	// 1.请输入用户名
	// userName118:"Xin nhập tên người dùng",
	userName118: "Sila masukkan nama pengguna",
	// 2. 请输入密码
	// password119:"Xin nhập mật khẩu",
	password119: "Sila masukkan kata laluan",
	// 3. 请重复输入密码
	// argin120:"Xin nhập lại mật khẩu",
	argin120: "Sila masukkan kata laluan sekali lagi",
	// 4. 请输入邮箱
	// email121:"Xin nhập email"
	email121: "Sila masukkan email anda",
	// 5. 两次输入不一致
	// errorTow:"Hai lần nhập không giống nhau"
	errorTow122: "Kedua-dua entri itu tidak konsisten",
	// 邮件格式错误，请重新输入
	// VN: Định dạng email bị lỗi, vui lòng nhập lại. 
	errorMessage123: "Ralat format e-mel, sila cuba lagi.",
	// ----------------------
	// 手机支付页面
	// -CN: 保存此二维码图片
	baocun124: "Simpan imej kod QR ini",
	// -CN: 充值内容
	chognzhi125: "Isi semula kandungan",
	// -CN: 复制
	copy126: "Salinan",
	// -CN: 到期时间
	daoqi127: "Tarikh luput",
	// CN: QR码只可使用一次
	one128: "Kod QR hanya boleh digunakan sekali sahaja",
	// CN: 请填写充值内容：P2987S（此外，请勿写其他任何东西，以免Zalo Pay钱包出现延误
	qr129: "Sila isi kandungan cas semula: P2987S (Selain itu, sila jangan tulis apa-apa lagi untuk mengelakkan kelewatan dalam dompet RGPay)",
	// ------------------------------------------
	// 开发者文档页面
	// API开发文档简介
	wandang130: "Pengenalan kepada Dokumentasi Pembangunan API",
	// 本文阅读对象：
	duixiang131: "Pembaca artikel ini: arkitek teknikal, jurutera R&D dan jurutera operasi dan penyelenggaraan sistem yang menggunakan sistem layan diri pedagang RGPay. Melalui dokumen ini, peniaga boleh belajar tentang teknologi akses RGPay, produk dan perkhidmatan akses, prosedur akses, spesifikasi akses dan maklumat lain, supaya peniaga dapat menyelesaikan kerja akses dengan jayanya..",
	// 接入网关
	wanggaun132: "Laluan masuk",
	// 请登录商户中心...
	shanghu133: "Sila log masuk ke Merchant Center dan lihat alamat get laluan dan parameter sambungan API dalam dokumen pembangunan pengurusan API.",
	// 204.签名算法
	shuanfa134: "Algoritma tandatangan",
	// 205.签名生成的通用步骤如下：
	buzou135: "Langkah-langkah umum untuk penjanaan tandatangan adalah seperti berikut:",
	// 第一步，设所有发.....
	first136: "Langkah pertama ialah menetapkan semua data yang dihantar atau diterima sebagai set M, mengisih parameter nilai parameter bukan nol dalam set M mengikut nama parameter kod ASCII daripada kecil kepada besar (tertib leksikografi), dan gunakan kekunci URL -format pasangan nilai (iaitu key1 =value1&key2=value2...) disambungkan ke dalam rentetan.",
	// 第二步，在stringA...
	two138: "Langkah kedua ialah menggunakan kekunci pada penyambungan terakhir stringA untuk mendapatkan rentetan stringSignTemp, lakukan operasi MD5 pada stringSignTemp, dan kemudian tukar semua aksara rentetan yang diperoleh kepada huruf besar untuk mendapatkan nilai tanda signValue.",
	// 209.提交方式：POST
	post139: 'Kaedah penyerahan: POST',
	// 35.地址： 接入网关/Pay_Index.html
	wangguan140: "Alamat: Access Gateway/Pay_Index.html",
	// 63.异步通知
	yubu141: "Pemberitahuan tak segerak",
	// 接收到服务器点对点通讯时
	tongxun142: "Apabila menerima komunikasi peer-to-peer daripada pelayan, keluarkan 'OK' pada halaman (tanpa tanda petikan berganda, OK dua huruf besar), jika tidak pemberitahuan peer-to-peer akan dihantar 5 kali.",
	// 85.订单查询接口
	chaxun143: "Antara muka pertanyaan pesanan",
	// 87.地址： 接入网关/Pay_Trade_query.html
	dizhi144: "Akses Gateway/Pay_Trade_query.html",
	// 账户余额查询接口
	zhuanghu145: "Antara muka pertanyaan baki akaun",
	// 210.接口地址：网关地址+/Payment_Dfpay_balance.html
	dizhi146: "Alamat antara muka: alamat gerbang+/Payment_Dfpay_balance.html",
	// 238.渠道编码
	qudao147: "Kod saluran",
	// 32.请登录商户中心，API管理 开发文档中 查看网关地址和API对接参数。
	canshu148: "Sila log masuk ke Merchant Center dan lihat alamat get laluan dan parameter sambungan API dalam dokumen pembangunan pengurusan API.",
	// 订单支付接口
	dingdan149: "Antara muka pembayaran pesanan",
	// 252.API开发文档
	wendang150: "Dokumentasi pembangunan API",
	// 注册成功,请等待管理员审核.
	shenhe151: "Pendaftaran berjaya, sila tunggu pentadbir menyemak",
	// 服务器内部错误
	error152: "Ralat pelayan dalaman",
	// 此页面无法访问
	wufa153: "Halaman ini tidak boleh diakses",
	// ----------------------------------------------------------
	// 支付相关页面
	// -剩余时间：544秒
	timeOut154: "Baki masa: 544 saat",
	// -请输入验证码已经发短信到您的手机或者用银行应用登录名：NONE
	shuru155: "Sila masukkan kod pengesahan dan ia telah dihantar ke telefon bimbit anda atau gunakan nama log masuk aplikasi bank: TIADA",
	// -请注意，OTP执行时间仅为60秒
	miao156: "Ambil perhatian bahawa masa pelaksanaan OTP hanya 60 saat",
	// -输入OTP验证码
	yanzheng157: "Masukkan kod pengesahan OTP",
	// -下一步
	xiayibu158: "Langkah seterusnya",
	// Lưu ý 备注  Note
	beizhu159: "Setiap kod QR atau maklumat deposit hanya boleh digunakan sekali. Jika anda menggunakannya semula, transaksi mungkin hilang dan ia akan mengambil masa 1 minggu untuk diproses. Sila ikut arahan deposit untuk mengelakkan kesilapan. terima kasih.",
	// 1.交易编号
	bianhao160: "Nombor transaksi",
	// 交易时间
	shijian161: "Jam transaksi",
	// 金额
	jine162: "Jumlah",
	// hasil
	jieguo163: "Hasil",
	// 没有输入信息
	xinxi164: "Tiada maklumat dimasukkan",
	// Thời gian时间
	shijian165: "Masa",
	// 交易代码
	daima166: "Kod transaksi",
	// -请等待您的交易被处理
	wait167: "Tunggu sehingga transaksi anda diproses",
	// -充值金额
	jine168: "Jumlah cas semula:",
	// -充值编号
	bianhao169: "Nombor cas semula",
	// -交易成功时间
	shijian170: "Masa kejayaan transaksi:",
	// -检查交易状态
	zhuangtai171: "Semak status transaksi.",
	// 支付成功
	success172: "Pembayaran berjaya",
	// 支付失败
	shibai173: "Pembayaran gagal",
	// 银行
	bank174: "bank",
	// 支付类型
	leixing175: "Jenis Pembayaran",

	////////////////////////////////////////////////////////////////////////////////////////////
	// 文档中心新修改文档翻译 不支持英文
	/////////////
	// 目录
	// 1.接口规范
	jiekou176: "Spesifikasi antara muka",
	// 2. 签名规范
	jianming177: "Spesifikasi Tandatangan",
	// 3. 请求规范
	qingqiu178: "Spesifikasi permintaan",
	// 4. 服务器接口列表
	jiekou179: "Senarai Antaramuka Pelayan",
	// 5. 创建支付订单
	chuangjian180: "Create payment order",
	// 6. 查询支付订单
	chaxun181: "Query payment order",
	// 7. 支付hasil异步通知
	zhifu182: "Asynchronous notification of payment result",
	// 8. 关闭订单
	guanbi183: "Close order",
	// 9. 获取支付渠道列表
	gongong184: "Get a list of payment channels",
	// 10.公共返回码
	fulu185: "Public return code",
	// 11.附录
	add11fulu: "Appendix",
	// 目录结束 文档开始
	// 商户接入系统时，调用API 必须遵守以下规则
	// 1.接口规范
	guizhe186: "When a merchant accesses the system, calling the API must comply with the following rules：",
	// 2. 签名规范
	jianming187: "Step 2: Sort the data to be signed according to the ASCII code of the parameter name from small to large (following the dictionary system); then use & concatenate in the form of a key-value pair (key=value) to form a complete string (eg: A =123&B=456) as StringA: sort the data to be signed according to the ASCII code of the parameter name from small to large (following the dictionary system); then use & concatenate in the form of a key-value pair (key=value) to form a complete string (eg: A=123&B=456) as StringA；",
	// 第二步
	er188: "Langkah 2: Tambahkan kekunci yang sepadan pada penghujung StringA (cth: A=123&B=456&key=123456) untuk mendapatkan StringB;",
	// 第三步
	san189: "Langkah 3: Lakukan pengiraan MD5 pada StringB, kemudian tukar rentetan yang diperoleh kepada huruf besar, dan nilai yang diperoleh ialah nilai simbol ",
	// 举例：
	juli190: "Contoh:",
	// 现有以下数据需要签名：
	xianyou191: "Data berikut perlu ditandatangani: ",
	// username：admin password：123456 timestamp：1234567890 nonceStr：e134b0c1d94
	// 签名 secret_key:**************** (请向技术人员索取) 
	username192: "Nama pengguna: admin ",
	password193: "Kata laluan: 123456 ",
	timestamp194: "cap masa：1234567890",
	nonceStr195: "nonceStr：e134b0c1d94",
	secret_key196: "Tandatangan secret_key: **************** (sila minta juruteknik untuknya)",
	// 第一步：排序并拼接字符串
	pinjie197: "Langkah 1: Isih dan gabungkan rentetan",
	juli198: "StringA =”password=123456&timestamp=1234567890&nonceStr=e134b0c1d94&username=admin”",
	// 第二步：最后拼接上 key 字段
	ziduan199: "Langkah 2: Akhir sekali sambung medan kunci",
	pinjie200: "StringB = StringA + ”&key=****************”",
	// 第三步：MD5 计算，并转为大写
	md5201: "Langkah 3: Pengiraan MD5 dan tukar kepada huruf besar",
	// Sign = strtoupper( md5(StringB) ) 
	Sign202: "Sign = strtoupper( md5(StringB) ) ",
	//////////////////////////////////////////////////////////////////////////////////
	// 3.请求规范 
	guifan203: "1)、Kaedah permintaan ialah: POST, yang menyokong tiga jenis kandungan: form-data, x-www-form-urlencoded, raw(json);",
	guifen204: "2)、Bahasa yang disokong pada masa ini: Vietnam (vi), Inggeris (en-Us), Cina (zh-cn) Cuma tambahkan lebih banyak parameter apabila meminta: lang=vi atau lang=en-Us atau lang=zh-cn。",
	/////////////////////////////////////////////////////////////////////////////////////////
	// 4.服务器接口列表 
	// 服务器 
	fuwu205: "Pelayan ",
	// 地址 
	dizhi206: "Alamat",
	// 接口网关
	wangguan207: "Gerbang antara muka",
	// 版本
	banban208: "Versi",
	// 请求范例
	guifan209: "Minta contoh",
	fanli210: "1. Alamat permintaan ialah: gerbang antara muka+versi+alamat antara muka" + '\n',
	fanli211: "2. Contohnya untuk membuat pesanan pembayaran: https://api.rg-pay.com/v1/pay",
	//////////////////////////////////////////////////////////////////////////////////////////
	// 5.创建支付订单 
	dizhi212: "Buat pesanan pembayaran ",
	// 请求参数：
	qingqiu213: "Parameter permintaan: ",
	// 表格
	// 表头标题--参数 /参数名/必须/描述
	canshu214: "parameter ",
	canshuming215: "nama parameter",
	bixu216: "mesti",
	miaoshu217: "huraikan",
	// 第二列商户id/支付订单号/支付时间/支付通道ID/异步回调地址/页面跳转地址/支付金额/当前UNIXcap masa/随机串/商品描述/支付类型/内容类型/签名
	// 1-7
	lie218: "ID Pedagang",
	lie219: "nombor pesanan pembayaran",
	lie220: "Masa pembayaran",
	lie221: "ID saluran pembayaran",
	lie222: "Alamat panggilan balik tak segerak",
	lie223: "Alamat lompat halaman",
	lie224: "Sila tanya juruteknik",
	// 8-13
	lie225: "Cap masa Vietnam semasa dalam beberapa saat",
	lie226: "Rentetan rawak",
	lie227: "Untuk butiran, sila lihat maklumat lampiran di hujung dokumen",
	lie228: "Selepas pembayaran diserahkan, data status pembayaran akan dipos secara tak segerak ke alamat",
	lie229: "Jenis kandungan",
	lie230: "Tanda",
	// 第三列   是/否
	yes231: "Ya",
	no232: "Tidak",
	// 第四列
	// 1-7
	// 请向技术人员索取
	suoqu233: "Sila tanya juruteknik untuk",
	// 如：20210602101815555456
	ru234: "Seperti: 20210602101815555456",
	// 如：2021-05-31 10:56:22
	ru235: "Seperti: 31-05-2021 10:56:22",
	// 具体请查看文档最后的附录信息
	juti236: "Untuk butiran, sila rujuk maklumat lampiran di hujung dokumen",
	// 支付提交后会把支付状态数据异步POST数据到该地址
	zhifu237: "Selepas pembayaran diserahkan, data status pembayaran akan dipos secara tak segerak ke alamat ini",
	// 当有传递该值时，支付提交后会跳转回该地址（注：状态数据会拼到该地址的URI部分里）
	chuandi238: "Apabila nilai ini diluluskan, ia akan melompat kembali ke alamat selepas pembayaran diserahkan (Nota: data status akan dieja ke dalam bahagian URI alamat)",
	// 越南盾(RM)，单位：元
	dun239: "Dong Vietnam (RM), Unit: Yuan",
	// 8-13
	//  以秒为单位的当前越南cap masa，如：1622429782
	danweimiao240: "Cap masa Vietnam semasa dalam beberapa saat, cth: 1624229782",
	// 随机字符串，长度为10-32位字符，如：nkkMTAkLkR4U
	zifuchuan241: "Rentetan rawak dengan panjang 10-32 aksara, seperti: nkkMTAkLkR4U",
	// 商品描述，如：充值
	chognzhi242: "Penerangan produk, seperti: cas semula",
	// 传递1为网页支付、传递2为扫码支付
	cuandi243: "Pas 1 ialah pembayaran web, pas 2 ialah pembayaran kod imbasan",
	// 异步回调返回数据内容类型，可选值：1、multipart/form-data（默认）2、application/x-www-form-urlencoded3、text/plain
	huidiao244: "Panggilan balik tak segerak mengembalikan jenis kandungan data, nilai pilihan: " + '\n' + "1, multipart/form-data (default)" + '\n' + "2, application/x-www-form-urlencoded3, teks / biasa" + '\n' + "3. application/json atau text/plain (kedua-dua json)",
	// 签名生成算法请查看签名规范（注：除了该参数不参与签名外，其它参数均参与签名）
	qianming245: "Untuk algoritma penjanaan tandatangan, sila rujuk spesifikasi tandatangan (Nota: Kecuali parameter ini tidak mengambil bahagian dalam tandatangan, semua parameter lain mengambil bahagian dalam tandatangan)",

	// 返回参数：
	fenhui246: "Parameter pulangan:  ",
	// 返回码 /返回消息 /返回数据 
	fanhuima247: "kembalikan kod",
	fanhuixiaoxi248: "membalas mesej",
	fanhuishuju249: "mengembalikan data",
	// 1-5
	// 0代表成功，1代表失败，其它请看公共返回码
	daibiao250: "0 bermakna kejayaan, 1 bermakna kegagalan, yang lain sila rujuk kod pulangan awam",
	// 返回消息，如：success 
	success251: "Kembalikan mesej seperti :: kejayaan",
	// 成功时必定返回以下数据 
	successdata252: "Apabila berjaya, data berikut mesti dikembalikan",
	// 带有订单ID的支付页面网址，商户拿到该地址后直接跳转即可。
	dingdan253: "URL halaman pembayaran dengan ID pesanan, pedagang boleh melompat terus selepas mendapat alamat",
	// 平台支付订单号，如：20210602101815555456
	pingtai254: "Nombor pesanan pembayaran platform, seperti: 20210602101815555456",
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 6.查询支付订单 
	// 当付完款之后，返回支付错误或支付中时，建议每间隔 3 秒调用本接口来获取当前订单最新的支付状态，直至支........
	fukuan255: " Selepas pembayaran selesai, apabila pembayaran salah atau pembayaran dikembalikan, adalah disyorkan untuk menghubungi antara muka ini setiap 3 saat untuk mendapatkan status pembayaran terkini pesanan semasa, sehingga pembayaran berjaya, pembayaran berjaya; atau pesanan ditutup secara aktif.",
	// 接口地址：/pay/query 
	jikeou256: "Alamat antara muka: /pay/query  ",
	// 	// 目录6表格1-请求参数： 
	mulu6: {
		// 参数名 (nombor perniagaan /支付订单号/随机串 /cap masa /签名 )
		canshuming: {
			mulu6shanghu257: "nombor perniagaan",
			mulu6dingdanhao258: "nombor pesanan pembayaran",
			mulu6sunjicuan259: "rentetan rawak",
			mulu6shijiancuo260: "cap masa",
			mulu6qianming261: "tanda"
		},
		// 描述 ()
		miaoshu: {
			suoqu262: "Sila tanya juruteknik",
			li263: "Cth: 20210602101815555456",
			zifucuan264: "Rentetan rawak, panjang 10-32 aksara, contohnya: nkkMTAkLkR4U",
			shijiancuo265: "Cap masa Vietnam semasa dalam beberapa saat, contohnya: 1624229782",
			suanfa267: "Sila rujuk spesifikasi tandatangan untuk algoritma penjanaan tandatangan (Nota: Kecuali parameter ini tidak mengambil bahagian dalam tandatangan, semua parameter lain mengambil bahagian dalam tandatangan)"
		}
	},
	// 目录6表格2-返回参数：
	mulu6_2: {
		// 表格名--返回参数
		fanhuicanshu268: "Parameter pulangan: ",
		canshufanhui: {
			column1: {},
			column2: {
				data1: "kembalikan kod",
				data2: "mesej yang dikembalikan",
				data3: "mengembalikan data",
				data4: "nombor perniagaan",
				data5: "nombor pesanan pembayaran ",
				data6: "Jumlah pembayaran",
				data7: "Masa kejayaan/kegagalan pembayaran",
				data8: "Nombor pesanan platform",
				data9: "kaedah pembayaran",
				data10: "status permintaan",
				data11: "status bayaran ",
				data12: "rentetan rawak",
				data13: "cap masa",
				data14: "tanda",
			},
			column3: {},
			column4: {
				data1: "0 bermakna kejayaan, 1 bermakna kegagalan, yang lain sila lihat kod pulangan awam",
				data2: "Mengembalikan maklumat, seperti: kejayaan",
				data3: "Data berikut mesti dikembalikan apabila berjaya",
				data4: "Sila tanya juruteknik",
				data5: "Nombor pesanan peniaga",
				data6: "Dong Vietnam (RM), Unit: Yuan",
				data7: "Seperti: 31-05-2021 10:56:22",
				data8: "Seperti: 20210602101815555456",
				data9: "1 adalah pembayaran dalam talian, 2 ialah pembayaran kod imbasan",
				data10: "00 bermakna kejayaan, lain bermakna kegagalan",
				data11: "NORGPay-Belum Bayar / NORGPay-Belum Bayar / USERPAYING-Membayar / BAYARAN BALIK-Pindahan / DIBATALKAN-Pengeluaran / TUTUP-Tutup / PEMBAYAR-Ralat Pembayaran",
				data12: "Rentetan rawak, panjang 10-32 aksara, contohnya: nkkMTAkLkR4U",
				data13: "Cap masa, sebagai contoh: 1624229782",
				data14: "Sila rujuk spesifikasi tandatangan untuk algoritma penjanaan tandatangan (Nota: Kecuali parameter ini tidak mengambil bahagian dalam tandatangan, semua parameter lain mengambil bahagian dalam tandatangan)",
			}
		}
	},
	///////////////////////////////////////////////////////////////////
	// 目录7.支付hasil异步通知
	inform7_269: {
		title1: "Jika parameter pay_notifyurl dipilih dalam antara muka pesanan, apabila pelanggan berjaya membayar, pelayan platform akan menolak data hasil pembayaran ke alamat ini dan pedagang perlu menerima dan memprosesnya. Apabila berinteraksi dengan pemberitahuan latar belakang, jika platform menerima respons daripada pedagang yang bukan rentetan tulen SUCCESS atau kembali selepas lebih daripada 5 saat, platform menganggap pemberitahuan itu gagal dan platform akan menggunakan strategi tertentu (pemberitahuan kekerapan ialah 0/5/10/15/20 /25/30...86400, unit: saat) Secara tidak langsung memulakan semula pemberitahuan untuk meningkatkan kadar kejayaan pemberitahuan sebanyak mungkin, tetapi tidak ada jaminan bahawa pemberitahuan akan akhirnya berjaya. Jika anda tidak menerima pemberitahuan untuk masa yang lama, adalah disyorkan untuk menghubungi borang pertanyaan.",
		title2: "Disebabkan oleh penghantaran semula pemberitahuan latar belakang, pemberitahuan yang sama mungkin dihantar ke sistem pedagang beberapa kali. Sistem pedagang mesti dapat mengendalikan pemberitahuan pendua dengan betul. Amalan yang disyorkan ialah menyemak status data perniagaan yang sepadan apabila menerima pemberitahuan pemprosesan, untuk menentukan sama ada pemberitahuan telah diproses, jika tidak, memprosesnya dan jika diproses, kembalikan hasilnya secara langsung. Sebelum semakan status dan pemprosesan data perniagaan, kunci data hendaklah digunakan untuk kawalan serentak bagi mengelakkan kekeliruan data yang disebabkan oleh kemasukan semula fungsi. ",
		title3: "Nota Khas: Selepas pedagang menerima parameter pemberitahuan, ID pesanan dan amaun dalam parameter pemberitahuan yang diterima mesti disahkan dengan pesanan dan amaun dalam sistem perniagaan, dan status pesanan pangkalan data boleh dikemas kini hanya selepas pengesahan adalah konsisten. Dan pastikan anda mengesahkan tandatangan yang dikembalikan untuk memastikan ketepatan mesej. ",
		// 通知参数
		tongzhi4: "Parameter pemberitahuan:",
		column2: {
			val1: "nombor perniagaan",
			val2: "nombor pesanan",
			val3: "Jumlah pembayaran",
			val4: "Masa kejayaan/kegagalan pembayaran",
			val5: "Nombor pesanan platform",
			val6: "kaedah pembayaran",
			val7: "status permintaan",
			val8: "status bayaran ",
			val9: "rentetan rawak",
			val10: "cap masa",
			val11: "tanda",
		},
		column4: {
			val1: "Sila tanya juruteknik",
			val2: "Nombor pesanan peniaga",
			val3: "Dong Vietnam (RM), Unit: Yuan",
			val4: "Seperti: 31-05-2021 10:56:22",
			val5: "Seperti: 20210602101815555456",
			val6: "1 adalah pembayaran dalam talian, 2 ialah pembayaran kod imbasan",
			val7: "00 bermakna kejayaan, lain bermakna kegagalan",
			val8: "NORGPay-Belum Bayar / NORGPay-Belum Bayar / USERPAYING-Membayar / BAYARAN BALIK-Pindahan / DIBATALKAN-Pengeluaran / TUTUP-Tutup / PEMBAYAR-Ralat Pembayaran",
			val9: "Rentetan rawak, panjang 10-32 aksara, contohnya: nkkMTAkLkR4",
			val10: "cap masa， Seperti: 1624229782",
			val11: "Sila rujuk spesifikasi tandatangan untuk algoritma penjanaan tandatangan (Nota: Kecuali parameter ini tidak mengambil bahagian dalam tandatangan, semua parameter lain mengambil bahagian dalam tandatangan)",
		},
		title4: "Apabila membuat pesanan, ia akan ditolak ke alamat pay_notifyurl dengan permintaan POST mengikut format data yang diluluskan dalam medan content_type. Apabila membuat pesanan, ia akan ditolak ke alamat pay_notifyurl dengan permintaan POST mengikut format data diluluskan dalam medan content_type."
	},
	////////////////////////////////////////////
	// 目录8.关闭订单 
	CloseTheOrder8_270: {
		title1: "Apabila masa menunggu terlalu lama, pelanggan boleh mengambil inisiatif untuk menutup pesanan untuk mengelakkan caj berlebihan pelanggan。 ",
		title2: "Alamat antara muka: /pay/close",
		title3: "Parameter permintaan:",
		table: {
			column2: {
				val1: "nombor perniagaan ",
				val2: "nombor pesanan pembayaran",
				val3: "rentetan rawak",
				val4: "cap masa",
				val5: "tanda"
			},
			column4: {
				val1: "Sila tanya juruteknik",
				val2: "Seperti: 20210602101815555456",
				val3: "Rentetan rawak, panjang 10-32 aksara, seperti nkkMTAkLkR4U",
				val4: "cap masa Seperti: 1624229782",
				val5: "Sila rujuk spesifikasi tandatangan untuk algoritma penjanaan tandatangan (Nota: Kecuali parameter ini tidak mengambil bahagian dalam tandatangan, semua parameter lain mengambil bahagian dalam tandatangan)",
			}
		},
		title4: "Parameter pulangan: ",
		tableTwo: {
			column2: {
				val1: "kembalikan kod",
				val2: "mesej yang dikembalikan",
			},
			column4: {
				val1: "0 bermakna kejayaan, 1 bermakna kegagalan, yang lain sila lihat kod pulangan awam",
				val2: "mesej yang dikembalikan"
			}
		}
	},

	// 目录9.获取支付渠道列表
	common_9: {
		title1: "alamat antara muka:/pay/product",
		title2: "Parameter pulangan:",
		table1: {
			column2: {
				val1: "nombor perniagaan",
				val2: "rentetan rawak",
				val3: "cap masa",
				val4: "tanda",
			},
			column4: {
				val1: "Sila tanya juruteknik",
				val2: "Rentetan rawak, panjang 10-32 aksara, seperti nkkMTAkLkR4U",
				val3: "cap masa Seperti: 1624229782",
				val4: "Sila rujuk spesifikasi tandatangan untuk algoritma penjanaan tandatangan (Nota: Kecuali parameter ini tidak mengambil bahagian dalam tandatangan, semua parameter lain mengambil bahagian dalam tandatangan)",
			}
		},
		table2: {
			column2: {
				val1: "kembalikan kod",
				val2: "mesej yang dikembalikan",
				val3: "mengembalikan data",
				val4: "jumlah bilangan baris",
				val5: "senarai data",
				val6: "ID Saluran",
				val7: "nama saluran",
				val8: "logo saluran",
				val9: "kaedah pembayaran",
				val10: "pengekodan tahap rendah",
			},
			column4: {
				val1: "0 bermakna kejayaan, 1 bermakna kegagalan, yang lain sila lihat kod pulangan awam",
				val2: "mesej yang dikembalikan",
				val3: "Data berikut mesti dikembalikan apabila berjaya",
				val4: "Mengembalikan jumlah bilangan baris data",
				val5: "Data senarai yang dikembalikan mempunyai medan berikut:",
				val6: "ID Saluran, cth: 926",
				val7: "Nama saluran, cth: Vietcombank",
				val8: "URL Logo Saluran",
				val9: "Kaedah pembayaran semasa yang disokong oleh bank, 1 ialah pembayaran dalam talian, 2 ialah pembayaran kod imbasan, dan gandaan dipisahkan dengan koma, seperti: 1, 2",
				val10: "kod gabungan bank, atau batal jika tiada",
			}
		}
	},
	/////////////////////////////////////////////////////////////////
	// 目录10
	returnCode10: {
		biaptou1: "kembalikan kod",
		biaptou2: "huraikan",
		biaptou3: "Program",
		table1: {
			column1: {
				val1: "0",
				val2: "1",
				val3: "100-600",
			},
			column2: {
				val1: "kejayaan",
				val2: "gagal dengan mesej kegagalan",
				val3: "Kod status HTTP yang sepadan, sila semak untuk mendapatkan butiran: https://zh.wikipedia.org/wiki/HTTP%E7%8A%B6%E6%80%81%E7%A0%81",
			}
		}
	},
	/////////////////////////////////////////////////////
	// 目录11
	appendix11: {
		title1: "Saluran pembayaran:",
		// 表格标题:
		headline1: "ID Saluran",
		headline2: "nama saluran",
		headline3: "Teguran",
		table1: {
			column1: {
				val1: "926",
				val2: "927",
				val3: "928",
				val4: "929",
				val5: "930",
				val6: "931",
				val7: "932",
				val8: "933",
				val9: "934",
				val10: "935",
				val11: "936",
				val12: "937",
				val13: "938",
				val14: "939",
				val15: "940",
				val16: "941",
				val17: "942",
				val18: "943",
			},
			column2: {
				val1: "926",
				val2: "927",
				val3: "928",
				val4: "929",
				val5: "930",
				val6: "931",
				val7: "932",
				val8: "933",
				val9: "934",
				val10: "935",
				val11: "936",
				val12: "937",
				val13: "938",
				val14: "939",
				val15: "940",
				val16: "941",
				val17: "942",
				val18: "943",
			}
		}
	},
	// 目录1-接口规格
	specification: {
		table1: {
			column1: {
				val1: "Kaedah pemindahan",
				val2: "Cara menghantar",
				val3: "Format Data ",
				val4: "Pengekodan aksara",
				val5: "Algoritma tandatangan",
			},
			column2: {
				val1: "Pengangkutan menggunakan HTTPS",
				val2: "Hantar menggunakan kaedah POST",
				val3: "Sokongan data penyerahan dan pengembalian: form-data, x-www-form-urlencoded, format mentah (json), nota: sila gunakan format data borang semasa menyerahkan. Jika anda menggunakan format lain, tetapkan nilai Jenis Kandungan dalam pengepala kepada x-www-form-urlencoded atau application/json.",
				val4: "Gunakan pengekodan aksara UTF-8",
				val5: "Gunakan MD5, lihat 2 untuk butiran",
			}
		}
	},
	// 支付页面的翻译validateTheInput.vue
	validateTheInput: {
		// 1.请输入用户名
		userNameNotHave: "Sila masukkan nama pengguna",
		// 2.请输入密码
		ppp: "Sila masukkan kata laluan",
		// 3.请输入OTP校验码
		peoc: "Sila masukkan kod pengesahan OTP",
		// -----------------------------------------------------
		jine: "jumlah",
		// 事务处理时间
		Transaction: "jam transaksi",
		// .参考代码
		Reference: "Kod rujukan",
		// 登入
		Log: "Log masuk",
		// 验证码
		Verification: "kod pengesahan",
		Result: "hasil",
		Account: "akaun.",
		Password: "kata laluan",
		// Remain: "Remain time 176 seconds",
		Remain90: "masa yang tinggal ",
		Remain91: "kedua",
		// 11-
		Remark: "Teguran",
		// 提示
		rishi501: "Sila masukkan akaun dan kata laluan yang betul. Bila mengesahkan, sila pilih pengesahihan SMS OTP dan aplikasi bank untuk pengesahihan klik. Sila ikut arahan deposit untuk mengelakkan ralat. Terima kasih. Terima kasih.",
		OTPcode: "Sila masukkan otp",
		// 请输入otp
		otpEnter: "Sila masukkan OTP dalam aplikasi SMS atau bank anda, atau klik pada Sahkan:",
		// 按钮下一步
		next: "Langkah seterusnya",
		// 请稍等。您的交易正在进行中
		transaction: "Sila tunggu. Urus niaga anda sedang dijalankan",
		// 检查事务状态
		Check: "Semak status transaksi",
		// 支付失败
		failed502: " Pembayaran gagal",
		// 请稍等。您的交易正在进行中
		waiting503: "Sila tunggu. Urus niaga anda sedang dijalankan",
		success504: "pembayaran berjaya",
		jieguo505: "hasil",
		shibai506: "Masa masuk tamat"

	},
	transfer: {
		// 通过银行转账进行付款
		transfer: "Pembayaran melalui pemindahan bank",
		// 网银转账
		banking: "Pemindahan bank dalam talian",
		// 扫码转账
		payment507: "Imbas kod QR untuk memindahkan wang",
		// akaun
		zhanghao508: "akaun",
		// 账户名字,
		mingzi509: "nama akaun",
		// 分行
		fenhang510: "cawangan",
		// 金额
		jine511: "jumlah",
		// 内容
		neirong512: "kandungan",
		// 完成
		wanchang513: "Selesai",
		// 还有176秒
		shnegyu514: "Masa yang tinggal ",
		// 转账成功后， 请点击完成按钮， 以便我们尽快处理交易。 感谢您使用我们的服务。
		fuwu515: "Selepas pemindahan berjaya, sila klik butang Selesai supaya kami boleh memproses transaksi secepat mungkin. Terima kasih kerana menggunakan perkhidmatan kami.",
		// 执行转移命令 -
		zhuanyi516: "melaksanakan perintah pemindahan",
		// 访问银行的网上银行或手机银行网站进行转账 -
		fangwen517: "-Lawati laman web perbankan dalam talian atau perbankan mudah alih bank untuk memindahkan wang",
		// 以下内容中的存款代码(GODETOXD8X0ZTK) 自生成后仅在 20 分钟内有效。 -
		youxiao518: "-Kod deposit masuk ",
		jiade5181: "Sejak generasi sahaja",
		jiade5182: "Sah dalam beberapa saat.",
		// 请在下面填写正确的信息以立自生成后仅在即更新资金。 -
		dengxin519: "-Sila isikan maklumat yang betul di bawah untuk mengemas kini dana hanya sebaik sahaja ia dijana.",
		// 注意： 请输入正确的 40,
		// 000 RM 金额， 并选择转账费用“ 人 / 转账单位” 形式以接收准确金额。 如果没有匹配项， 您的订单将被取消。 任何问题请联系客户支持。
		quxiao520: "- Nota: Sila masukkan yang betul ",
		quxiao5201: "Dan pilih borang 'Orang/Unit Pemindahan' yuran pemindahan untuk menerima jumlah yang tepat. Jika tiada padanan, pesanan anda akan dibatalkan. Sebarang pertanyaan sila hubungi sokongan pelanggan",
		// 秒
		miao521: "Kedua",
		// -------------------------
		// 内容必须有
		bixu522: "kandungan",
		// 二维码仅供本次充值使用，请勿保存以备日后充值使用。
		shiyong523: "Kod QR hanya digunakan untuk cas semula ini, sila jangan simpan untuk cas semula masa hadapan.",
		// 二维码不能重复用二维码仅供本次充值使用，请勿保存以备日后充值使用
		chongfu524: "Jangan gunakan semula kod QR ini",
		// 打开QRPay在手机网银APPSila isikan maklumat yang betul di bawah untuk mengemas kini dana hanya sebaik sahaja ia dijana
		wangyin525: "Buka QRPay dalam APP perbankan dalam talian mudah alih ",
		// 扫二维码
		erweima526: "Imbas kod QR",
		// 确认金额完成交易
		wancheng527: "Sahkan jumlah untuk menyelesaikan transaksi.",
		// 复制成功
		fail528: "Berjaya menyalin",
		// 复制失败
		succsee529: "Replikasi gagal",
		// 复制
		fuzhi530: "Salinan",
		// ----------------------------
		// 超时/
		chaoshi531: "Masa tamat",
		// 失败
		shibai532: "Gagal",
		// 成功
		chenggong534: "kejayaan",
		result: 'Hasil'
	}
}
