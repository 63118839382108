export const m = {
	// 2.首页
	Homepage: '首页',
	// 4.开发者中心
	Product: '产品中心',
	// 4.开发者中心
	Developer: '开发者中心',
	// 5.业务登录
	Login: '业务登录',
	// 6.商家注册
	Register: '商家注册',
	// 7.RGPay聚合支付/Payment
	registration_number:"输入挑战问题的答案。",
	Confirm:"确认",
	title: 'RGPay 聚合支付',
	// 8.让支付接口对接前所未有的简单/
	slogan: '让支付接口对接前所未有的简单.',
	explain: '无需后端开发，一个SDK即可接入一套完整的支付系统，高速集成主流支付接口，以更稳定的接口，更快的速度直达支付',
	one: '稳定待久',
	// 快速高效
	two: '快速高效',
	// 12.安全保障
	three: '安全保障',
	// 多机房异地容灾系统，服务端可用性99.9%， 专业运维团队值守
	room: '多机房异地容灾系统，服务端可用性99.9%， 专业运维团队值守.',
	time: '10分钟超快速响应，1V1专业客服服务，7*24小时技术支持.',
	financial: '金融级安全防护标准，强有力抵。御外部入侵，支持高并发交易.',
	major: '最专业的支持帮手.',
	APP: 'APP',
	H5: '移动端网页',
	web: 'PC网页',
	offline: '线下扫码',
	Service: '产品与服务',
	docking: '财务对接',
	system: '相近的订单系统，企业收支一目了然.',
	ss: '子商户系统.',
	addss: '添加子商户账号，为交易实现分账功能.',
	Interface: '接口申请',
	cover: '全支持场景覆盖，主流支付接口支持.',
	qr: '二维码支付.',
	gathering: '专业收款工具，线下商户经营必备',
	customization: '定制化支付解决方案.',
	scene: '支持不同业务场景的交易方式.',
	free: '免费在线一对一分析支付场景，梳理企业收款需求，提出接入建议，定制支付解决方案.',
	flow: '详情咨询',
	individuation: '定制分析',
	follow: '全程跟进定制化业务需求，可部署企业本地服务器，数据安全可控',
	team: '客户成功团队从接口联调，测试上线到后期系统运维，管理平台，使用等各方向全面提供7*10小时服务.',
	payment: '对接多家支付接口.',
	high: '对接行业内最优质的多家支付接口，全力保障业务流畅。让支付接口开发更加简单方便',
	open: '立即开启支付新时代.',
	facilitator: 'RGPay聚合支付，支付技术服务商，让支付简单，专业，快捷',
	// 现在加入
	join: '现在加入',
	// 43.我要接入
	wantTo: '我要接入',
	// 44.我们的伙伴
	OurPartners: '我们的伙伴',
	// 47.联系方式
	method: '我们的伙伴',
	// 48.产品项目
	chanpinXiangmu: '产品项目',
	// 49.聚合收款
	receipts: '聚合收款',
	// 50.子商户系统
	Merchant: '子商户系统',
	// 51.代付系统
	PaymentSystem: '代付系统',
	// 52.关于公司
	AboutCompany: '关于公司',
	// 53.关于我们
	AboutUs: '关于我们',
	// 54.接口合作
	Cooperation: '接口合作',
	// 55.流量合作
	FlowCooperation: '流量合作',
	// 56.开发者
	Deverloperzq: '开发者',
	// 57.DEMO体验
	DemoTrial: 'DEMO体验',
	// 58.API开发文档
	APIDevelopmentDocument: 'API开发文档',
	// 59.SDK下载
	SDKdownload: 'SDK下载',
	// 60.扫一扫
	ScanCode: '扫一扫',
	// 61.Copyright © 2021 中南汇金 All rights reserved. 版权所有
	Copyright: 'Copyright © 2023 RGPay All rights reserved. 版权所有.',
	// ------------------------------------------------------------------------------
	// 产品中心
	chanpin1: '产品中心',
	// 6.聚合支付SDK
	zhifuSDK2: '聚合支付SDK',
	// 无需重复集成繁琐
	fansuo3: "开发者无需重复集成繁琐的支付接口,使用 RGPay 聚合SDK轻松接入所有支付方式,应对各类支付场景,同时获得高效能,安全可靠的支付基础设施服务，让支付接口产生价值",
	// 8.立即接入
	ljjieru4: "立即接入",
	// 16.一码收款
	shoukuan5: "聚合收款",
	// 手机APP支付
	shoujiapp6: "聚合收款",
	// iOS/Android原生H5 App
	leibie7: "RGPay 为iOS/Android原生/H5 App提供全套支付解决方案：支持：",
	// 19.手机APP支付
	shouji8: "手机APP支付",
	// 20.公众号支付
	gongzhong9: "移动端网页支付",
	// PC网页支付
	wangye10: "PC网页支付",
	wangyezhifu: "网页支付",
	// 22.线下扫码支付
	xianxia11: "线下扫码支付",
	// 23.子商户系统
	zixitong12: "子商户系统",
	// 商户系统
	shanghu13: "RGPay子商户系统是面向平台、分销、供应链管理等类型商户，支持多级子商户管的支付产品通过子商户系统，可实现对代理商、分销商、分店、供应商等角色进行分管理与聚合收款产品相结合，实现自由灵活的主子商户交易分账。与资金存管产品相结合，实现为主子商户进行清分结算",
	// 25.商户管理
	shnaghu14: "商户管理",
	// 26.交易查询
	jiaoyi15: "交易查询",
	// 27.财务对账
	caiwu16: "财务对账",
	// 28.分润管理
	fenrun17: "分润管理",
	// 29.资金安全
	zijin18: "资金安全",
	// 完善的商户体系
	shanghu19: "提供完善的商户体系,易化商户结构梳理，灵活组建复杂场景下的商户关系,便捷的管理商户层级和基本信息.",
	// 31.了解更多
	liaojie20: "了解更多",
	// 代付系统
	daifu21: "代付系统",
	// 接入所有主流
	zhuliu22: "一次接入所有主流支付接口，99.99% 系统可用性，满足你丰富的交易场景需求,为你的用户提供完美支付体验.",
	// 1.详细记录每一条交易信息
	jiaoyi23: "详述每笔交易信息",
	// 全方位、多方面
	fangwei24: "全面多方的交易数据汇总统计",
	// 深度分析
	fenxi25: "分析每笔交易的收入和信息价值",
	// 精细化的财务展示
	caiwu26: "精细化财务显示，高效查账",
	// 5.清晰的数据逻辑和准确的财务数据
	shuju27: "清晰的逻辑数据和准确的财务数据",
	// 松实现内部对账
	duixhang28: "轻松实现内部对账、商户对账、接口对账，降低财务成本",
	// 10.接口放资金直清
	jiekou29: "通过接口直接清算资金",
	// 11. 提供支付接口对接服务
	fuwu30: "RGPay提供支付接口服务",
	// 资金由接口方进行清算
	qingsuan31: "资金由接口方清算，RGPay提供支付接口技术服务，安全放心",
	// 32.代付系统
	daifu32: "支付系统",
	// 入所有主流支付接口
	ruliu33: "一次接入所有主流支付接口，99.99%的系统可用性，满足您复杂的交易需求，为您的用户提供完美的支付体验.",
	// 一个 API，在任何平台和场景
	changjign34: "用于访问任何平台和案例上的支付功能的 API。 支持：",
	// 立即联系
	liji35: "立即联系",
	// 36.二维码支付
	zhifu36: "二维码支付",
	// 38.扫二维码支付
	sao37: "扫二维码支付",
	// 线下商户二维码台卡
	xinxia38: "线下商户二维码台卡，收款方便，商户日常经营一目了然从收款、查看流水、核对账单、等等，将商户与消费者紧密相连.",
	// 44.立即开户
	kauhu39: "立即开户",
	// 45.联系我们
	lianxi40: "联系我们",
	// 联系方式
	lianxifangshi41: "联系方式",
	// 47.产品项目
	chanpin42: "产品项目",
	// 48.聚合收款
	juhe43: "聚合收款",
	// 49.子商户系统
	zishanghu44: "子商户系统",
	// 50.代付系统
	dsifu45: "代付系统",
	// 52.关于我们
	guanyu46: "关于我们",
	// 接口合作
	jiekou47: "接口合作",
	// 51.关于公司
	gognsi48: "关于公司",
	// 54.流量合作
	liuliang49: "流量合作",
	// 开发者
	kaifa50: "开发者",
	// 56.DEMO体验
	demo51: "DEMO体验",
	// 57.API开发文档
	kaifa52: "API开发文档",
	// 58.SDK下载
	xiazai53: "SDK下载",
	// 扫一扫
	sao54: "扫一扫",
	// 版权所有
	banquan55: " Copyright © 2021 RGPay All rights reserved. 版权所有",
	// 33.详情咨询
	zixin56: "详情咨询",
	// 40.现在加入
	jiaru57: "现在加入",
	// 当前语言
	language: "中文",
	// 菜单-------------------------------------------
	// 2.首页
	shouye58: "首页",
	// 产品中心/
	shanpin59: "产品中心",
	// 开发者中心
	kaifa60: "开发者中心",
	// 5.业务登录
	yewu61: "业务登录",
	// 6.商家注册
	zhuce62: "商家注册",
	// 商户注册----------------------------------------
	// 1.商户注册
	// shnahghu63: "Merchant sign in",
	shnahghu63: " 商户注册",
	// 2.请输入账号
	shuru64: "请输入账号.",
	// 3.请输入密码
	mima65: "请输入密码",
	// 4.请再次输入密码
	again66: "请再次输入密码",
	// 5.请输入您的Email地址
	email67: "请输入您的Email地址",
	// 6.注册
	zhuce68: "注册",
	// 7.已有账号？点这里-->登录
	you69: "已有账号？点这里-->登录 ",
	// 登录
	denlu70: "登录",
	// 业务登录页面---------------------------------------------------------------------
	// 2.商户登录
	shanghu71: "商户登录",
	// 3.请输入账号
	shuru72: "请输入账号.",
	// 4.请输入密码
	mima73: "请输入密码",
	// 7.代理登录
	daili74: "代理登录",
	// 11.还没有账号？立即注册
	meiyou75: "还没有账号？立即注册",
	// 注册
	zhuce76: "注册.",
	//开发者中心---------------------------------
	// 1.开发者中心
	kaifa77: "开发者中心",
	// DEMO体验
	demo78: "DEMO体验",
	// 商品名称
	shangpin79: "商品名称",
	// 4.测试订单
	ceshi80: "测试订单",
	// 5.订单编号
	dingdan81: "订单号",
	// 6.订单金额
	jine82: "订单金额",
	// 支付方式
	zhifu83: "支付方式",
	// 8.支付宝扫码
	saoma84: "支付宝扫码",
	// 9.微信扫码
	weixin85: "微信扫码",
	// 10.微信h5
	h586: "微信h5",
	// 11.QQ钱包扫码
	qianbao87: "QQ钱包扫码",
	// 12.百度钱包
	baidu88: "百度钱包",
	// 13.银联快键
	yinlian89: "银联快键",
	// 14.在线网银
	zaixian90: "在线网银",
	// 15.需要支付
	xuyao91: "需要支付",
	// 16.立即支付
	liji92: "立即支付",
	// 17.Copyright © 2021 聚合支付 版权所有
	// 18.API开发文档
	kaifa93: "API开发文档",
	// 19.API开发文档简介
	wendang94: "API开发文档简介",
	// 20.接入网关
	jieru95: "接入网关",
	// 21.签名算法
	xianming96: "签名算法",
	// 22.商户统一下单API
	shanghu97: "商户统一下单API",
	// 23.异步通知
	// 61.单位：元
	yuan98: "RM",
	// 支付体验收银台
	tiyan99: "支付体验收银台",
	// 253.SDK下载
	xiazai100: "SDK下载",
	// 越南语
	// yuenan101:""
	// 关于我们-------------------------------------------------------------------------------
	// 1.关于我们
	women102: "关于我们",
	// 联系我们
	lianxi103: "联系我们",
	// 4.企业QQ
	qiyeqq104: "企业QQ",
	// 5.客服QQ
	kefuqq105: "客服QQ",
	// 6.咨询热线
	zixun106: "咨询热线",
	// 7.联系邮箱
	lainxi107: "联系邮箱:",
	// 加我领红包
	jiawo108: "加我领红包",
	// 8.公司地址：广州海珠区新港东路1888号中洲交易中心
	adress109: "公司地址",
	// 支付公司合作
	hezuo110: "支付公司合作",
	// 11.支付公司合作敬请联系客服人员
	kefu111: "支付公司合作敬请联系客服人员",
	// 国内领先的支付技术解决方案...
	wenan112: "RGPay是一款出色的支付网关，可支持财务转移和各种银行业需求。对于个人或电子商务欲寻找可靠且功能丰富的支付网关，RGPay称得上是绝佳选择。您可同时享有财务管理，数据设施以及端对端付款服务，无论是本地或是国际付款方式。",
	// 13.立即联系
	lianxi113: "立即联系",
	// 流量合作
	liuliang114: "流量合作",
	// 15.支付流量合作敬请联系客服人员
	kefu115: "支付流量合作敬请联系客服人员",
	// 提供支付接口代申请服务
	fuwu116: "RGPay付款提供各种付款方式，例如信用卡，借記卡和银行在线转账，在安全上並具有強大的欺詐预防措施。",
	// 17.联系客服
	kefu117: "联系客服",
	// ----------------------------------------------
	// 用户登录和注册语言提示
	// 1.请输入用户名
	// userName118:"Xin nhập tên người dùng",
	userName118: "请输入用户名",
	// 2. 请输入密码
	// password119:"Xin nhập mật khẩu",
	password119: "请输入密码",
	// 3. 请重复输入密码
	// argin120:"Xin nhập lại mật khẩu",
	argin120: "请重复输入密码",
	// 4. 请输入邮箱
	// email121:"Xin nhập email"
	email121: "请输入邮箱",
	// 5. 两次输入不一致
	// errorTow:"Hai lần nhập không giống nhau"
	errorTow122: "两次输入不一致",
	// 邮件格式错误，请重新输入
	// VN: Định dạng email bị lỗi, vui lòng nhập lại. 
	errorMessage123: "邮件格式错误，请重新输入.",
	// ----------------------
	// 手机支付页面
	// -CN: 保存此二维码图片
	baocun124: "保存此二维码图片",
	// -CN: 充值内容
	chognzhi125: "充值内容",
	// -CN: 复制
	copy126: "复制",
	// -CN: 到期时间
	daoqi127: "到期时间",
	// CN: QR码只可使用一次
	one128: "QR码只可使用一次",
	// CN: 请填写充值内容：P2987S（此外，请勿写其他任何东西，以免Zalo Pay钱包出现延误
	qr129: "请填写充值内容：P2987S（此外，请勿写其他任何东西，以免RGPay钱包出现延误)",
	// ------------------------------------------
	// 开发者文档页面
	// API开发文档简介
	wandang130: "API开发文档简介",
	// 本文阅读对象：
	duixiang131: "本文阅读对象：使用RGPay 商户自服务系统的技术架构师、研发工程师、系统运维工程师。通过本文档，商户可了解RGPay 接入的技术、接入的产品业务、接入的流程、接入规范等信息，以便于商户顺利完成接入工作.",
	// 接入网关
	wanggaun132: "接入网关",
	// 请登录商户中心...
	shanghu133: "请登录商户中心，API管理 开发文档中 查看网关地址和API对接参数",
	// 204.签名算法
	shuanfa134: "签名算法",
	// 205.签名生成的通用步骤如下：
	buzou135: "签名生成的通用步骤如下:",
	// 第一步，设所有发.....
	first136: "第一步，设所有发送或者接收到的数据为集合M，将集合M内非空参数值的参数按照参数名ASCII码从小到大排序（字典序），使用URL键值对的格式（即key1=value1&key2=value2…）拼接成字符串.",
	// 第二步，在stringA...
	two138: "第二步，在stringA最后拼接上应用key得到stringSignTemp字符串，并对stringSignTemp进行MD5运算，再将得到的字符串所有字符转换为大写，得到sign值signValue.",
	// 209.提交方式：POST
	post139: '提交方式：POST',
	// 35.地址： 接入网关/Pay_Index.html
	wangguan140: "地址： 接入网关/Pay_Index.html",
	// 63.异步通知
	yubu141: "异步通知",
	// 接收到服务器点对点通讯时
	tongxun142: "接收到服务器点对点通讯时，在页面输出“OK”（ 没有双引号，OK 两个字母大写 ) ，否则会重复5次发送点对点通知.",
	// 85.订单查询接口
	chaxun143: "订单查询接口",
	// 87.地址： 接入网关/Pay_Trade_query.html
	dizhi144: "接入网关/Pay_Trade_query.html",
	// 账户余额查询接口
	zhuanghu145: "账户余额查询接口",
	// 210.接口地址：网关地址+/Payment_Dfpay_balance.html
	dizhi146: "接口地址：网关地址+/Payment_Dfpay_balance.html",
	// 238.渠道编码
	qudao147: "渠道编码",
	// 32.请登录商户中心，API管理 开发文档中 查看网关地址和API对接参数。
	canshu148: "请登录商户中心，API管理 开发文档中 查看网关地址和API对接参数.",
	// 订单支付接口
	dingdan149: "订单支付接口",
	// 252.API开发文档
	wendang150: "API开发文档",
	// 注册成功,请等待管理员审核.
	shenhe151: "注册成功,请等待管理员审核",
	// 服务器内部错误
	error152: "服务器内部错误",
	// 此页面无法访问
	wufa153: "此页面无法访问",
	// ----------------------------------------------------------
	// 支付相关页面
	// -剩余时间：544秒
	timeOut154: "剩余时间：544秒",
	// -请输入验证码已经发短信到您的手机或者用银行应用登录名：NONE
	shuru155: "请输入验证码已经发短信到您的手机或者用银行应用登录名：NONE",
	// -请注意，OTP执行时间仅为60秒
	miao156: "请注意，OTP执行时间仅为60秒",
	// -输入OTP验证码
	yanzheng157: "输入OTP验证码",
	// -下一步
	xiayibu158: "下一步",
	// Lưu ý 备注  Note
	beizhu159: "每个二维码或存款信息只能使用一次，如果再次使用有可能会丢失交易，需要1周的处理时间。 请遵循存款说明以避免错误。 谢谢。 ",
	// 1.交易编号
	bianhao160: "交易编号",
	// 交易时间
	shijian161: "交易时间",
	// 金额
	jine162: "金额",
	// 结果
	jieguo163: "结果",
	// 没有输入信息
	xinxi164: "没有输入信息",
	// Thời gian时间
	shijian165: "时间",
	// 交易代码
	daima166: "交易代码",
	// -请等待您的交易被处理
	wait167: "等待您的交易被处理",
	// -充值金额
	jine168: "充值金额:",
	// -充值编号
	bianhao169: "充值编号",
	// -交易成功时间
	shijian170: "交易成功时间:",
	// -检查交易状态
	zhuangtai171: "检查交易状态.",
	// 支付成功
	success172: "支付成功",
	// 支付失败
	shibai173: "支付失败",
	// 银行
	bank174: "银行",
	// 支付类型
	leixing175: "支付类型",

	////////////////////////////////////////////////////////////////////////////////////////////
	// 文档中心新修改文档翻译 不支持英文
	/////////////
	// 目录
	// 1.接口规范
	jiekou176: "接口规范",
	// 2. 签名规范
	jianming177: "签名规范",
	// 3. 请求规范
	qingqiu178: "请求规范",
	// 4. 服务器接口列表
	jiekou179: "服务器接口列表",
	// 5. 创建支付订单
	chuangjian180: "创建支付订单",
	// 6. 查询支付订单
	chaxun181: "查询支付订单",
	// 7. 支付结果异步通知
	zhifu182: "支付结果异步通知",
	// 8. 关闭订单
	guanbi183: "关闭订单",
	// 9. 获取支付渠道列表
	gongong184: "获取支付渠道列表",
	// 10.公共返回码
	fulu185: "公共返回码",
	// 11.附录
	add11fulu: "附录",
	// 目录结束 文档开始
	// 商户接入系统时，调用API 必须遵守以下规则
	// 1.接口规范
	guizhe186: "商户接入系统时，调用API 必须遵守以下规则：",
	// 2. 签名规范
	jianming187: "第二步：将要签名的数据按照参数名的ASCII码从小到大排序（遵循字典系统）； 然后以键值对（key=value）的形式使用&连接，形成一个完整的字符串（如：A=123&B=456）作为StringA：将要签名的数据按照参数名的ASCII码从小到大排序（遵循字典系统）； 然后以键值对（key=value）的形式使用&连接，形成一个完整的字符串（如：A=123&B=456）作为StringA；",
	// 第二步
	er188: "第二步：在StringA的末尾加上对应的key（如：A=123&B=456&key=123456）得到StringB；",
	// 第三步
	san189: "第三步：对StringB进行MD5计算，然后将得到的字符串转换为大写，得到的值为符号值 ",
	// 举例：
	juli190: "举例：",
	// 现有以下数据需要签名：
	xianyou191: "现有以下数据需要签名: ",
	// username：admin password：123456 timestamp：1234567890 nonceStr：e134b0c1d94
	// 签名 secret_key:**************** (请向技术人员索取) 
	username192: "用户名：admin ",
	password193: "密码：123456 ",
	timestamp194: "timestamp：1234567890",
	nonceStr195: "nonceStr：e134b0c1d94",
	secret_key196: "签名 secret_key:**************** (请向技术人员索取)",
	// 第一步：排序并拼接字符串
	pinjie197: "第一步：排序并拼接字符串",
	juli198: "StringA =”password=123456&timestamp=1234567890&nonceStr=e134b0c1d94&username=admin”",
	// 第二步：最后拼接上 key 字段
	ziduan199: "第二步：最后拼接上 key 字段",
	pinjie200: "StringB = StringA + ”&key=****************”",
	// 第三步：MD5 计算，并转为大写
	md5201: "第三步：MD5 计算，并转为大写",
	// Sign = strtoupper( md5(StringB) ) 
	Sign202: "Sign = strtoupper( md5(StringB) ) ",
	//////////////////////////////////////////////////////////////////////////////////
	// 3.请求规范 
	guifan203: "1)、请求方式为：POST，支持三种内容类型：form-data、x-www-form-urlencoded、raw(json);",
	guifen204: "2)、语言目前支持：越南语 (vi)、英语 (en-Us)、中文(zh-cn) 请求时只需添加更多参数：lang=vi 或 lang=en-Us或lang=zh-cn。",
	/////////////////////////////////////////////////////////////////////////////////////////
	// 4.服务器接口列表 
	// 服务器 
	fuwu205: "服务器 ",
	// 地址 
	dizhi206: "地址",
	// 接口网关
	wangguan207: "接口网关",
	// 版本
	banban208: "版本",
	// 请求范例
	guifan209: "请求范例",
	fanli210: "1. 请求地址为：接口网关+版本+接口地址" + '\n',
	fanli211: "2. 例如创建支付订单：https://api.rg-pay.com/v1/pay",
	//////////////////////////////////////////////////////////////////////////////////////////
	// 5.创建支付订单 
	dizhi212: "创建支付订单 ",
	// 请求参数：
	qingqiu213: "请求参数： ",
	// 表格
	// 表头标题--参数 /参数名/必须/描述
	canshu214: "参数 ",
	canshuming215: "参数名",
	bixu216: "必须 ",
	miaoshu217: "描述",
	// 第二列商户id/支付订单号/支付时间/支付通道ID/异步回调地址/页面跳转地址/支付金额/当前UNIX时间戳/随机串/商品描述/支付类型/内容类型/签名
	// 1-7
	lie218: "商户ID",
	lie219: "支付订单号",
	lie220: "支付时间",
	lie221: "支付通道ID",
	lie222: "异步回调地址",
	lie223: "页面跳转地址",
	lie224: "请询问技术人员",
	// 8-13
	lie225: " 以秒为单位的当前越南时间戳",
	lie226: "随机字符串",
	lie227: "详情请查看文档末尾的附录信息",
	lie228: "支付提交后，支付状态数据会异步POST到地址",
	lie229: "内容类型",
	lie230: "签名",
	// 第三列   是/否
	yes231: "是",
	no232: "否",
	// 第四列
	// 1-7
	// 请向技术人员索取
	suoqu233: "请向技术人员索取",
	// 如：20210602101815555456
	ru234: "如: 20210602101815555456",
	// 如：2021-05-31 10:56:22
	ru235: "如: 2021-05-31 10:56:22",
	// 具体请查看文档最后的附录信息
	juti236: "具体请查看文档最后的附录信息",
	// 支付提交后会把支付状态数据异步POST数据到该地址
	zhifu237: "支付提交后会把支付状态数据异步POST数据到该地址",
	// 当有传递该值时，支付提交后会跳转回该地址（注：状态数据会拼到该地址的URI部分里）
	chuandi238: "当有传递该值时，支付提交后会跳转回该地址（注：状态数据会拼到该地址的URI部分里）",
	// 越南盾(RM)，单位：元
	dun239: "越南盾(RM)，单位：元",
	// 8-13
	//  以秒为单位的当前越南时间戳，如：1622429782
	danweimiao240: " 以秒为单位的当前越南时间戳, 如: 1624229782",
	// 随机字符串，长度为10-32位字符，如：nkkMTAkLkR4U
	zifuchuan241: "随机字符串，长度为10-32位字符，如: nkkMTAkLkR4U",
	// 商品描述，如：充值
	chognzhi242: "商品描述，如：充值",
	// 传递1为网页支付、传递2为扫码支付
	cuandi243: "传递1为网页支付、传递2为扫码支付",
	// 异步回调返回数据内容类型，可选值：1、multipart/form-data（默认）2、application/x-www-form-urlencoded3、text/plain
	huidiao244: "异步回调返回数据内容类型，可选值：" + '\n' + "1、multipart/form-data（默认）" + '\n' + "2、application/x-www-form-urlencoded3、text/plain"+'\n'+"3. application/json 或 text/plain (都是json)",
	// 签名生成算法请查看签名规范（注：除了该参数不参与签名外，其它参数均参与签名）
	qianming245: "签名生成算法请查看签名规范（注：除了该参数不参与签名外，其它参数均参与签名）",

	// 返回参数：
	fenhui246: "返回参数：  ",
	// 返回码 /返回消息 /返回数据 
	fanhuima247: "返回码",
	fanhuixiaoxi248: "返回消息",
	fanhuishuju249: "返回数据",
	// 1-5
	// 0代表成功，1代表失败，其它请看公共返回码
	daibiao250: "0代表成功，1代表失败，其它请看公共返回码",
	// 返回消息，如：success 
	success251: "返回消息，如：: success",
	// 成功时必定返回以下数据 
	successdata252: "成功时必定返回以下数据",
	// 带有订单ID的支付页面网址，商户拿到该地址后直接跳转即可。
	dingdan253: "带有订单ID的支付页面网址，商户拿到该地址后直接跳转即可",
	// 平台支付订单号，如：20210602101815555456
	pingtai254: "平台支付订单号，如: 20210602101815555456",
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 6.查询支付订单 
	// 当付完款之后，返回支付错误或支付中时，建议每间隔 3 秒调用本接口来获取当前订单最新的支付状态，直至支........
	fukuan255: " 支付完成后，当支付错误或支付返回时，建议每3秒调用一次该接口，获取当前订单的最新支付状态，直到支付成功，才会提示支付成功 ; 或主动关闭订单.",
	// 接口地址：/pay/query 
	jikeou256: "接口地址：/pay/query  ",
	// 	// 目录6表格1-请求参数： 
	mulu6: {
		// 参数名 (商户号 /支付订单号/随机串 /时间戳 /签名 )
		canshuming: {
			mulu6shanghu257: "商户号",
			mulu6dingdanhao258: "支付订单号",
			mulu6sunjicuan259: "随机串",
			mulu6shijiancuo260: "时间戳",
			mulu6qianming261: "签名"
		},
		// 描述 ()
		miaoshu: {
			suoqu262: "请询问技术人员",
			li263: "例如: 20210602101815555456",
			zifucuan264: "随机字符串，长度为10-32个字符，例如: nkkMTAkLkR4U",
			shijiancuo265: " 以秒为单位的当前越南时间戳，例如: 1624229782",
			suanfa267: "签名生成算法请查看签名规范（注：除此参数不参与签名外，其他参数均参与签名）"
		}
	},
	// 目录6表格2-返回参数：
	mulu6_2: {
		// 表格名--返回参数
		fanhuicanshu268: "返回参数： ",
		canshufanhui: {
			column1: {},
			column2: {
				data1: "返回码",
				data2: "返回信息",
				data3: "返回数据",
				data4: "商户号",
				data5: "支付订单号 ",
				data6: "支付金额",
				data7: "支付成功/失败时间",
				data8: "平台订单号",
				data9: "付款方式",
				data10: "请求状态",
				data11: "付款状态 ",
				data12: "随机串",
				data13: "时间戳",
				data14: "签名",
			},
			column3: {},
			column4: {
				data1: "0代表成功，1代表失败，其他人请看公共返回码",
				data2: "返回信息，如：成功",
				data3: "成功时必须返回以下数据",
				data4: "请询问技术人员",
				data5: "商户订单号",
				data6: "越南盾(RM)，单位：元",
				data7: "如: 2021-05-31 10:56:22",
				data8: "如: 20210602101815555456",
				data9: "1是网络支付，2是扫码支付",
				data10: "00代表成功，其他代表失败",
				data11: "NORGPay-未支付  / NORGPay-未支付   / USERPAYING-正在支付  / REFUND-转钱  / REVOKED-提现  / CLOSED-关闭 / PAYERROR-支付出错误",
				data12: "随机串，长度为10-32个字符，例如: nkkMTAkLkR4U",
				data13: "时间戳，例如: 1624229782",
				data14: "签名生成算法请查看签名规范（注：除此参数不参与签名外，其他参数均参与签名）",
			}
		}
	},
	///////////////////////////////////////////////////////////////////
	// 目录7.支付结果异步通知
	inform7_269: {
		title1: "如果在订单界面选择pay_notifyurl参数，当客户支付成功后，平台服务器会将支付结果数据推送到该地址，商户需要接收并处理。 与后台通知交互时，如果平台收到商家的响应不是纯字符串SUCCESS或超过5秒后返回，则平台认为通知失败，平台会通过一定的策略（通知频率为 0/5/10/15/20 /25/30...86400, 单位：秒) 间接重新发起通知，尽可能提高通知成功率，但不保证通知会 最终会成功。 如果长时间收不到通知，建议主动拨打询价单。",
		title2: "由于后台通知重发的情况，同一个通知可能会多次发送到商户系统。 商家系统必须能够正确处理重复通知。 推荐的做法是在收到处理通知的时候先检查对应业务数据的状态，判断通知是否已经处理，如果没有处理，则处理，如果处理，则返回结果 直接。 在业务数据的状态检查和处理之前，应该使用数据锁进行并发控制，避免函数重入造成的数据混乱。 ",
		title3: "特别注意：商家收到通知参数后，必须将收到的通知参数中的订单ID和金额与业务系统的订单和金额进行校验，校验一致后才可以更新数据库订单状态。 并且一定要对返回的签名进行校验，以保证消息的准确性。 ",
		// 通知参数
		tongzhi4: "通知参数：",
		column2: {
			val1: "商户号",
			val2: "订单号",
			val3: "支付金额",
			val4: "支付成功/失败时间",
			val5: "平台订单号",
			val6: "付款方式",
			val7: "请求状态",
			val8: "付款状态 ",
			val9: "随机串",
			val10: "时间戳",
			val11: "签名",
		},
		column4: {
			val1: "请询问技术人员",
			val2: "商户订单号",
			val3: "越南盾(RM)，单位：元",
			val4: "如: 2021-05-31 10:56:22",
			val5: "如: 20210602101815555456",
			val6: "1是网络支付，2是扫码支付",
			val7: "00代表成功，其他代表失败",
			val8: "NORGPay-未支付  / NORGPay-未支付   / USERPAYING-正在支付  / REFUND-转钱  / REVOKED-提现  / CLOSED-关闭 / PAYERROR-支付出错误",
			val9: "随机串, 长度为10-32个字符，例如: nkkMTAkLkR4",
			val10: "时间戳， 如: 1624229782",
			val11: "签名生成算法请查看签名规范（注：除此参数不参与签名外，其他参数均参与签名）",
		},
		title4: "下单时会根据content_type字段传入的数据格式用POST请求推送到pay_notifyurl地址下单时根据content_type字段传入的数据格式用POST请求推送到pay_notifyurl地址下单时 已下订单"
	},
	////////////////////////////////////////////
	// 目录8.关闭订单 
	CloseTheOrder8_270: {
		title1: "当等待时间过长时，客户可以主动关闭订单，避免向客户多收。 ",
		title2: "接口地址：/pay/close",
		title3: "请求参数：",
		table: {
			column2: {
				val1: "商户号 ",
				val2: "支付订单号",
				val3: "随机串",
				val4: "时间戳",
				val5: "签名"
			},
			column4: {
				val1: "请询问技术人员",
				val2: "如: 20210602101815555456",
				val3: "随机串, 长度为10-32个字符，例如 nkkMTAkLkR4U",
				val4: "时间戳 如: 1624229782",
				val5: "签名生成算法请查看签名规范（注：除此参数不参与签名外，其他参数均参与签名）",
			}
		},
		title4: "返回参数： ",
		tableTwo: {
			column2: {
				val1: "返回码",
				val2: "返回信息",
			},
			column4: {
				val1: "0代表成功，1代表失败，其他人请看公共返回码",
				val2: "返回信息"
			}
		}
	},

	// 目录9.获取支付渠道列表
	common_9: {
		title1: "接口地址：/pay/product",
		title2: "返回参数：",
		table1: {
			column2: {
				val1: "商户号",
				val2: "随机串",
				val3: "时间戳",
				val4: "签名 ",
			},
			column4: {
				val1: "请询问技术人员",
				val2: "随机串, 长度为10-32个字符，例如 nkkMTAkLkR4U",
				val3: "时间戳 如: 1624229782",
				val4: "签名生成算法请查看签名规范（注：除此参数不参与签名外，其他参数均参与签名）",
			}
		},
		table2: {
			column2: {
				val1: "返回码",
				val2: "返回信息",
				val3: "返回数据",
				val4: "总行数",
				val5: "数据名单",
				val6: "渠道ID",
				val7: "渠道名",
				val8: "渠道logo",
				val9: "付款方式",
				val10: "低级编码",
			},
			column4: {
				val1: "0代表成功，1代表失败，其他人请看公共返回码",
				val2: "返回信息",
				val3: "成功时必须返回以下数据",
				val4: "返回数据总行数",
				val5: "返回的列表数据有以下字段:",
				val6: "渠道ID, 如: 926",
				val7: "渠道名, 如: Vietcombank",
				val8: "渠道 LOGO URL",
				val9: "目前银行支持的支付方式，1为网络支付，2为扫码支付，倍数之间用英文逗号隔开，如：1, 2",
				val10: "银行从属代码，如果没有，则返回空",
			}
		}
	},
	/////////////////////////////////////////////////////////////////
	// 目录10
	returnCode10: {
		biaptou1: "返回码",
		biaptou2: "描述",
		biaptou3: "方案",
		table1: {
			column1: {
				val1: "0",
				val2: "1",
				val3: "100-600",
			},
			column2: {
				val1: "成功",
				val2: "失败并返回失败消息",
				val3: "对应HTTP状态码，详情请查看: https://zh.wikipedia.org/wiki/HTTP%E7%8A%B6%E6%80%81%E7%A0%81",
			}
		}
	},
	/////////////////////////////////////////////////////
	// 目录11
	appendix11: {
		title1: "支付渠道：",
		// 表格标题:
		headline1: "渠道 ID",
		headline2: "渠道名",
		headline3: "备注",
		table1: {
			column1: {
				val1: "926",
				val2: "927",
				val3: "928",
				val4: "929",
				val5: "930",
				val6: "931",
				val7: "932",
				val8: "933",
				val9: "934",
				val10: "935",
				val11: "936",
				val12: "937",
				val13: "938",
				val14: "939",
				val15: "940",
				val16: "941",
				val17: "942",
				val18: "943",
			},
			column2: {
				val1: "926",
				val2: "927",
				val3: "928",
				val4: "929",
				val5: "930",
				val6: "931",
				val7: "932",
				val8: "933",
				val9: "934",
				val10: "935",
				val11: "936",
				val12: "937",
				val13: "938",
				val14: "939",
				val15: "940",
				val16: "941",
				val17: "942",
				val18: "943",
			}
		}
	},
	// 目录1-接口规格
	specification: {
		table1: {
			column1: {
				val1: "转账方式",
				val2: "提交方式",
				val3: "数据格式 ",
				val4: "字符编码",
				val5: "签名算法",
			},
			column2: {
				val1: "使用HTTPS传输",
				val2: "使用POST方法提交",
				val3: "提交和返回数据均支持：form-data、x-www-form-urlencoded、raw (json)格式，注意：提交时请务必使用form-data格式。 如果您使用其他格式，请将header中的 Content-Type 值设置为 x-www-form-urlencoded 或 application/json。",
				val4: "使用 UTF-8 字符编码",
				val5: "使用MD5，详见2",
			}
		}
	},
	// 支付页面的翻译validateTheInput.vue
	validateTheInput: {
		// 1.请输入用户名
		userNameNotHave: "请输入用户名",
		// 2.请输入密码
		ppp: "请输入密码",
		// 3.请输入OTP校验码
		peoc: "请输入OTP校验码",
		// -----------------------------------------------------
		jine: "金额",
		// 事务处理时间
		Transaction: "交易时间",
		// .参考代码
		Reference: "参考代码",
		// 登入
		Log: "登录",
		// 验证码
		Verification: "验证码",
		Result: "结果",
		Account: "账号.",
		Password: "密码",
		// Remain: "Remain time 176 seconds",
		Remain90: "剩余时间 ",
		Remain91: "秒",
		// 11-
		Remark: "备注",
		// 提示
		rishi501: "请输入正确的的户口和密码，验证的时候请选择OTP短信验证和银行App进行点击验证。请按照存款说明操作以避免出现错误。非常感谢。",
		OTPcode: "请输入otp",
		// 请输入otp
		otpEnter: "请在您的短信或银行应用程序中输入OTP，或者点击验证：",
		// 按钮下一步
		next: "下一步",
		// 请稍等。您的交易正在进行中
		transaction: "请稍等。您的交易正在进行中",
		// 检查事务状态
		Check: "检查事务状态",
		// 支付失败
		failed502: " 支付失败",
		// 请稍等。您的交易正在进行中
		waiting503: "请稍等。您的交易正在进行中",
		success504: "支付成功",
		jieguo505: "结果",
		shibai506: "登录已超时"

	},
	transfer: {
		// 通过银行转账进行付款
		transfer: "通过银行转账进行付款",
		// 网银转账
		banking: "网银转账",
		// 扫码转账
		payment507: "扫二维码转账",
		// 账号
		zhanghao508: "账号",
		// 账户名字,
		mingzi509: "账户名字",
		// 分行
		fenhang510: "分行",
		// 金额
		jine511: "金额",
		// 内容
		neirong512: "内容",
		// 完成
		wanchang513: "完成",
		// 还有176秒
		shnegyu514: "剩余时间 ",
		// 转账成功后， 请点击完成按钮， 以便我们尽快处理交易。 感谢您使用我们的服务。
		fuwu515: "转账成功后， 请点击完成按钮， 以便我们尽快处理交易。 感谢您使用我们的服务。",
		// 执行转移命令 -
		zhuanyi516: "执行转移命令",
		// 访问银行的网上银行或手机银行网站进行转账 -
		fangwen517: "- 访问银行的网上银行或手机银行网站进行转账",
		// 以下内容中的存款代码(GODETOXD8X0ZTK) 自生成后仅在 20 分钟内有效。 -
		youxiao518: "- 以下内容中的存款代码 ",
		jiade5181: "自生成后仅在",
		jiade5182: "秒内有效.",
		// 请在下面填写正确的信息以立自生成后仅在即更新资金。 -
		dengxin519: "- 请在下面填写正确的信息以立自生成后仅在即更新资金.",
		// 注意： 请输入正确的 40,
		// 000 RM 金额， 并选择转账费用“ 人 / 转账单位” 形式以接收准确金额。 如果没有匹配项， 您的订单将被取消。 任何问题请联系客户支持。
		quxiao520: "- 注意： 请输入正确的  ",
		quxiao5201: "并选择转账费用“ 人 / 转账单位” 形式以接收准确金额。 如果没有匹配项， 您的订单将被取消。 任何问题请联系客户支持",
		// 秒
		miao521: "秒",
		// -------------------------
		// 内容必须有
		bixu522: "内容",
		// 二维码仅供本次充值使用，请勿保存以备日后充值使用。
		shiyong523: "二维码仅供本次充值使用，请勿保存以备日后充值使用.",
		// 二维码不能重复用二维码仅供本次充值使用，请勿保存以备日后充值使用
		chongfu524: "请勿重复用本二维码",
		// 打开QRPay在手机网银APP
		wangyin525: "打开QRPay在手机网银APP ",
		// 扫二维码
		erweima526: "扫二维码",
		// 确认金额完成交易
		wancheng527: "确认金额完成交易.",
		// 复制成功
		fail528: "复制成功",
		// 复制失败
		succsee529: "复制失败",
		// 复制
		fuzhi530: "复制",
		// ----------------------------
		// 超时/
		chaoshi531: "超时",
		// 失败
		shibai532: "失败",
		// 成功
		chenggong534: "成功",
		result: '结果'
	}
}
