<template>
	<div class="serve allBox">
		<div class="title">{{$t('m.Service')}}</div>
		<div class="title1">PRODUCT SERVICE</div>
		<div class="mian wow slideInUp">
			<div class="left">
				<img src="../../assets/index/dh.png" />
				<p>{{$t('m.docking')}}</p>
				<p class="pBox">{{$t('m.system')}}
				</p>
			</div>
			<div class="left">
				<img src="../../assets/index/ren2.png" />
				<p>{{$t('m.ss')}}</p>
				<p class="pBox">{{$t('m.addss')}}
				</p>
			</div>
		</div>
		<div class="mian footer wow slideInUp">
			<div class="left">
				<img src="../../assets/index/ren3.png" />
				<p>{{$t('m.Interface')}}</p>
				<p class="pBox">{{$t('m.cover')}}
				</p>
			</div>
			<div class="left">
				<img src="../../assets/index/ren4.png" />
				<p>{{$t('m.qr')}}</p>
				<p class="pBox">{{$t('m.gathering')}}
				</p>
			</div>
		</div>
		<div class="details wow slideInUp">
			<div class="imgBox">
				<img src="../../assets/index/line.png" />
				<div class="btn" @click="goAboutUs">{{$t('m.zixin56')}}</div>
			</div>
			<div class="essay">
				<p>{{$t('m.customization')}}</p>
				<div class="right">
					<img src="../../assets/index/pick.png" />{{$t('m.scene')}}

				</div>
				<div class="right">
					<img src="../../assets/index/pick.png" />{{$t('m.free')}}
				</div>
			</div>
		</div>
		<div class="details wow slideInDown">
			<div class="essay footerEssay">
				<p>{{$t('m.flow')}}</p>
				<div class="right">
					<img src="../../assets/index/pick.png" />{{$t('m.individuation')}}

				</div>
				<div class="right">
					<img src="../../assets/index/pick.png" />{{$t('m.follow')}}
				</div>
				<div class="right">
					<img src="../../assets/index/pick.png" />{{$t('m.team')}}
				</div>
			</div>
			<div class="imgBox">
				<img src="../../assets/index/t2.png" />
				<div class="btn" @click="goLogin">{{$t('m.jiaru57')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "serve",
		props: {
			msg: String,
		},
		methods:{
			goLogin(){
				this.$router.push('/register')
			},
			goAboutUs(){
				this.$router.push('/aboutUs')
			}
		}
	};
</script>

<style lang="less" scoped="scoped">
	.serve {
		padding-top: 146px;

		.title {
			text-align: center;
			font-size: 35px;
			font-family: Helvetica-Light, Helvetica;
			font-weight: 300;
			color: #1f2a68;
			line-height: 42px;
		}

		.title1 {
			margin-top: 9px;
			font-size: 20px;
			font-family: Arial-Black, Arial;
			font-weight: 900;
			color: #E4E9ED;
			line-height: 28px;
			letter-spacing: 2px;
			text-align: center;
			margin-bottom: 75px;
		}

		.footer {
			margin-top: 30px;
		}

		.mian {
			display: flex;
			justify-content: space-around;

			.left,
			.right {
				width: 435px;
				height: 315px;
				background: #F8F9FF;
				border-radius: 20px;
				text-align: center;
				padding: 20px 15px;

				img {
					width: 430px;
					height: 180px;
				}

				p {

					font-size: 20px;
					font-family: Helvetica-Bold, Helvetica;
					font-weight: bold;
					color: #1f2a68;
					line-height: 28px;
				}

				.pBox {

					font-size: 16px;
					font-family: Helvetica;
					color: #1f2a68;
					line-height: 28px;
				}
			}
		}

		.details {
			display: flex;
			margin-top: 150px;

			.imgBox {
				img {
					width: 550px;
					height: 360px;
				}
			}

			.essay {
				flex: 1;
				// text-align: right;
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				p {
					font-size: 35px;
					font-family: Helvetica-Light, Helvetica;
					font-weight: 300;
					color: #1f2a68;
					line-height: 42px;
					// text-align: center;
					padding-top: 50px;
					width: 420px;
					text-align: center;
				}

				img {
					width: 29px;
					height: 29px;
					margin-right: 10px;
				}

				.right {
					width: 420px;
					text-align: left;
					display: flex;
					font-size: 16px;
					font-family: Helvetica;
					color: #1f2a68;
					line-height: 28px;
					margin-top: 29px;
				}
			}

			.footerEssay {
				align-items: flex-start;
				padding-bottom: 150px;
			}

			.btn {
				margin: 0 auto;
				width: 240px;
				height: 42px;
				background: #FFFFFF;
				border-radius: 10px;
				border: 1px solid #7180A1;
				font-size: 16px;
				font-family: Helvetica-Bold, Helvetica;
				font-weight: bold;
				color: #6E7EA7;
				line-height: 28px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 30px;
				cursor: pointer;
			}
			.btn:hover{
				border-color: #409EFF;
				color:#409EFF ;
			}

		}
	}
</style>
