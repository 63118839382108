<template>
	<div class="PayTheAssistant">
		<div class="title">{{$t('m.major')}}</div>
		<div class="subhead">PAYMENT ASSISTANT</div>
		<div class="mian  allBox" id="">
			<div class="imgBox wow slideInUp ">
				<div class="cartoonBox">
					<img src="../../assets/index/code1.png" />
				</div>
				<div class="name">{{$t('m.APP')}}</div>
			</div>
			<div class="imgBox wow slideInUp">
				<div  class="cartoonBox">
					<img src="../../assets/index/code2.png" />
				</div>
				<div class="name">{{$t('m.H5')}}</div>
			</div>
			<div class="imgBox wow slideInUp ">
				<div  class="cartoonBox">
					<img src="../../assets/index/code3.png" />
				</div>
				<div class="name">{{$t('m.web')}}</div>
			</div>
			<div class="imgBox wow slideInUp">
				<div  class="cartoonBox">
					<img src="../../assets/index/code4.png" />
				</div>
				<div class="name">{{$t('m.offline')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	export default {
		name: "PayTheAssistant",
		props: {
			msg: String,
		},
		mounted() {
			this.$nextTick(() => {
				// 在dom渲染完后,再执行动画
				const wow = new WOW({
					live: false,
					// boxClass: ‘wow‘,
					// animateClass: ‘animated‘,
					offset: 300,
					mobile: true,
					live: true
				})
				wow.init()
			})
		}
		
	};
</script>

<style lang="less" scoped="scoped">
	.cartoonBox{
		border-radius: 10px;
		}
	.PayTheAssistant {
		margin-top: 150px;
		background: #F8F9FF;
		padding: 53px 0;
		.title {
			text-align: center;
			font-size: 35px;
			font-family: Helvetica-Light, Helvetica;
			font-weight: 300;
			color: #1f2a68;
			line-height: 42px;
		}
		.subhead{
			text-align: center;
			font-family: Arial-Black, Arial;
			font-weight: 900;
			color: #E4E9ED;
			line-height: 28px;
			letter-spacing: 2px;
		}
		.mian{
			display: flex;
			justify-content: space-around;
			padding-top: 75px;
			
			.imgBox{
				flex: 1;
				padding: 0 0.9375rem;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				img{
					width: 180px;
					height: 320px;
				}
			}
			.name{
				text-align: center;
				font-size: 20px;
				font-family: Helvetica-Bold, Helvetica;
				font-weight: bold;
				color: #1f2a68;
				line-height: 28px;
				margin-top: 10px;
			}
		}
	}
</style>
