import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'
import Toast from 'vant';
Vue.use(ElementUI);
import VueI18n from 'vue-i18n'
Vue.use(Toast);
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(VueI18n) // 通过插件的形式挂载
Vue.use(Vant)
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import VueTouch from 'vue-touch'
Vue.use(VueTouch, {
	name: 'v-touch'
})


import apiUrl from './components/module/Global.vue'
Vue.prototype.apiUrl = apiUrl.apiUrl;
import port from './components/module/Global.vue'
Vue.prototype.port = port.port;

const i18n = new VueI18n({
	locale: "en-Us", // 语言标识
	fallbackLocale: "en-Us",
	messages: {
		'en-Us': require('@/common/lang/en'), // 英文语言包
		'zh-CN': require('@/common/lang/zh'), // 中文语言包
		'vl-VN': require('@/common/lang/vl'), // 越南语言包
		'ma-LN': require('@/common/lang/ml'), // 马来语语言包
		// 'ja-JP': require('./common/lang/ja'), // 日语语言包
		// 'vl-VN': require('./common/lang/vl') // 日语语言包
	}
})

// console.dir(VueI18n.prototype, "i18n")
// VueI18n.set(VueI18n._vm, 'locale', "en-Us")
new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount("#app");
