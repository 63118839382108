<template>
</template>
<!--全局变量-->
<script>
	//邮箱地址
	const apiUrl = "yiichiiping@hotmail.com";
	// 接口
	const port="v1"
	export default {
		apiUrl,
		port
	}
</script>

<style>
</style>
