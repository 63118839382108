<template>
  <div>
    <div>
      <van-popup position="top" v-model="show">
        <div class="menu">
          <!-- <div class="pTitle mobile" @click="onSubmitVietnamese">
            <span class="imgBox">
              <img
                v-bind:class="{ activeLang: 1 == lang }"
                src="../../assets/index/VN.png"
                style="opacity: 0.3"
              /> </span
            >VN
          </div> -->
          <div class="pTitle mobile" @click="onSubmitEnglish">
            <span class="imgBox">
              <img
                v-bind:class="{ activeLang: 2 == lang }"
                src="../../assets/index/EN.png"
                style="opacity: 0.3"
              /> </span
            >EN
          </div>
          <div class="pTitle mobile" @click="onSubmitMalaynamese">
            <span class="imgBox">
              <img
                v-bind:class="{ activeLang: 4 == lang }"
                src="../../assets/index/malay.png"
                style="
                  opacity: 0.3;
                  width: 30px;
                  height: 30px;
                  border-radius: 100%;
                  object-fit: cover;
                "
              /> </span
            >Malay
          </div>
          <div class="pTitle mobile" @click="onSubmitChinese">
            <span class="imgBox">
              <img
                v-bind:class="{ activeLang: 3 == lang }"
                src="../../assets/index/china.png"
                style="opacity: 0.3"
              /> </span
            >CN
          </div>
          <div
            @click="noShow"
            style="
              display: flex;
              align-items: center;
              flex: 1;
              justify-content: flex-end;
            "
          >
            <van-icon name="cross" size="2rem" />
          </div>
        </div>
        <van-collapse
          v-model="activeName"
          accordion
          :border="line"
          size="large"
        >
          <!-- 首页 -->
          <div @click="goHome()" class="moAregidt">{{ $t("m.shouye58") }}</div>
          <!-- <van-collapse-item :title="$t('m.shouye58')" name="1">内容</van-collapse-item> -->
          <!-- 产品 -->
          <van-collapse-item
            :title="$t('m.shanpin59')"
            name="2"
            :border="line"
            size="large"
          >
            <div>
              <div class="pTitle" @click="jump(1)">
                <!-- 聚合收款 -->
                {{ $t("m.receipts") }}
              </div>
              <div class="pTitle" @click="jump(2)">
                <!-- 子商户系统 -->
                {{ $t("m.Merchant") }}
              </div>
              <div class="pTitle" @click="jump(3)">
                <!-- 代付系统 -->
                {{ $t("m.PaymentSystem") }}
              </div>
              <div class="pTitle" @click="jump(4)">
                <!-- 二维码支付 -->
                {{ $t("m.zhifu36") }}
              </div>
            </div>
          </van-collapse-item>
          <!-- 开发者中心 -->
          <van-collapse-item :title="$t('m.kaifa60')" name="3" :border="line">
            <div class="">
              <div
                class="pTitle"
                @click="goPay"
                v-bind:class="{ isActiveZq: 3 == listNumber }"
              >
                {{ $t("m.demo78") }}
              </div>
              <div
                class="pTitle"
                @click="goDevelop"
                v-bind:class="{ isActiveZq: 4 == listNumber }"
              >
                {{ $t("m.kaifa52") }}
              </div>
              <div
                class="pTitle"
                @click="goDownload"
                v-bind:class="{ isActiveZq: 5 == listNumber }"
              >
                {{ $t("m.xiazai53") }}
              </div>
            </div>
          </van-collapse-item>
          <!-- 用户登入 -->
          <van-collapse-item :title="$t('m.yewu61')" name="4" :border="line">
            <div class="">
              <div
                class="pTitle"
                @click="goLogin('tenant')"
                v-bind:class="{ isActiveZq: 1 == listNumber }"
              >
                {{ $t("m.shanghu71") }}
              </div>
              <div
                class="pTitle"
                @click="goLogin('agency')"
                v-bind:class="{ isActiveZq: 2 == listNumber }"
              >
                {{ $t("m.daili74") }}
              </div>
            </div>
          </van-collapse-item>
          <!-- <!注册 -->
          <div
            @click="goRegister"
            v-bind:class="{ isActiveZq: 5 == name }"
            class="moAregidt"
          >
            {{ $t("m.zhuce62") }}
          </div>
          <!-- <van-collapse-item :title="$t('m.zhuce62')" name="5" @click="goRegister"></van-collapse-item> -->
        </van-collapse>
      </van-popup>
    </div>
    <div style="width: 100%" class="media">
      <div class="cellphoneTop">
        <div class="right" @click="goHome()">
          <img style="" src="../../assets/index/top.png" />
          <span style="font-size: 1.4rem">RGPay</span>
        </div>
        <div class="loginBtnAdd" @click="goLogin('tenant')">
          {{ $t("m.shanghu71") }}
        </div>
        <div>
          <van-icon
            @click="showPopup"
            name="wap-nav"
            size="2.5rem"
            color="#4A5D87"
          />
        </div>
      </div>
      <!-- <div class="bgBox">
				<img v-if="$route.meta.bg==1" src="../../assets/index/bg.png" />
				<img v-if="$route.meta.bg==2" src="../../assets/product/bg.png" />
			</div> -->
    </div>
    <div class="box">
      <div class="hello allBox" id="">
        <div class="midddle">
          <div class="imgBox btnFocus" @click="goHome()" style="width: 70px">
            <img src="../../assets/index/logo.png" />
          </div>
          <!-- <div class="title btnFocus" @click="goHome()">TPay</div> -->
          <div class="right">
            <!-- 首页 -->
            <!-- <el-dropdown> -->
            <span
              class="el-dropdown-link btnFocus addling"
              @click="goHome()"
              v-bind:class="{ isActiveZq: 1 == name }"
            >
              {{ $t("m.shouye58") }}
            </span>
            <!-- <el-dropdown-menu slot="dropdown"  style="display: none;">
								<div></div>
							</el-dropdown-menu>
						</el-dropdown> -->
            <!-- 产品中心 -->
            <el-dropdown :show-timeout="timeOut">
              <span
                class="el-dropdown-link btnFocus"
                @click="goProduct()"
                v-bind:class="{ isActiveZq: 2 == name }"
              >
                {{ $t("m.shanpin59")
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="menu">
                  <div class="pTitle" @click="jump(1)">
                    <!-- 聚合收款 -->
                    {{ $t("m.receipts") }}
                  </div>
                  <div class="pTitle" @click="jump(2)">
                    <!-- 子商户系统 -->
                    {{ $t("m.Merchant") }}
                  </div>
                  <div class="pTitle" @click="jump(3)">
                    <!-- 代付系统 -->
                    {{ $t("m.PaymentSystem") }}
                  </div>
                  <div class="pTitle" @click="jump(4)">
                    <!-- 二维码支付 -->
                    {{ $t("m.zhifu36") }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 开发者中心 -->
            <el-dropdown :show-timeout="timeOut">
              <span
                class="el-dropdown-link btnFocus"
                v-bind:class="{ isActiveZq: 3 == name }"
              >
                {{ $t("m.kaifa60") }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="menu">
                  <div
                    class="pTitle"
                    @click="goPay"
                    v-bind:class="{ isActiveZq: 3 == listNumber }"
                  >
                    {{ $t("m.demo78") }}
                  </div>
                  <div
                    class="pTitle"
                    @click="goDevelop"
                    v-bind:class="{ isActiveZq: 4 == listNumber }"
                  >
                    {{ $t("m.kaifa52") }}
                  </div>
                  <div
                    class="pTitle"
                    @click="goDownload"
                    v-bind:class="{ isActiveZq: 5 == listNumber }"
                  >
                    {{ $t("m.xiazai53") }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 用户登入 -->
            <el-dropdown :show-timeout="timeOut">
              <span
                class="el-dropdown-link btnFocus"
                v-bind:class="{ isActiveZq: 4 == name }"
              >
                {{ $t("m.yewu61") }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="menu">
                  <div
                    class="pTitle"
                    @click="goLogin('tenant')"
                    v-bind:class="{ isActiveZq: 1 == listNumber }"
                  >
                    {{ $t("m.shanghu71") }}
                  </div>
                  <div
                    class="pTitle"
                    @click="goLogin('agency')"
                    v-bind:class="{ isActiveZq: 2 == listNumber }"
                  >
                    {{ $t("m.daili74") }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <!注册 -->
            <!-- <el-dropdown :show-timeout="timeOut"> -->
            <span
              class="el-dropdown-link btnFocus addling"
              v-bind:class="{ isActiveZq: 5 == name }"
              @click="goRegister"
            >
              {{ $t("m.zhuce62") }}
            </span>
            <span
              class="el-dropdown-link btnFocus addling"
              v-bind:class="{ isActiveZq: 6 == name }"
              @click="goAboutUs"
            >
              {{ $t("m.lianxi40") }}
            </span>
            <!-- 	<el-dropdown-menu slot="dropdown">
							</el-dropdown-menu> -->
            <!-- </el-dropdown> -->
            <el-dropdown :show-timeout="timeOut">
              <span class="el-dropdown-link btnFocus">
                <span class="imgBox">
                  <img v-if="isEn == 'en-Us'" src="../../assets/index/EN.png" />
                  <img v-if="isEn == 'vl-VN'" src="../../assets/index/VN.png" />
                  <img
                    v-if="isEn == 'ma-LN'"
                    src="../../assets/index/malay.png"
                  />
                  <img
                    v-if="isEn == 'zh-CN'"
                    src="../../assets/index/china.png"
                  />
                </span>
                <span style="margin-left: 10px">{{ $t("m.language") }}</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="menu">
                  <div class="pTitle" @click="onSubmitEnglish">
                    <span class="imgBox">
                      <img src="../../assets/index/EN.png" /> </span
                    >English
                  </div>
                  <div class="pTitle" @click="onSubmitVietnamese">
                    <span class="imgBox">
                      <img src="../../assets/index/VN.png" /> </span
                    >Tiếng Việt
                  </div>
                  <div class="pTitle" @click="onSubmitMalaynamese">
                    <span class="imgBox">
                      <img
                        src="../../assets/index/malay.png"
                        style="width: 30px; height: 30px"
                      /> </span
                    >Malay
                  </div>
                  <div class="pTitle" @click="onSubmitChinese">
                    <span class="imgBox">
                      <img src="../../assets/index/china.png" /> </span
                    >中文
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="topHeight" style="height: 80px"></div>
    <!-- <a href="mailto:" id="sendMail">444444444444444444444444</a> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    myName: Number,
  },
  data() {
    return {
      isEn: "en-Us",
      name: this.myName,
      listNumber: 0,
      timeOut: 100,
      show: false,
      activeName: "1",
      line: false,
      lang: 1,
    };
  },
  watch: {
    // $route(to, from) {
    // 	console.log(to.path);
    // },
    myName(newVal) {
      this.name = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
    },
  },
  mounted() {
    // console.log(this.$route.path)
    // if(this.$route.path!="/product"){
    // 	this.$router.go(0);
    // }
    // console.log(this.name)
    // this.onSubmitVietnamese()
    const valPath = localStorage.getItem("userId");
    if (valPath == "tenant") {
      this.listNumber = 1;
    } else if (valPath == "agency") {
      this.listNumber = 2;
    } else if (valPath == 3) {
      this.listNumber = 3;
    } else if (valPath == 4) {
      this.listNumber = 4;
    } else if (valPath == 5) {
      this.listNumber = 5;
    }
    // if (this.$i18n.locale == "en-Us") {
    //   localStorage.setItem("lang", this.$i18n.locale);
    // }
    this.getLang();
  },
  methods: {
    goAboutUs() {
      this.$router.push("/aboutUs");
      localStorage.setItem("lang", this.$i18n.locale);
      this.isActiveZq = 6;
    },
    goHome() {
      this.show = false;
      this.$router.push("/");
      // this.$router.go(0)
    },
    /** 语言切换中文 */
    onSubmitChinese() {
      this.lang = 3;
      this.show = false;
      this.isEn = "zh-CN";
      // let self_current_lang = this.langForm.radio
      let self_current_lang = "zh-CN";
      if (self_current_lang) {
        // 切换系统语言
        this.$i18n.locale = self_current_lang;
        // 将新语言保存到全局状态管理
        //this.$store.dispatch("update_current_lang", self_current_lang);
        // 关闭语言询问框
        this.chooseLangDialogVisible = false;
        // 成功提示
        // this.$message.success('系统语言切换成功！')
      } else {
        // this.$message.warning('没有你想要的语言吗，选一个吧！')
      }
      var that = this;
      this.$i18n.locale = self_current_lang;
      console.log(this.$i18n.locale, "self_current_lang");
      localStorage.setItem("lang", self_current_lang);
    },
    /** 语言切换越南文 */
    onSubmitVietnamese() {
      this.lang = 2;
      this.show = false;
      this.isEn = "vl-VN";
      // let self_current_lang = this.langForm.radio
      let self_current_lang = "vl-VN";
      if (self_current_lang) {
        // 切换系统语言
        this.$i18n.locale = self_current_lang;
        // 将新语言保存到全局状态管理
        // this.$store.dispatch("update_current_lang", self_current_lang);
        // 关闭语言询问框
        this.chooseLangDialogVisible = false;
        // 成功提示
        // this.$message.success('系统语言切换成功！')
      } else {
        // this.$message.warning('没有你想要的语言吗，选一个吧！')
      }
      var that = this;
      let lang = this.$i18n.locale;
      localStorage.setItem("lang", self_current_lang);
    },

    onSubmitMalaynamese() {
      this.lang = 4;
      this.show = false;
      this.isEn = "ma-LN";
      // let self_current_lang = this.langForm.radio
      let self_current_lang = "ma-LN";
      console.log(self_current_lang, "self_current_lang");
      if (self_current_lang) {
        // 切换系统语言
        this.$i18n.locale = self_current_lang;
        // 将新语言保存到全局状态管理
        //this.$store.dispatch("update_current_lang", self_current_lang);
        // 关闭语言询问框
        this.chooseLangDialogVisible = false;
        // 成功提示
        // this.$message.success('系统语言切换成功！')
      } else {
        // this.$message.warning('没有你想要的语言吗，选一个吧！')
      }
      var that = this;
      let lang = that.$i18n.locale;
      localStorage.setItem("lang", self_current_lang);
    },

    /** 语言切换英文 */
    onSubmitEnglish() {
      this.show = false;
      this.lang = 1;
      // let self_current_lang = this.langForm.radio
      this.isEn = "en-Us";
      let self_current_lang = "en-Us";
      if (self_current_lang) {
        // 切换系统语言
        this.$i18n.locale = self_current_lang;
        // 将新语言保存到全局状态管理
        // this.$store.dispatch("update_current_lang", self_current_lang);
        // 关闭语言询问框
        this.chooseLangDialogVisible = false;
        // 成功提示
        // this.$message.success('系统语言切换成功！')
      } else {
        // this.$message.warning('没有你想要的语言吗，选一个吧！')
      }
      var that = this;
      let lang = this.$i18n.locale;
      localStorage.setItem("lang", self_current_lang);
    },
    goProduct() {
      this.show = false;
      this.$router.push("/product");
    },
    // 商户登录
    goLogin(val) {
      this.show = false;
      localStorage.setItem("userId", val);
      if (val == "tenant") {
        this.listNumber = 1;
      } else {
        this.listNumber = 2;
      }
      // console.log(val)
      this.$router.push("/about");
    },
    // 支付体验
    goPay() {
      this.show = false;
      // this.listNumber=3
      localStorage.setItem("userId", 3);
      this.$router.push("/pay");
    },
    // 下载页
    goDownload() {
      // console.log("1")
      this.show = false;
      // this.listNumber=5
      localStorage.setItem("userId", 5);
      this.$router.push("/download");
    },
    // 开发文档页
    goDevelop() {
      this.show = false;
      // this.listNumber=4
      localStorage.setItem("userId", 4);
      this.$router.push("/develop");
    },
    // 商户注册页
    goRegister() {
      this.show = false;
      this.$router.push("/register");
    },
    jump(index) {
      this.show = false;
      this.$router.push("/product");
      setTimeout(function () {
        let jump = document.querySelectorAll(".anchor");
        // console.log("1")
        let total = jump[index];
        // 实现滚动效果
        total.scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "center", // 上边框与视窗顶部平齐。默认值
        });
      }, 200);
      // this.$router.push({path:'product', query: {jumpIndex: index}})
    },
    showPopup() {
      this.show = true;
    },
    noShow() {
      this.show = false;
    },
    // 获取当前语言
    getLang() {
      var that = this;
      //localStorage.setItem("lang", this.$i18n.locale);
      if (localStorage.getItem("lang") === "en-Us") {
        // let lang = this.$i18n.locale;
        // localStorage.setItem("lang", lang);
        // var data1 = localStorage.getItem("lang");
        this.onSubmitEnglish();
      } else if (localStorage.getItem("lang") === "zh-CN") {
        that.onSubmitChinese();
      } else if (localStorage.getItem("lang") === "ma-LN") {
        that.onSubmitMalaynamese();
      } else if (localStorage.getItem("lang") === "vl-VN") {
        that.onSubmitVietnamese();
      }
      // if (data1 == "vl-VN") {
      //   that.lang = 2;
      // } else if (data1 == "en-Us") {
      //   that.lang = 1;
      // } else if (data1 == "zh-CN") {
      //   that.lang = 3;
      // } else if (data1 == "ma-LN") {
      //   that.lang = 4;
      // }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// 当前语言图片透明度
.activeLang {
  opacity: 1 !important;
}

.loginBtnAdd {
  border-radius: 5px;
  border: 1px solid #4a5d87;
  font-size: 14px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #1f2a68;
  line-height: 17px;
  padding: 7px 7px;
  margin-right: 10px;
}

.el-dropdown-link:hover {
  cursor: pointer;
  color: #1f2a68;
}

// .el-icon-arrow-down {
//   font-size: 12px;
// }
.pTitle:hover {
  cursor: pointer;
  color: #1f2a68;
}

.box {
  position: fixed;
  top: 0;
  background-color: #ffff;
  width: 100%;
  height: 80px;
  z-index: 10;
  // z-index: -1;
  // padding-top: 80px;
}

.hello {
  // margin: 0 auto;
  .midddle {
    width: 1200px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    // position: relative;

    .bgBox {
      position: absolute;
      z-index: -999;
      top: 0;
      width: 1200px;
      height: 580px;
      margin: 0 auto;

      img {
        width: 694px;
        height: 100%;
      }
    }

    .imgBox {
      width: 45px;
      height: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .el-dropdown,
    .addling {
      // width: 66px;
      // height: 28px;
      font-size: 14px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #6e7ea7;
      line-height: 28px;
      margin-left: 28px;
    }

    .addling:hover {
      color: #1f2a68 !important;
    }

    .right {
      flex: 1;
      text-align: right;

      // display: flex;
      // justify-content: center;
      .imgBox {
        width: 30px;
        height: 30px;
        // background: #F42F4C;
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
      }
    }
  }

  .title {
    // width: 320px;
    // height: 30px;
    font-size: 25px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #1f2a68;
    line-height: 30px;
    padding-left: 15px;
  }
}

.menu {
  text-align: left;

  .imgBox {
    width: 30px;
    height: 30px;
    // background: #F42F4C;
    display: inline-block;
    margin-right: 23px;

    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
}
</style>
