<template>
	<div class="indexTop allBox" id="">
		<div class="bgBoxMble">
			<img src="../../assets/index/bg.png" />
		</div>
		<div class="bgBox ">
			<img src="../../assets/index/bg.png" />
		</div>
		<div class="topTitle wow bounceInUp">{{$t('m.title')}}<br>{{$t('m.slogan')}}</div>
		<div class="subhead">
			{{$t('m.explain')}}
		</div>
		<div class="mian">
			<div class="box wow  slideInUp">
				<div class="jewel">
					<div class="left">
						<div class="a"></div>
						<div class="b">{{$t('m.one')}}</div>
					</div>
					<div>
						<img src="../../assets/index/jewel.png" />
					</div>
				</div>
				<div class="footerTitle">{{$t('m.room')}}</div>
			</div>
			<div class="box wow  slideInUp">
				<div class="jewel">
					<div class="left">
						<div class="a"></div>
						<div class="b">{{$t('m.two')}}</div>
					</div>
					<div>
						<img src="../../assets/index/napzong.png" />
					</div>
				</div>
				<div class="footerTitle">{{$t('m.time')}}</div>
			</div>
			<div class="box wow  slideInUp">
				<div class="jewel">
					<div class="left">
						<div class="a"></div>
						<div class="b">{{$t('m.three')}}</div>
					</div>
					<div>
						<img src="../../assets/index/dunpai.png" />
					</div>
				</div>
				<div class="footerTitle">{{$t('m.financial')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	export default {
		name: "indexTop",
		props: {
			msg: String,
		},
		data() {
			return {
				imgList: [

				]
			};
		},
		mounted() {
			this.$nextTick(() => {
				// 在dom渲染完后,再执行动画
				const wow = new WOW({
					live: false,
					// boxClass: ‘wow‘,
					// animateClass: ‘animated‘,
					offset: 0,
					mobile: true,
					live: true
				})
				wow.init()
			})
		},
		methods: {
			swiperleft() {
				console.log("左划")
			},
			swiperright() {
				console.log("右滑")
			}
		}
	};
</script>

<style lang="less" scoped="scoped">
	.indexTop {
		.bgBoxMble {
			display: none;
			width: 100%;
			height: auto;

			// padding: 15px;
			img {
				width: 90%;
				height: auto;
				position: absolute;
				top: 0;
				z-index: -1;
			}
		}

		.bgBox {
			position: absolute;
			z-index: -999;
			top: 0;
			width: 1200px;
			height: 580px;
			margin: 0 auto;
			text-align: right;

			img {
				width: 694px;
				height: 100%;
			}
		}

		.topTitle {
			width: 506px;
			// height: 105px;
			font-size: 30px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #1f2a68;
			line-height: 35px;
			margin-top: 152px;
		}

		.subhead {
			width: 506px;
			height: 112px;
			font-size: 16px;
			font-family: Helvetica;
			color: #1f2a68;
			line-height: 28px;
			margin-top: 26px;
		}

		.mian {
			display: flex;
			justify-content: space-around;
			margin-top: 180px;

			.box:hover {
				margin-top: -20px;
				box-shadow: 0px 0px 20px #e5e5e5;
				border-radius: 0 20px 0 0;

			}

			// .box:hover .footerTitle {
			// 	color: #1f2a68;
			// }

			.box {
				transition: all 0.6s; //设置动画执行的时间为0.6s
				width: 240px;
				height: 375px;
				background: #F8F9FF;
				border-radius: 20px;
			}

			.jewel {
				display: flex;

				.left {
					display: flex;
					flex-direction: column;
					flex: 1;
					height: 97px;

					.a {
						flex: 1;
					}

					.b {
						height: 28px;
						font-size: 20px;
						font-family: Helvetica-Bold, Helvetica;
						font-weight: bold;
						color: #1f2a68;
						line-height: 28px;
						// align-items: center;
						padding: 0 15px;
					}
				}



				div {}

				img {
					width: 97px;
					height: 149px;

				}
			}

			.footerTitle {
				margin-top: 10px;
				font-size: 16px;
				font-family: Helvetica;
				color: #1f2a68;
				line-height: 28px;
				padding: 15px;
			}
		}
	}
</style>
