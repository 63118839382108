//判断不同环境
const base = {
    sever: "",
    image: "https://api.rg-pay.com/",
};

if (process.env.VUE_APP_ENV === "production") {
    // base.sever = "http://apitest.tpay.vn/";
    base.sever = "https://api.rg-pay.com/";
} else if (process.env.VUE_APP_ENV === "development") {
    base.sever = "http://apidev.score99pay.com/";
    //base.sever = "https://api.rg-pay.com/";
    //base.sever = "https://apitest.tpay.vn/";
    // base.sever = "http://dev.tpay.vn/";
} else if (process.env.VUE_APP_ENV === "test") {
    base.sever = "https://api.rg-pay.com/";
}

function paramToUrl(obj = {}) {
    return Object.keys(obj)
        .map((key) => "&" + key + "=" + obj[key])
        .join("");
}

export {
    base,
    paramToUrl
};