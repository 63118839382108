import server from '@/api/request';
import port from '../../components/module/Global.vue'
var api = port.port;
var nowLang = function () {
    return localStorage.getItem('lang')
}
// alert(nowLang())
var lang = ""
// if(nowLang()=="en-Us"){
// 	lang="en-Us"
// }else if(nowLang()=="zh-CN"){
// 	lang="zh-cn"
// }else if(nowLang()=="vl-VN"){
// 	lang="vi"
// }
export function getHome(data) {   //get请求传参

    return server.get(api + '/Websiteconfig', { params: data })
}

// export function postHome(data){   //post请求传参
//     return server.post(api+'/BlocManage/auth', data)
// }
// 商户注册
export function postRegister(data) {   //post请求传参
    return server.post(api + '/member', data)
}

// 商户登陆
export function postLogin(data) {   //post请求传参
    // console.log(data)
    return server.post(api + '/member.shopLogin', data)
}
// 联系我们/关于我么api/v1/Websiteconfig
export function getAboutUs(data) {   //get请求传参
    return server.get(api + '/Websiteconfig', { params: data })
}
// 获取SDK下载地址api/v1/Websiteconfig_getsdk
export function getSdk(data) {   //get请求传参
    return server.get(api + '/Websiteconfig.getsdk', { params: data })
}
// 获取支付产品列表api/v1/order_product
export function getProduct(data) {   //get请求传参
    return server.get(api + '/order.product', { params: data })
}
// 获取demo测试订单信息api/v1/order_demo
export function getDemo(data) {   //get请求传参
    return server.get(api + '/order.demo', { params: data })
}
// demo测试订单支付api/v1/order_demoOrderPay
export function postDemoOrderPay(data) {   //post请求传参
    return server.post(api + '/order.demoOrderPay', data)
}
// pay页面url参数+订单号V1/pay/query?lang=zh-cn
export function postUrlData(data) {   //post请求传参
    return server.post(api + '/pay/query', data)
}
// Order - 获取支付订单信息
export function getOrderData(data) {   //post请求传参
    return server.get(api + '/order', { params: data })
}
// 统一银行登录pay/login
export function getPayLogin(data) {   //post请求传参
    return server.post(api + '/pay/login', data)
}
// Pay - 3.统一银行OTP校验pay/checkOTP
export function postCheckOTP(data) {   //post请求传参
    return server.post(api + '/pay/checkOTP', data)
}

// Pay - 3.统一银行OTP校验pay/checkOTP
export function sendOTP(data) {   //post请求传参
    return server.post(api + '/pay/sendOTP', data)
}
// /pay/offlineOrderQuery统一银行离线订单查询（离线）
export function postOrderQuery(data) {   //post请求传参
    return server.post(api + '/pay/offlineOrderQuery', data)
}

