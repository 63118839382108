<template>
  <div class="home">
    <HelloWorld :myName="1"></HelloWorld>
    <indexTop></indexTop>
    <PayTheAssistant></PayTheAssistant>
    <serve></serve>
    <clientele></clientele>
    <join></join>
    <!-- <bottom></bottom> -->
  </div>
</template>

<script>
import * as API from "@/api/pageRequest/home"; //引入API
import HelloWorld from "@/components/home/HelloWorld";
import indexTop from "@/components/home/indexTop";
import PayTheAssistant from "@/components/home/PayTheAssistant";
import serve from "@/components/home/serve";
import clientele from "@/components/home/clientele";
import join from "@/components/home/join";
import bottom from "@/components/home/bottom";
export default {
  name: "Home",
  components: {
    HelloWorld: HelloWorld,
    indexTop: indexTop,
    PayTheAssistant: PayTheAssistant,
    serve: serve,
    clientele: clientele,
    join: join,
    bottom: bottom,
  },
  data() {
    return {
      producerSn: "",
      blocCode: "FXFT_0591591000",
      userids: "775",
    };
  },
  methods: {},

  created() {
    // this.getHome();
  },
};
</script>
<style lang="less" scoped="scoped">
.home {
}
</style>
