<template>
	<div class="join allBox">
		<div>{{$t('m.open')}}</div>
		<div class="title">{{$t('m.facilitator')}}</div>
		<div class="joinBtn"><a :href="'mailto:'+url" id="sendMail">{{$t('m.kauhu39')}}</a></div>
	</div>
</template>

<script>
	export default {
		name: "join",
		props: {
			msg: String,
		},
		data() {
			return {
				url: this.apiUrl
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.join {
		text-align: center;
		font-size: 35px;
		font-family: Helvetica-Light, Helvetica;
		font-weight: 300;
		color: #1f2a68;
		line-height: 42px;
		padding: 150px 0;

		.title {

			font-size: 16px;
			font-family: Helvetica;
			color: #1f2a68;
			line-height: 28px;
			margin-top: 30px;
		}

		.joinBtn:hover {
			opacity: 0.8;
		}

		.joinBtn {
			a {
				color: #FFFFFF;
			}

			margin: 0 auto;
			width: 240px;
			height: 42px;
			background: #1f2a68;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			font-family: Helvetica-Bold,
			Helvetica;
			font-weight: bold;
			color: #FFFFFF;
			line-height: 28px;
			margin-top: 50px;
			cursor: pointer;
		}
	}
</style>
