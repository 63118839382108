import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			isActive: 1,
			isShow: true,
			bg: 1,
			isShowAboutUs: true
		}
	},
	{
		path: "/about",
		name: "About",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/About.vue")
	},
	{
		path: "/product",
		name: "Product",
		meta: {
			isActive: 2,
			isShow: true,
			bg: 2,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/product.vue")
	},
	// 商户登录和注册页面
	{
		path: "/loginAndRegister",
		name: "LoginAndRegister",
		meta: {
			isActive: 2,
			isShow: true,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/LoginAndRegister.vue")
	},
	// 支付页
	{
		path: "/pay",
		name: "Pay",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/pay.vue")
	},
	// 下载页
	{
		path: "/download",
		name: "Download",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/download.vue")
	},
	// 开发者文档
	{
		path: "/develop",
		name: "Develop",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/develop.vue")
	},
	// 商户注册
	{
		path: "/register",
		name: "Register",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/register.vue")
	},
	// 关于我们
	{
		path: "/aboutUs",
		name: "AboutUs",
		meta: {
			isShow: true,
			isShowAboutUs: false
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/aboutUs.vue")
	},
	// 不知名的支付页面1payType1   1    步骤1
	{
		path: "/payflow",
		name: "payflow",
		meta: {
			isShow: false,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../components/mobilePaymentProcess/validateTheInput.vue")
	},
	// 404
	{
		path: "*",
		name: "404",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/404.vue")
	},
	// 手机版支付结果状态    1
	{
		path: "/toPay",
		name: "toPay",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../components/module/toPay.vue")
	},
	// 手机版支付二维码超时页   1
	{
		path: "/codeTimeout",
		name: "codeTimeout",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../components/module/codeTimeout.vue")
	},
	// 手机二维码支付页面
	{
		path: "/payCode",
		name: "payCode",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		component: () =>
			import( /* webpackChunkName: "about" */ "../components/module/payCode.vue")
	},
	// 验证码输入页
	{
		path: "/pay_code",
		name: "pay_code",
		meta: {
			isShow: true,
			isShowAboutUs: true
		},
		component: () =>
			import( /* webpackChunkName: "about" */ "../components/module/captchaInputPage.vue")
	},
	// ----------------------------------------------------------------------------------------------
	// 手机端验证码页面6/10    步骤2
	{
		path: "/verificationCodeInput",
		name: "verificationCodeInput",
		meta: {
			isShow: false,
			isShowAboutUs: true
		},
		component: () =>
			import( /* webpackChunkName: "about" */ "../components/mobilePaymentProcess/verificationCodeInput.vue")
	},
	// 手机端支付状态页面6/10
	{
		path: "/stateOfPayment",
		name: "stateOfPayment",
		meta: {
			isShow: false,
			isShowAboutUs: true
		},
		component: () =>
			import( /* webpackChunkName: "about" */ "../components/mobilePaymentProcess/stateOfPayment.vue")
	},
	// 转账页
	{
		path: "/transfer",
		name: "Transfer",
		meta: {
			isShow: false,
			isShowAboutUs: true
		},
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/Transfer.vue")
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop;
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
});

export default router;
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
