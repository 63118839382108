export const m = {
	Homepage: 'Trang chủ',
	Product: 'Sản phẩm',
	Developer: 'Trung tâm kỹ thuật viên',
	Login: 'Đăng nhập giao dịch',
	Register: 'Người bán đăng kí',
	registration_number:"What is your first car registration number?",
	Confirm:"Confirm",
	// 7.RGPay聚合支付/Payment
	title: 'RGPay thanh toán tổng hợp',
	// 8.让支付接口对接前所未有的简单
	slogan: 'Giúp việc thanh toán dễ dàng hơn bao giờ hết.',
	explain: 'Không cần phát triển back-end, một SDK có thể truy cập vào một hệ thống thanh toán hoàn chỉnh, tích hợp giao diện thanh toán chính thống với tốc độ cao và thanh toán trực tiếp với giao diện ổn định hơn và tốc độ nhanh hơn.',
	one: 'Ổn định và lâu dài',
	// 快速高效
	two: 'Nhanh và hiệu quả',
	// 12.安全保障
	three: 'Đảm bảo an toàn',
	// 多机房异地容灾系统，服务端可用性99.9%， 专业运维团队值守
	room: 'Hệ thống phục hồi sự cố từ xa, máy chủ sẵn sàng 99,9%, đội ngũ vận hành và bảo trì chuyên nghiệp luôn túc trực.',
	time: 'Phản hồi siêu nhanh trong 10 phút, dịch vụ khách hàng chuyên nghiệp 1V1, hỗ trợ kỹ thuật 7/24h.',
	financial: 'Tiêu chuẩn bảo vệ an toàn cấp tài chính, khả năng phòng tránh mạnh mẽ. Bảo vệ chống lại sự xâm nhập từ bên ngoài và hỗ trợ các giao dịch cùng lúc cao.',
	major: 'Nơi hỗ trợ thanh toán chuyên nghiệp nhất',
	APP: 'Ứng dụng',
	H5: 'Dành cho điện thoại',
	web: 'Dành cho máy tính',
	offline: 'Quét mã QR',
	Service: 'Sản phẩm và dịch vụ',
	docking: 'Liên kết tài chính',
	system: 'Hệ thống đơn hàng tương tự, giúp phân tích thu chi của doanh nghiệp rõ ràng trong nháy mắt.',
	ss: 'Hệ thống người bán phụ',
	addss: 'Thêm tài khoản người bán phụ để thực hiện chức năng chia nhỏ tài khoản cho các giao dịch.',
	Interface: 'Xin cấp giao diện',
	cover: 'Hỗ trợ cho tất cả các trường hợp, trở thành giao diện thanh toán chính thống.',
	qr: 'Quét mã QR để thanh toán.',
	gathering: 'Là công cụ thu tiền chuyên nghiệp, cần thiết cho mọi hoạt động kinh doanh ngoại tuyến.',
	customization: 'Phương án giải quyết cho thanh toán linh hoạt.',
	scene: 'Hỗ trợ các phương thức giao dịch cho các tình huống kinh doanh khác nhau.',
	free: 'Phân tích trực tuyến miễn phí về các tình huống thanh toán, phân loại nhu cầu thu tiền của doanh nghiệp, đề xuất các ý kiến truy cập và phương án giải quyết các thanh toán linh hoạt.',
	flow: 'Tư vấn chi tiết',
	individuation: 'Phân tích tùy chỉnh',
	follow: 'Theo dõi các yêu cầu kinh doanh linh hoạt trong suốt quá trình, triển khai máy chủ doanh nghiệp cục bộ và kiểm soát bảo mật dữ liệu.',
	team: 'Cung cấp dịch vụ 7/10h cho khách hàng trong tất cả các giai đoạn từ điều chỉnh giao diện, thử nghiệm, vận hành, bảo trì hệ thống hậu kì, giao diện quản lý và đi vào sử dụng.',
	payment: 'Liên kết nhiều giao diện thanh toán.',
	high: 'Kết nối với nhiều giao diện thanh toán tốt nhất trong ngành để đảm bảo hoạt động kinh doanh suôn sẻ. Giúp phát triển giao diện thanh toán dễ dàng và thuận tiện hơn',
	open: 'Khởi đầu cho một kỷ nguyên thanh toán mới!',
	facilitator: 'RGPay thanh toán tổng hợp, nhà cung cấp dịch vụ công nghệ thanh toán, để thanh toán trở nên đơn giản, chuyên nghiệp và nhanh chóng.',
	// 现在加入
	join: 'Tham gia ngay',
	// 43.我要接入
	wantTo: 'Tôi muốn truy cập',
	// 44.我们的伙伴
	OurPartners: 'Đối tác của chúng tôi',
	// 47.联系方式
	method: 'Phương thức liên lạc',
	// 48.产品项目
	chanpinXiangmu: 'Sản phẩm',
	// 49.聚合收款
	receipts: 'Thanh toán tổng hợp',
	// 50.子商户系统
	Merchant: 'Hệ thống người bán phụ',
	// 51.代付系统
	PaymentSystem: 'Hệ thống thanh toán',
	// 52.关于公司
	AboutCompany: 'Về công ty',
	// 53.关于我们
	AboutUs: 'Về chúng tôi',
	// 54.接口合作
	Cooperation: 'Hợp tác kết nối',
	// 55.流量合作
	FlowCooperation: 'Hợp tác dung lượng',
	// 56.开发者
	Deverloperzq: 'Kỹ thuật viên',
	// 57.DEMO体验
	DemoTrial: 'Demo trải nghiệm',
	// 58.API开发文档
	APIDevelopmentDocument: 'API phát triển dữ liệu',
	// 59.SDK下载
	SDKdownload: 'Tải SDK',
	// 60.扫一扫
	ScanCode: 'Quét mã',
	// 61.Copyright © 2021 中南汇金 All rights reserved. 版权所有
	Copyright: 'Bản quyền © 2021 RGPay Mọi quyền được bảo lưu.',
	// ----------------------------------------------------------------------------
	// 产品中心
	chanpin: 'Sản phẩm',
	// 6.聚合支付SDK
	zhifuSDK2: 'Thanh toán SDK',
	// 无需重复集成繁琐
	fansuo3: 'Kỹ thuật viên không cần phải tích hợp nhiều lần các giao diện thanh toán cồng kềnh, mà có thể sử dụng SDK tổng hợp của RGPay để dễ dàng truy cập vào tất cả các phương thức thanh toán, đáp ứng các tình huống thanh toán khác nhau, đồng thời đạt được hiệu suất thanh toán cao. Dịch vụ cơ sở hạ tầng thanh toán an toàn và đáng tin cậy, có thể tạo ra một giao diện thanh toán có giá trị cao .',
	// 8.立即接入
	ljjieru4: "Kết nối ngay",
	// 16.一码收款
	shoukuan5: "Nhận thanh toán tổng hợp",
	// 手机APP支付
	shoujiapp6: "Thanh toán qua ứng dụng điện thoại",
	// iOS/Android原生H5 App
	leibie7: "RGPay cung cấp đầy đủ các giải pháp thanh toán cho tất cả các ứng dụng trên iOS / Android native / H5 như: ",
	// 19.手机APP支付
	shouji8: "Thanh toán qua ứng dụng điện thoại",
	// 20.移动端网页
	gongzhong9: "Thanh toán trên web di động",
	// PC网页支付
	wangye10: "Thanh toán qua mạng máy tính",
	wangyezhifu: "Thanh toán trực tuyến",

	// 22.线下扫码支付
	xianxia11: "Quét mã thanh toán",
	// 23.子商户系统
	zixitong12: "Hệ thống người bán phụ",
	// 商户系统
	shanghu13: "Hệ thống người bán phụ của RGPay là hướng tới nền tảng, phân phối, quản lý chuỗi cung ứng và các loại hình người bán khác. Các sản phẩm thanh toán hỗ trợ quản lý người bán phụ đa cấp có thể thực hiện việc quản lý các đại lý, nhà phân phối, chi nhánh, nhà cung cấp và các vai trò khác thông qua hệ thống người bán phụ. Kết hợp với các sản phẩm nhận thanh toán tổng hợp để thực hiện phân chia giao dịch miễn phí và linh hoạt giữa người bán chính và người bán phụ. Kết hợp với các sản phẩm lưu ký quỹ để thực hiện thanh toán bù trừ của các tiểu thương chính.",
	// 25.商户管理
	shnaghu14: "Quản lí người bán phụ",
	// 26.交易查询
	jiaoyi15: "Kiểm tra giao dịch",
	// 27.财务对账
	caiwu16: "Kiểm chứng tài chính",
	// 28.分润管理
	fenrun17: "Quản lí lợi nhuận",
	// 29.资金安全
	zijin18: "Bảo mật tiền vốn",
	// 完善的商户体系
	shanghu19: "Cung cấp một hệ thống người bán phụ hoàn chỉnh, tạo điều kiện thuận lợi cho việc tổ chức cơ cấu người bán phụ, thiết lập linh hoạt các mối quan hệ người bán phụ trong các tình huống phức tạp, đồng thời quản lý thuận tiện cấp độ người bán và thông tin cơ bản.",
	// 31.了解更多
	liaojie20: "Tìm hiểu thêm",
	// 代付系统
	daifu21: "Hệ thống thanh toán",
	// 接入所有主流
	zhuliu22: "Truy cập vào tất cả các giao diện thanh toán chính cùng một lúc, tính khả dụng của hệ thống 99,99%, để đáp ứng nhu cầu giao dịch phong phú của bạn và cung cấp cho người dùng một trải nghiệm thanh toán hoàn hảo.",
	// 1.详细记录每一条交易信息
	jiaoyi23: "Ghi lại chi tiết từng giao dịch",
	// 全方位、多方面
	fangwei24: "Thống kê tóm tắt dữ liệu giao dịch toàn diện và nhiều khía cạnh",
	// 深度分析
	fenxi25: "Phân tích kỹ hiển thị doanh thu và giá trị thông tin của mỗi giao dịch",
	// 精细化的财务展示
	caiwu26: "Hiển thị tài chính tinh tế và kiểm tra tài khoản hiệu quả",
	// 5.清晰的数据逻辑和准确的财务数据
	shuju27: "Dữ liệu logic rõ ràng và dữ liệu tài chính chính xác",
	// 松实现内部对账
	duixhang28: "Dễ dàng thực hiện đối chiếu nội bộ, đối chiếu người bán và đối chiếu giao diện, giảm chi phí tài chính",
	// 10.接口放资金直清
	jiekou29: "Thanh toán trực tiếp qua giao diện",
	// 11. 提供支付接口对接服务
	fuwu30: "RGPay cung cấp dịch vụ cho các giao diện thanh toán",
	// 资金由接口方进行清算
	qingsuan31: "Tiền được bên giao diện tiến hành kiểm tra và RGPay cung cấp các dịch vụ kỹ thuật giao diện thanh toán an toàn và bảo mật.",
	// 32.代付系统
	daifu32: "Hệ thống thanh toán",
	// 入所有主流支付接口
	ruliu33: "Truy cập vào tất cả các giao diện thanh toán chính cùng một lúc, tính khả dụng của hệ thống 99,99%, để đáp ứng nhu cầu giao dịch phong phú của bạn và cung cấp cho người dùng một trải nghiệm thanh toán hoàn hảo.",
	// 一个 API，在任何平台和场景
	changjign34: "Một API để truy cập các chức năng thanh toán trên bất kỳ nền tảng và tình huống nào. Hỗ trợ ：",
	// 立即联系
	liji35: "Liên hệ ngay",
	// 36.二维码支付
	zhifu36: "Quét mã QR thanh toán",
	// 38.扫二维码支付
	sao37: "Quét mã QR thanh toán",
	// 线下商户二维码台卡
	xinxia38: "Dùng bảng mã QR của người bán, thuận tiện cho việc nhận thanh toán, các hoạt động thanh toán hàng ngày của người bán rất rõ ràng, từ nhận thanh toán, kiểm tra luồng tiền, kiểm tra hóa đơn, v.v., kết nối chặt chẽ giữa người bán và người tiêu dùng.",
	// 44.立即开户
	kauhu39: "Mở tài khoản",
	// 45.联系我们
	lianxi40: "Liên hệ chúng tôi",
	// 联系方式
	lianxifangshi41: "Phương thức liên lạc",
	// 47.产品项目
	chanpin42: "Sản phẩm",
	// 48.聚合收款
	juhe43: "Thanh toán tổng hợp",
	// 49.子商户系统
	zishanghu44: "Hệ thống người bán phụ",
	// 50.代付系统
	dsifu45: "Hệ thống thanh toán",
	// 52.关于我们
	guanyu46: "Về chúng tôi",
	// 接口合作
	jiekou47: "Hợp tác kết nối",
	// 51.关于公司
	gognsi48: "Về công ty",
	// 54.流量合作
	liuliang49: "Hợp tác dung lượng",
	// 开发者
	kaifa50: "Kỹ thuật viên",
	// 56.DEMO体验
	demo51: "Demo trải nghiệm",
	// 57.API开发文档
	kaifa52: "API phát triển dữ liệu",
	// 58.SDK下载
	xiazai53: "Tải SDK",
	// 扫一扫
	sao54: "Quét mã",
	// 版权所有
	banquan55: "© 2023 RGPay Mọi quyền được bảo lưu.",
	// 33.详情咨询
	zixin56: "Tư vấn chi tiết",
	// 40.现在加入
	jiaru57: "Tham gia ngay",
	// 当前语言
	language: "VN",
	// 菜单-------------------------------------------
	// 2.首页
	shouye58: "Trang chủ",
	// 产品中心/
	shanpin59: "Sản phẩm",
	// 开发者中心
	kaifa60: "Trung tâm kỹ thuật viên",
	// 5.业务登录
	yewu61: "Đăng nhập giao dịch",
	// 6.商家注册
	zhuce62: "Đăng ký người bán",
	// 商户注册----------------------------------------
	// 1.商户注册
	shnahghu63: "Người bán đăng kí",
	// 2.请输入账号
	shuru64: "Vui lòng nhập tài khoản",
	// 3.请输入密码
	mima65: "Vui lòng nhập mật khẩu",
	// 4.请再次输入密码
	again66: "Vui lòng nhập lại mật khẩu",
	// 5.请输入您的Email地址
	email67: "Vui lòng nhập địa chỉ email của bạn",
	// 6.注册
	zhuce68: "Đăng kí",
	// 7.已有账号？点这里-->登录
	you69: "Đã có tài khoản? Bấm vào đây -> ",
	// 登录
	denlu70: "Đăng nhập",
	// 业务登录页面---------------------------------------------------------------------
	// 2.商户登录
	shanghu71: "Người bán đăng nhập",
	// 3.请输入账号
	shuru72: "Vui lòng nhập tài khoản",
	// 4.请输入密码
	mima73: "Vui lòng nhập mật khẩu",
	// 7.代理登录
	daili74: "Đại lí đăng nhập",
	// 11.还没有账号？立即注册
	meiyou75: "Bạn chưa có tài khoản?",
	// 注册
	zhuce76: "Đăng kí.",
	//开发者中心---------------------------------
	// 1.开发者中心
	kaifa77: "Developer Center",
	// DEMO体验
	demo78: "DEMO thử nghiệm",
	// 商品名称
	shangpin79: "Tên gọi sản phẩm",
	// 4.测试订单
	ceshi80: "Đơn hàng thử nghiệm",
	// 5.订单编号
	dingdan81: "Mã đơn hàng",
	// 6.订单金额
	jine82: "Giá trị đơn hàng",
	// 支付方式
	zhifu83: "Phương thức thanh toán",
	// 8.支付宝扫码
	saoma84: "Alipay",
	// 9.微信扫码
	weixin85: "Wechat Pay",
	// 10.微信h5
	h586: "Wechat h5",
	// 11.QQ钱包扫码
	qianbao87: "Ví tiền QQ",
	// 12.百度钱包
	baidu88: "Ví tiền Baidu",
	// 13.银联快键
	yinlian89: "Union Pay",
	// 14.在线网银
	zaixian90: "Ngân hàng trực tuyến",
	// 15.需要支付
	xuyao91: "Cần thanh toán",
	// 16.立即支付
	liji92: "Thanh toán ngay",
	// 17.Copyright © 2021 聚合支付 版权所有
	// API开发文档
	kaifa93: "Triển khai dữ liệu API",
	// 19.API开发文档简介
	wendang94: "Giới thiệu về triển khai dữ liệu API",
	// 20.接入网关
	jieru95: "Cổng truy cập",
	// 21.签名算法
	xianming96: "Thuật toán Chữ ký",
	// 22.商户统一下单API
	shanghu97: "API đặt hàng hợp nhất của người bán",
	// 23.异步通知
	// 61.单位：元
	yuan98: "RM",
	// 支付体验收银台
	tiyan99: "Thanh toán kinh nghiệm",
	// 253.SDK下载
	xiazai100: "Tải SDK",
	// 越南语
	// yuenan101:"ViệtName"
	// 关于我们-------------------------------------------------------------------------------
	// 1.关于我们
	women102: "Về chúng tôi",
	// 联系我们
	lianxi103: "Liên hệ chúng tôi",
	// 4.企业QQ
	qiyeqq104: "QQ của công ty",
	// 5.客服QQ
	kefuqq105: "QQ CSKH",
	// 6.咨询热线
	zixun106: "Đường dây nóng tư vấn:",
	// 7.联系邮箱
	lainxi107: "Email:",
	// 加我领红包
	jiawo108: "Thêm chúng tôi để nhận lì xì",
	// 8.公司地址：广州海珠区新港东路1888号中洲交易中心
	adress109: "Địa chỉ công ty",
	// 支付公司合作
	hezuo110: "Các công ty thanh toán hợp tác",
	// 11.支付公司合作敬请联系客服人员
	kefu111: "Vui lòng liên hệ với nhân viên dịch vụ khách hàng để biết các công ty thanh toán đã hợp tác.",
	// 国内领先的支付技术解决方案...
	wenan112: "RGPay là nhà cung cấp dịch vụ giải pháp công nghệ thanh toán hàng đầu trong nước, cam kết giúp các công ty tiếp cận một hệ thống thanh toán trang nhã và đáng tin cậy với chi phí thấp nhất, đồng thời thị hóa dữ liệu để hỗ trợ hoàn thành các chính sách kinh doanh.",
	// 13.立即联系
	lianxi113: "Liên hệ ngay",
	// 流量合作
	liuliang114: "Đối tác tiềm năng ",
	// 15.支付流量合作敬请联系客服人员
	kefu115: "Vui lòng liên hệ với nhân viên dịch vụ khách hàng biết các đối tác tiềm năng.",
	// 提供支付接口代申请服务
	fuwu116: "RGPay cung cấp các dịch vụ ứng dụng giao diện thanh toán, và mở rộng dữ liệu của giao diện thanh toán đến giá trị tối đa, hỗ trợ mọi đối tác kinh doanh giải quyết vấn đề kết nối thanh toán.",
	// 17.联系客服
	kefu117: "Liên hệ CSKH",
	// ----------------------------------------------
	// 用户登录和注册语言提示
	// 1.请输入用户名
	userName118: "Xin nhập tên người dùng",
	// EN: Please enter user name
	// 2. 请输入密码
	password119: "Xin nhập mật khẩu",
	// EN: Please enter password
	// 3. 请重复输入密码
	argin120: "Xin nhập lại mật khẩu",
	// EN: Please enter password again
	// 4. 请输入邮箱
	email121: "Xin nhập email",
	// EN: Please enter email
	// 5. 两次输入不一致
	errorTow122: "Hai lần nhập không giống nhau",
	// EN:Two inputs are inconsistent
	// 邮件格式错误，请重新输入
	errorMessage123: "Định dạng email bị lỗi, vui lòng nhập lại. ",
	// errorMessage123: "Email format is not correct, please enter again."
	// ----------------------
	// 手机支付页面
	// -CN: 保存此二维码图片
	baocun124: "Lưu ãnh mã QR này",
	// -CN: 充值内容
	chognzhi125: "Nội dung nạp tiền",
	// -CN: 复制
	copy126: "Sao chép",
	// -CN: 到期时间
	daoqi127: "Thời gian hết hạn",
	// CN: QR码只可使用一次
	one128: "Mã QR chỉ dung một lần cho  lần thanh toán này",
	// CN: 请填写充值内容：P2987S（此外，请勿写其他任何东西，以免Zalo Pay钱包出现延误
	qr129: "Vui lòng ghi nội dung nạp tiền: P2987S (ngoài ra không ghi gì khác tránh việc tiền vào ví RGPay chậm trễ)",
	// ------------------------------------------
	// 开发者文档页面
	// API开发文档简介
	wandang130: "Giới thiệu về triển khai dữ liệu API",
	// 本文阅读对象：
	duixiang131: "Bài viết này dành cho: kiến ​​trúc sư kỹ thuật, kỹ sư R & D và kỹ sư vận hành và bảo trì hệ thống sử dụng hệ thống tự phục vụ người bán của RGPayz. Thông qua tài liệu này, người bán có thể tìm hiểu về công nghệ truy cập, sản phẩm và dịch vụ, quy trình truy cập, thông số kỹ thuật truy cập và thông tin khác của RGPay, để người bán có thể hoàn thành công việc truy cập thành công.",
	// 接入网关
	wanggaun132: "Cổng truy cập",
	// 请登录商户中心...
	shanghu133: "Vui lòng đăng nhập vào trung tâm người bán và kiểm tra địa chỉ cổng và các tham số kết nối API trong tài liệu phát triển quản lý API.",
	// 204.签名算法
	shuanfa134: "Thuật toán chữ kí",
	// 205.签名生成的通用步骤如下：
	buzou135: "Các bước chung để tạo chữ ký như sau:",
	// 第一步，设所有发.....
	first136: "Bước 1, đặt tất cả dữ liệu được gửi hoặc nhận là tập hợp M, sắp xếp các tham số của giá trị tham số không trống trong tập hợp M theo mã ASCII của tên tham số từ nhỏ đến lớn (thứ tự từ điển), và sử dụng định dạng của các cặp khóa-giá trị URL (tức là key1 = value1 & key2 = value2 ...) được nối thành một chuỗi.",
	// 第二步，在stringA...
	two138: "Bước 2, áp dụng khóa cho lần nối cuối cùng của stringA để lấy chuỗi ký tự stringSignTemp và thực hiện thao tác MD5 trên stringSignTemp, sau đó chuyển đổi tất cả các ký tự của chuỗi ký tự thu được thành chữ hoa để có được giá trị dấu signValue.",
	// 209.提交方式：POST
	post139: 'Phương thức gửi: POST',
	// 35.地址： 接入网关/Pay_Index.html
	wangguan140: "Địa chỉ: cổng đăng nhập/Pay_Index.html",
	// 63.异步通知
	yubu141: "Thông báo không đồng bộ",
	// 接收到服务器点对点通讯时
	tongxun142: "Khi nhận được giao tiếp ngang hàng của máy chủ, hãy nhập 'OK' trên trang (không có dấu ngoặc kép, OK hai chữ cái được viết hoa), nếu không, thông báo ngang hàng sẽ được gửi liên tục 5 lần.",
	// 85.订单查询接口
	chaxun143: "Cổng kiểm tra đơn hàng",
	// 87.地址： 接入网关/Pay_Trade_query.html
	dizhi144: "Địa chỉ: cổng đăng nhập/ Pay_Trade_query.html",
	// 账户余额查询接口
	zhuanghu145: "Giao diện kiểm tra số dư",
	// 210.接口地址：网关地址+/Payment_Dfpay_balance.html
	dizhi146: "Địa chỉ giao diện: địa chỉ cổng + / Payment_Dfpay_balance.html",
	// 238.渠道编码
	qudao147: "Mã kênh",
	// 32.请登录商户中心，API管理 开发文档中 查看网关地址和API对接参数。
	canshu148: "Vui lòng đăng nhập vào trung tâm người bán và kiểm tra địa chỉ cổng và các tham số kết nối API trong tài liệu phát triển quản lý API.",
	// 订单支付接口
	dingdan149: "Cổng thanh toán đơn hàng",
	// 252.API开发文档
	wendang150: "API phát triển dữ liệu",
	// 注册成功,请等待管理员审核.
	shenhe151: " Đăng ký thành công, vui lòng đợi quản trị viên xét duyệt.",
	// 服务器内部错误
	error152: "Máy chủ lỗi nội bộ",
	// 此页面无法访问
	wufa153: "Không thể truy cập trang này",
	// ----------------------------------------------------------
	// 支付相关页面
	// -剩余时间：544秒
	timeOut154: "Thời gian còn lại: 544 giây",
	// -请输入验证码已经发短信到您的手机或者用银行应用登录名：NONE
	shuru155: "Vui lòng nhập mã OTP trong tin nhắn hoặc ứng dụng ngân hàng của bạn với RefID là: NONE",
	// -请注意，OTP执行时间仅为60秒
	miao156: "Quý khách lưu ý thời gian thực hiện OTP chỉ có 60 giây",
	// -输入OTP验证码
	yanzheng157: "Nhập mã OTP",
	// -下一步
	xiayibu158: "Tiếp theo",
	// Lưu ý 备注  Note
	beizhu159: 'Mỗi mã QR hoặc thông tin gửi tiền chỉ được sử dụng 1 lần, nếu sử dụng lần nữa có khả năng thất thoát giao dịch, cần thời gian 1 tuần để xử lý. Quý khách vui lòng thực hiện theo hướng dẫn Nạp Tiền để tránh xảy ra sai xót. Xin cảm ơn. ',
	// 1.交易编号
	bianhao160: "số tham chiếu",
	// 交易时间
	shijian161: "Thời gian giao dịch",
	// 金额
	jine162: "Số tiền",
	// 结果
	jieguo163: "Kết quả",
	// 没有输入信息
	xinxi164: "Không có thông tin nào được nhập",
	// Thời gian时间
	shijian165: "Thời gian",
	// 交易代码
	daima166: "mã giao djch",
	// -请等待您的交易被处理
	wait167: "Vui lòng chờ giao dịch của bạn đang trong quá trình xử lí",
	// -充值金额
	jine168: "Số tiền nạp:",
	// -充值编号
	bianhao169: "Mã nạp tiền:",
	// -交易成功时间
	shijian170: "Thời gian thành công:",
	// -检查交易状态
	zhuangtai171: "Kiểm tra trạng thái giao dịch",
	// 支付成功
	success172: "Thanh toán thành công",
	// 支付失败
	shibai173: "Thanh toán thất bại",
	// 银行
	bank174: "ngân hàng",
	// 支付类型
	leixing175: "Phương thức thanh toán",

	////////////////////////////////////////////////////////////////////////////////////////////
	// 文档中心新修改文档翻译 不支持英文
	/////////////
	// 目录
	// 1.接口规范
	jiekou176: "Thông số kỹ thuật giao diện",
	// 2. 签名规范
	jianming177: "Thông số kỹ thuật chữ ký",
	// 3. 请求规范
	qingqiu178: "Thông số kỹ thuật yêu cầu",
	// 4. 服务器接口列表
	jiekou179: "Danh sách giao diện máy chủ",
	// 5. 创建支付订单
	chuangjian180: "Tạo lệnh thanh toán",
	// 6. 查询支付订单
	chaxun181: "Kiểm tra lệnh thanh toán",
	// 7. 支付结果异步通知
	zhifu182: "Thông báo kết quả thanh toán không đồng bộ",
	// 8. 关闭订单
	guanbi183: "Đóng lệnh",
	// 9. 获取支付渠道列表
	gongong184: "Nhận danh sách các kênh thanh toán",
	// 10.公共返回码
	fulu185: "Mã trả về công khai ",
	// 11.附录
	add11fulu: "Phụ lục",
	// 目录结束 文档开始
	///////////////////////////////////////////////////
	// 商户接入系统时，调用API 必须遵守以下规则
	// 1.接口规范
	guizhe186: "Khi người bán truy cập vào hệ thống, muốn dùng API phải tuân thủ các quy tắc sau:",
	// 2. 签名规范
	jianming187: "Bước 1: Sắp xếp dữ liệu được ký theo mã ASCII của tên tham số từ nhỏ nhất đến lớn nhất (thứ tự từ điển); sau đó sử dụng & nối ở dạng cặp khóa-giá trị (key=value) để tạo thành một chuỗi toàn bộ (chẳng hạn như: A = 123 & B = 456) dưới dạng StringA ；",
	// ``````第二步
	er188: "Bước 2: tại StringA, thêm key tương ứng (chẳng hạn như: A = 123 & B = 456 & key = 123456) để nhận được StringB;",
	// ``````第三步
	san189: "Bước 3: Thực hiện phép tính MD5 trên StringB, sau đó chuyển chuỗi kết quả thành chữ hoa và giá trị thu được là giá trị sign.",
	//````` 举例：
	juli190: "Ví dụ:",
	// `````现有以下数据需要签名： 
	xianyou191: "Dữ liệu sau đây cần đượcký： ",
	//````` username：admin password：123456 timestamp：1234567890 nonceStr：e134b0c1d94
	// 签名 secret_key:**************** (请向技术人员索取) 
	username192: "username：admin ",
	password193: "password：123456 ",
	timestamp194: "timestamp：1234567890",
	nonceStr195: "nonceStr：e134b0c1d94",
	secret_key196: "Chữ ký secret_key:**************** (Xin hỏi kỹ thuật viên) ",
	// 第一步：排序并拼接字符串 
	pinjie197: "Bước 1: Sắp xếp và nối các chuỗi",
	juli198: "StringA =”password=123456&timestamp=1234567890&nonceStr=e134b0c1d94&username=admin”",
	// `````第二步：最后拼接上 key 字段
	ziduan199: "Bước 2: Cuối cùng, nối đoạn kí tự key",
	pinjie200: "StringB = StringA + ”&key=****************” ",
	// ````第三步：MD5 计算，并转为大写 
	md5201: "Bước 3: Tính toán MD5 và chuyển đổi thành chữ hoa",
	// Sign = strtoupper( md5(StringB) ) 
	Sign202: "Sign = strtoupper( md5(StringB) ) ",
	//////////////////////////////////////////////////////////////////////////////////
	// 3.请求规范 
	guifan203: "1). Phương thức yêu cầu là: POST, hỗ trợ ba kiểu nội dung: form-data, x-www-form-urlencoded, raw (json);",
	guifen204: "2). Lang / ngôn ngữ hiện hỗ trợ: Tiếng Việt (vi), Tiếng Anh (en-Us), người Trung Quốc (en-Us) Chỉ cần thêm các tham số khác khi yêu cầu: lang = vi hoặc lang = en-Us hoặc lang=zh-cn.",
	/////////////////////////////////////////////////////////////////////////////////////////
	// 4.服务器接口列表 
	// 服务器 
	fuwu205: "Máy chủ",
	// 地址 
	dizhi206: "Địa chỉ ",
	// 接口网关
	wangguan207: "Cổng giao diện",
	// 版本
	banban208: "Phiên bản",
	// 请求范例
	guifan209: "Ví dụ yêu cầu ",
	fanli210: "1. Địa chỉ được yêu cầu là: cổng giao diện + phiên bản + địa chỉ giao diện" + '\n',
	fanli211: "2. Chẳng hạn như tạo lệnh thanh toán：https://api.rg-pay.com/v1/pay",
	//////////////////////////////////////////////////////////////////////////////////////////
	// 5.创建支付订单 
	dizhi212: "Địa chỉ giao diện：/pay",
	// 请求参数：
	qingqiu213: "Tham số yêu cầu：",
	// 表格
	// 表头标题--参数 /参数名/必须/描述
	canshu214: "Tham số ",
	canshuming215: "Tên tham số ",
	bixu216: "Cần hay không",
	miaoshu217: "Miêu tả",
	// 第二列商户id/支付订单号/支付时间/支付通道ID/异步回调地址/页面跳转地址/支付金额/当前UNIX时间戳/随机串/商品描述/支付类型/内容类型/签名
	// 1-7
	lie218: "Id người bán",
	lie219: "Mã lệnh thanh toán",
	lie220: "Thời gian thanh toán",
	lie221: "ID kênh thanh toán",
	lie222: "Địa chỉ gọi lại không đồng bộ",
	lie223: "Địa chỉ chuyển trang",
	lie224: "Số tiền thanh toán",
	// 8-13
	lie225: "Dấu thời gian UNIX hiện tại",
	lie226: "Chuỗi ngẫu nhiên",
	lie227: "Mô tả Sản phẩm",
	lie228: "Loại thanh toán",
	lie229: "Nội dung thanh toán",
	lie230: "Chữ ký",
	// 第三列 必填  是/否
	yes231: "Có",
	no232: "Không",
	// 第四列
	// 1-7
	// 请向技术人员索取
	suoqu233: "Xin hỏi kỹ thuật viên",
	// 如：20210602101815555456
	ru234: "Ví dụ：20210602101815555456",
	// 如：2021-05-31 10:56:22
	ru235: "Ví dụ：2023-05-31 10:56:22",
	// 具体请查看文档最后的附录信息
	juti236: "Chi tiết vui lòng xem thông tin phụ lục cuối tài liệu",
	// 支付提交后会把支付状态数据异步POST数据到该地址
	zhifu237: "Sau khi thanh toán được gửi, dữ liệu trạng thái thanh toán sẽ không đồng bộ dữ liệu POST đến địa chỉ này",
	// 当有传递该值时，支付提交后会跳转回该地址（注：状态数据会拼到该地址的URI部分里）
	chuandi238: "Khi giá trị được chuyển qua, khoản thanh toán sẽ được chuyển hướng trở lại địa chỉ sau khi gửi (Lưu ý: dữ liệu trạng thái sẽ được viết vào phần URI của địa chỉ)",
	// 越南盾(RM)，单位：元
	dun239: "Đồng Việt Nam (RM)，Đơn vị：Đồng",
	// 8-13
	// 以秒为单位的时间戳，如：1622429782
	danweimiao240: "Dấu thời gian hiện tại của Việt Nam tính bằng giây, ví dụ：1622429782",
	// 随机字符串，长度为10-32位字符，如：nkkMTAkLkR4U
	zifuchuan241: "Chuỗi ngẫu nhiên, độ dài 10-32 ký tự, ví dụ：nkkMTAkLkR4U",
	// 商品描述，如：充值
	chognzhi242: "Mô tả sản phẩm, ví dụ: nạp tiền",
	// 传递1为网页支付、传递2为扫码支付
	cuandi243: "Pass 1 là thanh toán qua web, pass 2 là thanh toán bằng mã quét",
	// 异步回调返回数据内容类型，可选值：1、multipart/form-data（默认）2、application/x-www-form-urlencoded3、text/plain
	huidiao244: "Gọi lại không đồng bộ trả về kiểu nội dung dữ liệu, giá trị tùy chọn: " + '\n' +
		"  1、multipart/form-data（mặc định）  " + '\n' + " 2、application/x-www-form-urlencoded " + '\n' +
		"3. Ứng dụng / json hoặc văn bản / đơn giản (cả hai đều là json)",
	// 签名生成算法请查看签名规范（注：除了该参数不参与签名外，其它参数均参与签名）
	qianming245: "Đối với thuật toán tạo chữ ký, vui lòng kiểm tra đặc điểm kỹ thuật chữ ký (lưu ý: ngoại trừ tham số này không tham gia vào chữ ký, các tham số khác liên quan đến chữ ký)",
	// 返回参数：
	fenhui246: "Tham số trả về： ",
	// 返回码 /返回消息 /返回数据 
	fanhuima247: "Mã trả về",
	fanhuixiaoxi248: "Tin nhắn trả về",
	fanhuishuju249: "Dữ liệu trả về",
	// 1-5
	// 0代表成功，1代表失败，其它请看公共返回码
	daibiao250: "0 có nghĩa là thành công, 1 có nghĩa là thất bại, khác vui lòng xem mã trả lại công khai",
	// 返回消息，如：success 
	success251: "Tin nhắn trả về，ví dụ：success ",
	// 成功时必定返回以下数据 
	successdata252: "Khi thành công dữ liệu sau phải được trả lại ",
	// 带有订单ID的支付页面网址，商户拿到该地址后直接跳转即可。
	dingdan253: "Địa chỉ URL thanh toán có lệnh ID, người bán sau khi lấy địa chỉ này có thể trực tiếp chuyển qua.",
	// 平台支付订单号，如：20210602101815555456
	pingtai254: "Mã lệnh thanh toán, ví dụ：20210602101815555456",
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 6.查询支付订单 
	// 当付完款之后，返回支付错误或支付中时，建议每间隔 3 秒调用本接口来获取当前订单最新的支付状态，直至支........
	fukuan255: "Sau khi thanh toán xong, quay về thanh toán lỗi hoặc đang thanh toán, nên gọi giao diện này 3 giây một lần để có được trạng thái thanh toán mới nhất của đơn hàng hiện tại, đến khi thanh toán thành công sẽ có thông báo nhắc nhở. thanh toán thành công; hoặc chủ động đóng đơn đặt hàng.",
	// 接口地址：/pay/query 
	jikeou256: "Địa chỉ giao diện: /pay/query ",
	// 目录6表格1-请求参数： 
	mulu6: {
		// 参数名 (商户号 /支付订单号/随机串 /时间戳 /签名 )
		mulu6shanghu257: "Mã người bán",
		canshuming: {
			mulu6shanghu257: "Mã người bán",
			mulu6dingdanhao258: "Mã lệnh thanh toán",
			mulu6sunjicuan259: "Chuỗi ngẫu nhiên",
			mulu6shijiancuo260: "Dấu thời gian ",
			mulu6qianming261: "Chữ ký"
		},
		// 描述 ()
		miaoshu: {
			suoqu262: "Xin hỏi kỹ thuật viên",
			li263: "Ví dụ：20210602101815555456",
			zifucuan264: "Chuỗi ngẫu nhiên, độ dài 10-32 ký tự ，ví dụ：nkkMTAkLkR4U",
			shijiancuo265: "Dấu thời gian hiện tại của Việt Nam tính bằng giây ，ví dụ：1622429782",
			suanfa267: "Đối với thuật toán tạo chữ ký, vui lòng kiểm tra đặc điểm kỹ thuật chữ ký (lưu ý: ngoại trừ tham số này không tham gia vào chữ ký, các tham số khác liên quan đến chữ ký)",


		}
	},
	// 目录6表格2-返回参数：
	mulu6_2: {
		// 表格名--返回参数
		fanhuicanshu268: "Tham số trả về：",
		canshufanhui: {
			column1: {},
			column2: {
				data1: "Mã trả về ",
				data2: "Tin nhắn trả về",
				data3: "Dữ liệu trả về",
				data4: "Mã người bán",
				data5: "Mã lệnh",
				data6: "Số tiền thanh toán",
				data7: "Thời gian thanh toán thành công / thất bại",
				data8: "Mã lệnh",
				data9: "Loại thanh toán",
				data10: "Trạng thái yêu cầu",
				data11: "Trạng thái thanh toán",
				data12: "Chuỗi ngẫu nhiên",
				data13: "Dấu thời gian",
				data14: "Chữ ký",
			},
			column3: {},
			column4: {
				data1: "0 có nghĩa là thành công, 1 có nghĩa là thất bại, khác vui lòng xem mã trả lại công khai",
				data2: "Tin nhắn trả về，ví dụ：success ",
				data3: "Khi thành công dữ liệu sau phải được trả lại ",
				data4: "Xin hỏi kỹ thuật viên",
				data5: "Mã lệnh người bán ",
				data6: "Đồng Việt Nam (RM)，Đơn vị：Đồng",
				data7: "Ví dụ：2021-05-31 10:56:22",
				data8: "Ví dụ：20210602101815555456",
				data9: "1 là thanh toán qua web, 2 là thanh toán bằng mã quét",
				data10: "00 có nghĩa là thành công, khác có nghĩa là thất bại",
				data11: "NORGPay- CHƯA THANH TOÁN / SUCCESS- ĐÃ THANH TOÁN  / USERPAYING- ĐANG THANH TOÁN / REFUND- Chuyển để hoàn tiền / REVOKED-Đã thu hồi / CLOSED- ĐÃ ĐÓNG / PAYERROR- THANH TOÁN BỊ LỖI",
				data12: "Chuỗi ngẫu nhiên, độ dài 10-32 ký tự，ví dụ：nkkMTAkLkR4U",
				data13: "Dấu thời gian hiện tại của Việt Nam tính bằng giây，ví dụ：1622429782",
				data14: "Đối với thuật toán tạo chữ ký, vui lòng kiểm tra đặc điểm kỹ thuật chữ ký (lưu ý: ngoại trừ tham số này không tham gia vào chữ ký, các tham số khác liên quan đến chữ ký)",
			}
		}
	},
	///////////////////////////////////////////////////////////////////
	// 目录7.支付结果异步通知
	inform7_269: {
		title1: "Nếu tham số pay_notifyurl được chọn trong giao diện đặt hàng, khi khách hàng thanh toán thành công, máy chủ của ứng dụng sẽ đẩy dữ liệu kết quả thanh toán đến địa chỉ này, người bán cần nhận và xử lý. Khi tương tác với các thông báo nền, nếu ứng dụng nhận được phản hồi của người bán không phải là chuỗi chữ số SUCCESS hoặc trả về sau hơn 5 giây, ứng dụng sẽ xem như thông báo không thành công và ứng dụng sẽ chuyển một chiến lược nhất định (tần suất thông báo là 0/5/10/15/20/25 / 30...86400, đơn vị: giây) Gián tiếp bắt đầu lại thông báo để tăng tỷ lệ thông báo thành công nhiều nhất có thể, nhưng không có gì đảm bảo rằng thông báo sẽ được thành công cuối cùng. Nếu bạn không thể nhận được thông báo trong một thời gian dài, nên chủ động chuyển đến kiềm tra lệnh.",
		title2: "Do tình trạng gửi lại các thông báo nền, cùng một thông báo có thể được gửi đến hệ thống bán hàng nhiều lần. Hệ thống người bán phải có khả năng xử lý chính xác các thông báo trùng lặp. Cách tiếp cận được đề xuất trước tiên là kiểm tra trạng thái của dữ liệu kinh doanh tương ứng khi nhận được thông báo để xử lý, xác định xem thông báo đã được xử lý chưa, nếu chưa được xử lý thì xử lý và nếu được xử lý thì trả kết quả. trực tiếp. Trước khi kiểm tra trạng thái và xử lý dữ liệu bán hàng, nên sử dụng khóa dữ liệu để kiểm soát đồng thời để tránh nhầm lẫn dữ liệu do hàm số trùng lập.",
		title3: " Đặc biệt lưu ý: Sau khi nhận được các thông số thông báo, người bán phải xác minh mã lệnh orderID đơn hàng và số tiền đơn hàng Amount trong các thông số thông báo nhận được với đơn hàng và số lượng của hệ thống kinh doanh, và chỉ cập nhật trạng thái đơn hàng sau khi xác minh nhất quán. Và hãy chắc chắn xác minh chữ ký được trả lại để đảm bảo tính chính xác của tin nhắn.",
		// 通知参数
		tongzhi4: "Tham số thông báo：",
		column2: {
			val1: "Mã người bán",
			val2: "Mã đơn hàng",
			val3: "Số tiền thanh toán",
			val4: "Thanh toán thành công / thất bại / thời gian kiểm tra",
			val5: "Mã lệnh chuyển",
			val6: "Loại thanh toán ",
			val7: "Trạng thái yêu cầu",
			val8: "Trạng thái thanh toán ",
			val9: "Chuỗi ngẫu nhiên ",
			val10: "Dấu thời gian",
			val11: "Chữ ký",
		},
		column4: {
			val1: "Xin hỏi kỹ thuật viên",
			val2: "Mã đơn hàng người bán",
			val3: "Đồng Việt Nam (RM)，Đơn vị：Đồng",
			val4: "Ví dụ：2021-05-31 10:56:22",
			val5: "Ví dụ：20210602101815555456",
			val6: "1 là thanh toán qua web, 2 là thanh toán bằng mã quét",
			val7: "00 có nghĩa là thành công, khác có nghĩa là thất bại",
			val8: "NORGPay- CHƯA THANH TOÁN / SUCCESS- ĐÃ THANH TOÁN  / USERPAYING- ĐANG THANH TOÁN  / REFUND- Chuyển để hoàn tiền / REVOKED-Đã thu hồi / CLOSED- ĐÃ ĐÓNG  / PAYERROR- THANH TOÁN BỊ LỖI;",
			val9: "Chuỗi ngẫu nhiên, độ dài 10-32 ký tự，ví dụ：nkkMTAkLkR4U",
			val10: "Dấu thời gian hiện tại của Việt Nam tính bằng giây，ví dụ：1622429782",
			val11: "Đối với thuật toán tạo chữ ký, vui lòng kiểm tra đặc điểm kỹ thuật chữ ký (lưu ý: ngoại trừ tham số này không tham gia vào chữ ký, các tham số khác liên quan đến chữ ký)",
		},
		title4: "Yêu cầu ĐĂNG sẽ được gửi đến địa chỉ pay_notifyurl theo định dạng dữ liệu được chuyển trong trường content_type khi đơn đặt hàng được đặt."
	},
	////////////////////////////////////////////
	// 目录8.关闭订单 
	CloseTheOrder8_270: {
		title1: "Khi thời gian chờ đợi quá lâu, khách hàng có thể chủ động đóng lệnh để tránh tình trạng bị tính tiền quá nhiều của khách hàng.",
		title2: "Địa chỉ giao diện：/pay/close",
		title3: "Tham số yêu cầu：",
		table: {
			column2: {
				val1: "Mã người bán",
				val2: "Mã lệnh thanh toán ",
				val3: "Chuỗi ngẫu nhiên ",
				val4: "Dấu thời gian",
				val5: "Chữ ký"
			},
			column4: {
				val1: "Xin hỏi kỹ thuật viên",
				val2: "Ví dụ：20210602101815555456",
				val3: "Chuỗi ngẫu nhiên, độ dài 10-32 ký tự，ví dụ ：nkkMTAkLkR4U",
				val4: "Dấu thời gian hiện tại của Việt Nam tính bằng giây，ví dụ ：1622429782",
				val5: "Đối với thuật toán tạo chữ ký, vui lòng kiểm tra đặc điểm kỹ thuật chữ ký (lưu ý: ngoại trừ tham số này không tham gia vào chữ ký, các tham số khác liên quan đến chữ ký)",
			}
		},
		title4: "Tham số trả về： ",
		tableTwo: {
			column2: {
				val1: "Mã trả về",
				val2: "Tin nhắn trả về",
			},
			column4: {
				val1: "0 có nghĩa là thành công, 1 có nghĩa là thất bại, khác vui lòng xem mã trả lại công khai ",
				val2: "Tin nhắn trả về"
			}
		}
	},
	// 目录9.获取支付渠道列表
	common_9: {
		title1: "Địa chỉ cổng liên kết:：/pay/product",
		title2: "Tham số trả về：",
		table1: {
			column2: {
				val1: "Mã người bán",
				val2: "Chuỗi tùy chọn",
				val3: "Dấu thời gian",
				val4: "Chữ ký ",
			},
			column4: {
				val1: "Xin hỏi kỹ thuật viên",
				val2: "Chuỗi ký tự ngẫu nhiên, độ dài 10-32 ký tự, ví dụ: nkkMTAkLkR4U",
				val3: "Dấu thời gian hiện tại của Việt Nam tính bằng giây, ví dụ: 1624229782",
				val4: "Đối với thuật toán tạo chữ ký, vui lòng kiểm tra đặc điểm kỹ thuật chữ ký (lưu ý: ngoại trừ tham số này không tham gia vào chữ ký, các tham số khác có liên quan đến chữ ký) ",
			}
		},
		table2: {
			column2: {
				val1: "Mã trả về ",
				val2: "Tin nhắn trả về  ",
				val3: "Dữ liệu trả về  ",
				val4: "Tổng số hàng",
				val5: "Danh sách dữ liệu",
				val6: "Danh sách dữ liệu",
				val7: "Tên kênh",
				val8: "LOGO kênh",
				val9: "Phương thức thanh toán",
				val10: "Mã cấp dưới",
			},
			column4: {
				val1: "0 nghĩa là thành công, 1 có nghĩa là thất bại, vui lòng xem mã trả lại công khai cho những mã khác",
				val2: "Tin nhắn trả về ",
				val3: "Dữ liệu sau phải được trả lại khi thành công",
				val4: "Trả về tổng số hàng dữ liệu",
				val5: "Dữ liệu danh sách trả về có các trường sau:",
				val6: "ID kênh,  ví dụ 926",
				val7: "tên kênh,  ví dụ Vietcombank",
				val8: "Logo trang web của kênh",
				val9: "Các phương thức thanh toán hiện tại được ngân hàng hỗ trợ, 1 là thanh toán qua web, 2 là thanh toán bằng mã quét, các bội số được phân tách bằng dấu phẩy tiếng Anh, chẳng hạn như: 1, 2",
				val10: " Mã cấp dưới của ngân hàng, nếu không có, trả về trống",
			}
		}
	},
	/////////////////////////////////////////////////////////////////
	// 目录10
	returnCode10: {
		// 表头标题
		biaptou1: "Mã trả về",
		biaptou2: "Mô tả",
		biaptou3: "Phương án giải quyết",
		table1: {
			column1: {
				val1: "0",
				val2: "1",
				val3: "100-600",
			},
			column2: {
				val1: "Thành công",
				val2: "Không thành công và trả về một thông báo lỗi",
				val3: "Tương ứng với mã trạng thái HTTP, vui lòng kiểm tra chi tiết：https://zh.wikipedia.org/wiki/HTTP%E7%8A%B6%E6%80%81%E7%A0%81"
			}
		}
	},
	/////////////////////////////////////////////////////
	// 目录11
	appendix11: {
		title1: "Kênh thanh toán：",
		// 表格标题:
		headline1: "ID Kênh",
		headline2: "Tên kênh thanh toán",
		headline3: "Ghi chú",
		table1: {
			column1: {
				val1: "926",
				val2: "927",
				val3: "928",
				val4: "929",
				val5: "930",
				val6: "931",
				val7: "932",
				val8: "933",
				val9: "934",
				val10: "935",
				val11: "936",
				val12: "937",
				val13: "938",
				val14: "939",
				val15: "940",
				val16: "941",
				val17: "942",
				val18: "943",
			},
			column2: {
				val1: "Vietcombank",
				val2: "VietinBank",
				val3: "Techcombank",
				val4: "Agribank",
				val5: "MBBank",
				val6: "VPBank",
				val7: "ACB",
				val8: "BIDV",
				val9: "HDBank",
				val10: "VIB",
				val11: "Sacombank",
				val12: "TPBank",
				val13: "Eximbank",
				val14: "MaritimeBank",
				val15: "DongABank",
				val16: "OCB",
				val17: "SHB",
				val18: "LienVietPostBank",
			}
		}
	},
	// 目录1-接口规格
	specification: {
		table1: {
			column1: {
				val1: "Phương thức truyền tải ",
				val2: "Phương thức gửi đi",
				val3: "Định dạng dữ liệu ",
				val4: "Mã ký tự",
				val5: "Thuật toán chữ ký ",
			},
			column2: {
				val1: "Sử dụng truyền HTTPS",
				val2: "Gửi bằng phương pháp POST",
				val3: "Cả dữ liệu gửi và trả lại đều được hỗ trợ：định dạng form-data、x-www-form-urlencoded、raw (json) , Lưu ý: Đảm bảo sử dụng định dạng form-data khi gửi. Nếu sử dụng các định dạng khác, vui lòng đặt giá trị Content-Type dung trong tiêu đề header thành x-www-form-urlencoded hoặc application / json. ",
				val4: "Sử dụng mã ký tự UTF-8",
				val5: "Sử dụng MD5, tham khảo mục 2 để biết chi tiết",
			}
		}
	},
	// 支付页面的翻译validateTheInput.vue
	validateTheInput: {
		// 1.请输入用户名
		userNameNotHave: "Vui lòng nhập tên người dùng",
		// 2.请输入密码
		ppp: "Vui lòng nhập mật khẩu",
		// 3.请输入OTP校验码
		peoc: "Vui lòng nhập mã xác nhận OTP",
		// -----------------------------------------------------
		jine: "Số tiền ",
		// 事务处理时间
		Transaction: "Thời gian giao dịch",
		// .参考代码
		Reference: "Số tham chiếu",
		// 登入
		Log: "Đăng nhập",
		// 验证码
		Verification: "Mã xác thực",
		Result: "Kết quả",
		Account: "Số tài khoản",
		Password: "Mật khẩu",
		Remain90: "Còn lại ",
		Remain91: "giây",
		// 11-
		Remark: "Lưu ý",
		// 提示
		rishi501: "Vui lòng nhập đúng hộ khẩu và mật khẩu, khi xác minh xin vui lòng chọn xác minh tin nhắn OTP và ứng dụng ngân hàng để nhấp vào xác minh. Vui lòng làm theo hướng dẫn nạp tiền để tránh sai sót. Cảm ơn rất nhiều.",
		OTPcode: "Vui lòng nhập mã xác nhận OTP",
		// 请输入otp
		otpEnter: "Vui lòng nhập OTP vào ứng dụng SMS hoặc ngân hàng của bạn hoặc nhấn Xác minh: ",
		// 按钮下一步
		next: "Tiếp theo ",
		// 请稍等。您的交易正在进行中
		transaction: "Vui lòng chờ. Giao dịch của bạn đang trong quá trình xử lý",
		// 检查事务状态
		Check: "Kiểm tra trạng thái giao dịch",
		// 支付失败
		failed502: "Thanh toán thất bại",
		// 请稍等。您的交易正在进行中
		waiting503: "Vui lòng chờ. Giao dịch của bạn đang trong quá trình xử lý",
		success504: "Thanh toán thành công",
		jieguo505: "Kết quả ",
		shibai506: "Đã hết giờ đăng nhập"
	},
	transfer: {
		// 通过银行转账进行付款
		transfer: "Thanh toán bằng phương thức chuyển khoản ngân hàng",
		// 网银转账
		banking: "Chuyển khoản bằng internet banking",
		// 扫码转账
		payment507: "Chuyển khoản bằng QR code",
		// 账号
		zhanghao508: "Số tài khoản ủy nhiệm",
		// 账户名字
		mingzi509: "Tên chủ tài khoản",
		// 分行
		fenhang510: "Chi nhánh",
		// 金额
		jine511: "Tổng số tiền nộp",
		// 内容
		neirong512: "Nội dung",
		// 完成
		wanchang513: "Hoàn Thành",
		// 还有176秒
		shnegyu514: "Còn lại",
		// 转账成功后， 请点击完成按钮， 以便我们尽快处理交易。 感谢您使用我们的服务。
		fuwu515: "Sau khi chuyển khoản thành công, Quý khách nhấn nút Hoàn Thành để chúng tôi có thể xử lý giao dịch một cách nhanh nhất. Cảm ơn quý khách đã sử dụng dịch vụ của chúng tôi.",
		// 执行转移命令 -
		zhuanyi516: "Thực hiện lệnh chuyển khoản",
		// 访问银行的网上银行或手机银行网站进行转账 -
		fangwen517: "-Truy cập vào website internet banking hoặc Mobile banking của ngân hàng để thực hiện chuyển khoản",
		// 以下内容中的存款代码(GODETOXD8X0ZTK) 自生成后仅在 20 分钟内有效。 -
		youxiao518: "-Mã gửi tiền trong mục nội dung bên dưới ",
		jiade5181: "chỉ có hiệu lực trong vòng",
		jiade5182: "giây kể từ ngày tạo.",
		// 请在下面填写正确的信息以立即更新资金。 -
		dengxin519: "-Quý khách vui lòng điền đúng thông tin bên dưới đề được cập nhập tiền ngay lập tức.",
		// 注意： 请输入正确的 40,
		// 000 RM 金额， 并选择转账费用“ 人 / 转账单位” 形式以接收准确金额。 如果没有匹配项， 您的订单将被取消。 任何问题请联系客户支持。
		quxiao520: "-Lưu ý: Quý khách vui lòng nhập đúng số tiền  ",
		quxiao5201: "và chọn hình thức Phí chuyển “Người/ Đơn vị chuyển trả phí” để nhận được chính xác số tiền. Nếu không trùng khớp, lệnh của quý khách sẽ bị hủy. Mọi thắc mắc xin vui lòng liên hệ bộ phận hỗ trợ khách hàng.",
		// 秒
		miao521: "giây",
		// -------------------------
		// 内容必须有
		bixu522: "Nội dung bắt buộc",
		// 二维码仅供本次充值使用，请勿保存以备日后充值使用。
		shiyong523: "Mã QR chỉ cung cấp cho nạp tiền lần này, vui lòng không lưu lại sử dụng cho những lần nạp tiền sau.",
		// 二维码不能重复用
		chongfu524: "Không lập lại mã quét",
		// 打开QRPay在手机网银APP
		wangyin525: "Mở QRPay trên Mobile Banking",
		// 扫二维码
		erweima526: "Quét mã QR",
		// 确认金额完成交易
		wancheng527: "Xác nhận số tiền hoàn tất thanh toán",
		// 复制成功
		fail528: "Sao chép thành công",
		// 复制失败
		succsee529: "Sao chép thất bại",
		// 复制
		fuzhi530: "Sao chép",
		// ----------------------------
		// 超时/
		chaoshi531: "Hết giờ",
		// 失败
		shibai532: "Sự thất bại",
		// 成功
		chenggong534: "Sự thành công",
		result: 'Kết quả'
	}
}
