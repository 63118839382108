import axios from 'axios';
import {
	Message
} from "element-ui";
import {
	Loading
} from 'element-ui';
import {
	base,
	paramToUrl
} from "./base";
let baseURL = base.sever //接口请求的域名(生产和测试环境一般不同)
import VueI18n from 'vue-i18n'
const messages = {
	en: {
		message: {
			hello: 'Server internal error'
		}
	},
	vn: {
		message: {
			hello: 'Máy chủ lỗi nội bộ'
		}
	},
	cn: {
		message: {
			hello: '服务器内部错误'
		}
	}
}
// 获取当前语言
var getLang = function () {
	// var that = this
	// console.log(localStorage.getItem('lang'), 123)
	if (localStorage.getItem('lang') == "en-Us") {
		return 1
	} else if (localStorage.getItem('lang') == "vl-VN") {
		return 2
	} else if (localStorage.getItem('lang') == "zh-CN") {
		return 3
	} else {
		return 4
	}
}
// var nowLang = function () {
// 	return localStorage.getItem('lang') || "en-Us"
// }
/**
 * 请求类型为post时请求头的请求类型默认为{headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}}
 * 可按实际修改为{headers: {'Content-Type': 'application/json; charset=UTF-8'}}或者{headers: {'Content-Type': 'multipart/form-data; charset=UTF-8'}}  跟在传参后
 * 列如:
 *  export function postHome(data){
		return server.post('Home/BlocManage/auth',data,{headers: {'Content-Type': 'multipart/form-data; charset=UTF-8'}})
	}
 */

const server = axios.create({ //创建axios实例
	baseURL: baseURL,
	timeout: 180000,
})
let check = false
let loadingInstance = ""
var a = "v1/order.product"
var nowLang = function () {
	var b = localStorage.getItem('lang')
	if (b == "en-Us") {
		return "en-Us"
	} else if (b == "zh-CN") {
		return "zh-cn"
	} else if (b == "vl-VN") {
		return "vi"
	} else if (b == "ma-LN") {
		return "ma-LN"
	}
}
// var str = a.replace(".", "");
// console.log(str)
// 添加请求拦截器
server.interceptors.request.use(function (config) {
	check = false;
	// config.data
	// console.log(config.data.get('lang'), "configconfig")
	if (config.method == 'get') {
		// var searchParams = config.params
		// var c = searchParams.get("lang")
		//config.params.set('lang', nowLang())
	} else if (config.method == 'post') {
		//config.data.set('lang', nowLang())
	}

	// return
	if (config.url == "v1/pay/checkOTP" || config.url == "v1/pay/offlineOrderQuery" || config.url ==
		a) {
		// let loadingInstance = Loading.service();
		// loadingInstance.close();
		// console.log("66666")
		check = true
	} else if (config.url == "v1/pay/offlineOrderQuery") {

	} else {
		// Loading.service();
		loadingInstance = Loading.service();
	}
	// 在发送请求之前做些什么
	// console.log(1,config);
	//    config.headers.token = 'zqqq7b569b6b1660fa23162567d0c35ad51a'   //一般是判断是否存在token后再决定头部是否添加token
	// config.headers['Authorization'] = 'zqq7b569b6b1660fa23162567d0c35ad51a'
	return config;
}, function (error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

let dangerouslyUseHTMLString = false;
server.interceptors.response.use(function (response) {
	// 对响应数据做点什么
	//console.log(2, response.data.msg);

	// 服务端返回HTML时
	if (response.data?.msg && /^<\w.*>/i.test(response.data.msg)) {
		dangerouslyUseHTMLString = true;
	} else {
		dangerouslyUseHTMLString = false;
	}

	// 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
	// 否则的话抛出错误
	if (response.status === 200) {
		if (loadingInstance == "") {

		} else {
			loadingInstance.close();
		}

		// });
		if (response.data.code != 0) {
			if (response.data.msg) {
				if (check) {

				} else {
					Message.error({
						message: response.data.msg,
						dangerouslyUseHTMLString: dangerouslyUseHTMLString,
						offset: 180
					});
				}

			} else {
				if (getLang() == "1") {
					if (check) {

					} else {
						Message.error({
							message: messages.en.message.hello,
							dangerouslyUseHTMLString: dangerouslyUseHTMLString,
							offset: 180
						});
					}

				} else if (getLang() == "2") {
					if (check) {

					} else {
						Message.error({
							message: messages.vn.message.hello,
							dangerouslyUseHTMLString: dangerouslyUseHTMLString,
							offset: 180
						});
					}
				} else if (getLang() == "3") {
					Message.error({
						message: messages.cn.message.hello,
						dangerouslyUseHTMLString: dangerouslyUseHTMLString,
						offset: 180
					});
				} else {
					Message.error({
						message: messages.cn.message.hello,
						dangerouslyUseHTMLString: dangerouslyUseHTMLString,
						offset: 180
					});
				}

			}

		} else {
			// Message.success({
			// 	message: response.data.msg,
			// 	offset: 180
			// });
		}
		return Promise.resolve(response);
	} else {
		// this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
		if (loadingInstance == "") {

		} else {
			loadingInstance.close();
		}
		// });
		return Promise.reject(response);
	}

	// return response;
}, function (error) {
	console.error(error);
	// this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
	if (loadingInstance == "") {

	} else {
		loadingInstance.close();
	}
	// });
	// 对响应错误做点什么
	// 服务器状态码不是2开头的的情况
	// 这里可以跟你们的后台开发人员协商好统一的错误状态码    
	// 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
	// 下面列举几个常见的操作，其他需求可自行扩展
	if (error.response?.status) {
		switch (error.response.status) {
			// 401: 未登录
			// 未登录则跳转登录页面，并携带当前页面的路径
			// 在登录成功后返回当前页面，这一步需要在登录页操作。                
			case 401:
				// router.replace({                        
				//     path: '/login',                        
				//     query: { 
				//         redirect: router.currentRoute.fullPath 
				//     }
				// });
				break;

			// 403 token过期
			// 登录过期对用户进行提示
			// 清除本地token和清空vuex中token对象
			// 跳转登录页面                
			case 403:
				// Message.error('登录过期，请重新登录');
				// 清除token
				localStorage.removeItem('token');
				// store.commit('loginSuccess', null);
				// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
				setTimeout(() => {
					// router.replace({                            
					//     path: '/login',                            
					//     query: { 
					//         redirect: router.currentRoute.fullPath 
					//     }                        
					// });                    
				}, 1000);
				break;

			// 404请求不存在
			case 404:
				// Message.error('网络请求不存在');
				Message.error(error.response.data.message);
				break;
			// 其他错误，直接抛出错误提示
			default:
				Message.error(error.response.data.message);
		}
		return Promise.reject(error.response);
	} else {
		Message.error({
			message: error.message,
			dangerouslyUseHTMLString: dangerouslyUseHTMLString,
			offset: 180
		});
	}
	// return Promise.reject(error);
});


export default server
