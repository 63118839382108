export const m = {
	// 2.首页
	Homepage: 'Home page',
	// 4.开发者中心
	Product: 'Product',
	// 4.开发者中心
	Developer: 'Developer Center',
	// 5.业务登录
	Login: 'Business Log in',
	// 6.商家注册
	Register: 'Merchant Sign in',
	registration_number:"Enter Answer for the challenge question.",
	Confirm:"Confirm",
	// 7.RGPay聚合支付/Payment
	title: 'RGPay Payment',
	// 8.让支付接口对接前所未有的简单/
	slogan: 'Make payment easier than ever.',
	explain: 'Back-end development is not required. One SDK can access a complete payment system, integrate mainstream payment interfaces at a high speed, and direct payments with a more stable interface and faster speed.',
	one: 'Stable for a long time',
	// 快速高效
	two: 'Fast and efficient',
	// 12.安全保障
	three: 'Security',
	// 多机房异地容灾系统，服务端可用性99.9%， 专业运维团队值守
	room: 'Multi-computer room remote disaster recovery system, server availability 99.9%, professional operation and maintenance team on duty.',
	time: 'Super fast response in 10 minutes, 1V1 professional customer service, 7/24 hours technical support.',
	financial: 'Financial-grade security protection standards, strong resistance. Guard against external intrusions and support high concurrent transactions.',
	major: 'The most professional support place.',
	APP: 'APP',
	H5: 'Mobile page',
	web: 'PC page',
	offline: 'QR code scan',
	Service: 'Product and Service',
	docking: 'Financial link',
	system: 'Similar order system, make clear the company’s income and expenditure quickly.',
	ss: 'Sub-merchant system.',
	addss: 'Add sub-merchant accounts to realize the split account function for transactions.',
	Interface: 'Interface application',
	cover: 'Full support all payment case, support to be a mainstream payment.',
	qr: 'Scan QR code to pay.',
	gathering: 'A professional payment collection tool, essential for offline business operations.',
	customization: 'Customized payment solutions.',
	scene: 'Support transaction methods for different business scenarios.',
	free: 'Free online one-to-one analysis of payment scenarios, sorting out corporate collection needs, making suggestions for access, and customizing payment solutions.',
	flow: 'Consultation for details',
	individuation: 'Customized analysis',
	follow: 'Follow up customized business requirements throughout the process, deploy enterprise local servers, and control data security.',
	team: 'Providing a 7/10h service in all progress from interface joint debugging, testing and launching to later system operation and maintenance, management platform, and using.',
	payment: 'Link to multiple payment interface.',
	high: 'Connect with many of the best payment interfaces in the industry to ensure smooth business. Make payment interface development easier and more convenient',
	open: 'Start a new era of payment now.',
	facilitator: 'RGPay payment, payment technology service provider, make payment simple, professional and fast.',
	// 现在加入
	join: 'Join now',
	// 43.我要接入
	wantTo: 'I want to access',
	// 44.我们的伙伴
	OurPartners: 'Our partners',
	// 47.联系方式
	method: 'Contact method',
	// 48.产品项目
	chanpinXiangmu: 'Product',
	// 49.聚合收款
	receipts: 'Aggregate receipts',
	// 50.子商户系统
	Merchant: 'Merchant System',
	// 51.代付系统
	PaymentSystem: 'Payment system',
	// 52.关于公司
	AboutCompany: 'About company',
	// 53.关于我们
	AboutUs: 'About us',
	// 54.接口合作
	Cooperation: 'Cooperation',
	// 55.流量合作
	FlowCooperation: 'Flow cooperation',
	// 56.开发者
	Deverloperzq: 'Deverloper',
	// 57.DEMO体验
	DemoTrial: 'Demo trial',
	// 58.API开发文档
	APIDevelopmentDocument: 'API Development Document',
	// 59.SDK下载
	SDKdownload: 'SDK download',
	// 60.扫一扫
	ScanCode: 'Scan code',
	// 61.Copyright © 2021 中南汇金 All rights reserved. 版权所有
	Copyright: 'Copyright © 2023 RGPay All rights reserved.',
	// ------------------------------------------------------------------------------
	// 产品中心
	chanpin1: 'Products',
	// 6.聚合支付SDK
	zhifuSDK2: 'SDK payment',
	// 无需重复集成繁琐
	fansuo3: "Developers do not need to repeatedly integrate cumbersome payment interfaces, and use RGPay's aggregation SDK to easily access all payment methods, respond to various payment scenarios, and obtain a high-performance, safe and reliable payment infrastructure services, so that payment interfaces can generate value.",
	// 8.立即接入
	ljjieru4: "Connect now",
	// 16.一码收款
	shoukuan5: "Aggregate receipts",
	// 手机APP支付
	shoujiapp6: "Mobile APP Payment",
	// iOS/Android原生H5 App
	leibie7: "RGPay provides a full set of payment solutions for iOS/Android native/H5 such as: ",
	// 19.手机APP支付
	shouji8: "Mobile APP Payment",
	// 20.移动端网页
	gongzhong9: "Mobile web payment",
	// PC网页支付
	wangye10: "PC website payment",
	wangyezhifu: "Web payment",
	// 22.线下扫码支付
	xianxia11: "Offline payment",
	// 23.子商户系统
	zixitong12: "Merchant System",
	// 商户系统
	shanghu13: "The Merchant System of RGPay is geared towards platform, distribution, supply chain management and other types of merchants. A payment products that support multi-level merchant management can realize sub-management of agents, distributors, branches, suppliers and other roles through the merchant system. Combine with aggregate receipt products to realize free and flexible transaction splitting between master and merchants. Combine with fund depository products to realize the clearing and settlement of main merchants.",
	// 25.商户管理
	shnaghu14: "Merchant management",
	// 26.交易查询
	jiaoyi15: "Transaction checking",
	// 27.财务对账
	caiwu16: "Financial audit",
	// 28.分润管理
	fenrun17: "Profit management",
	// 29.资金安全
	zijin18: "Cash sercurity",
	// 完善的商户体系
	shanghu19: "Provide a complete merchant system, facilitate the organization of merchant structures, flexibly establish merchant relationships in complex scenarios, and conveniently manage merchant levels and basic information.",
	// 31.了解更多
	liaojie20: "More information",
	// 代付系统
	daifu21: "Payment system",
	// 接入所有主流
	zhuliu22: "Access to all mainstream payment interfaces at one time, 99.99% system availability, to meet your complex transaction requirements, and provide your users with a perfect payment experience.",
	// 1.详细记录每一条交易信息
	jiaoyi23: "Detail each transaction information",
	// 全方位、多方面
	fangwei24: "Comprehensive and multi-faceted transaction data summary statistics",
	// 深度分析
	fenxi25: "Analysis the revenue and information value of each transaction",
	// 精细化的财务展示
	caiwu26: "Refined financial display and efficient account checking",
	// 5.清晰的数据逻辑和准确的财务数据
	shuju27: "Dữ liệu logic rõ ràng và dữ liệu tài chính chính xác",
	// 松实现内部对账
	duixhang28: "Easily implement internal reconciliation, merchant reconciliation and interface reconciliation, reducing financial costs.",
	// 10.接口放资金直清
	jiekou29: "Direct clearing of funds through the interface",
	// 11. 提供支付接口对接服务
	fuwu30: "RGPay provides payment interface services",
	// 资金由接口方进行清算
	qingsuan31: "Funds are cleared by the interface party, and RGPay provides payment interface technical services, which are safe and assured.",
	// 32.代付系统
	daifu32: "Payment system",
	// 入所有主流支付接口
	ruliu33: "Access to all mainstream payment interfaces at one time, 99.99% system availability, to meet your complex transaction requirements, and provide your users with a perfect payment experience.",
	// 一个 API，在任何平台和场景
	changjign34: "An API to access payment functions on any platform and cases. It supports ",
	// 立即联系
	liji35: "Contact now",
	// 36.二维码支付
	zhifu36: "QR code payment",
	// 38.扫二维码支付
	sao37: "Scan QR code to pay",
	// 线下商户二维码台卡
	xinxia38: "Merchants' QR code cards are convenient for receiving payments, the daily operations of merchants are clear at a glance, from receiving payments, checking the flow, checking bills, etc., closely connecting merchants and consumers.",
	// 44.立即开户
	kauhu39: "Open Account",
	// 45.联系我们
	lianxi40: "Contact us",
	// 联系方式
	lianxifangshi41: "Contact method",
	// 47.产品项目
	chanpin42: "Product",
	// 48.聚合收款
	juhe43: "Aggregate receipts",
	// 49.子商户系统
	zishanghu44: "Merchant System",
	// 50.代付系统
	dsifu45: "Payment system",
	// 52.关于我们
	guanyu46: "About us",
	// 接口合作
	jiekou47: "Cooperation",
	// 51.关于公司
	gognsi48: "About company",
	// 54.流量合作
	liuliang49: "Flow cooperation",
	// 开发者
	kaifa50: "Deverloper",
	// 56.DEMO体验
	demo51: "Demo trial",
	// 57.API开发文档
	kaifa52: "API Development Document",
	// 58.SDK下载
	xiazai53: "SDK download",
	// 扫一扫
	sao54: "Scan code",
	// 版权所有
	banquan55: " © 2023 RGPay All rights reserved.",
	// 33.详情咨询
	zixin56: "Consultation for details",
	// 40.现在加入
	jiaru57: "Join now",
	// 当前语言
	language: "EN",
	// 菜单-------------------------------------------
	// 2.首页
	shouye58: "Home page",
	// 产品中心/
	shanpin59: "Product",
	// 开发者中心
	kaifa60: "Developer Center",
	// 5.业务登录
	yewu61: "Business Log in",
	// 6.商家注册
	zhuce62: "Merchant registration",
	// 商户注册----------------------------------------
	// 1.商户注册
	// shnahghu63: "Merchant sign in",
	shnahghu63: " Merchant registration",
	// 2.请输入账号
	shuru64: "Please enter your account.",
	// 3.请输入密码
	mima65: "Please enter your password",
	// 4.请再次输入密码
	again66: "Please enter your password again",
	// 5.请输入您的Email地址
	email67: "Please enter your email address",
	// 6.注册
	zhuce68: "Sign in",
	// 7.已有账号？点这里-->登录
	you69: "Already have an account? Click here -> ",
	// 登录
	denlu70: "Login",
	// 业务登录页面---------------------------------------------------------------------
	// 2.商户登录
	shanghu71: "Merchant Login",
	// 3.请输入账号
	shuru72: "Please enter your account.",
	// 4.请输入密码
	mima73: "Please enter your password",
	// 7.代理登录
	daili74: "Agent login",
	// 11.还没有账号？立即注册
	meiyou75: "Don't have an account yet?",
	// 注册
	zhuce76: "Sign up.",
	//开发者中心---------------------------------
	// 1.开发者中心
	kaifa77: "Developer Center",
	// DEMO体验
	demo78: "DEMO trial",
	// 商品名称
	shangpin79: "Product name",
	// 4.测试订单
	ceshi80: "Trial Order",
	// 5.订单编号
	dingdan81: "Order No",
	// 6.订单金额
	jine82: "Order amount",
	// 支付方式
	zhifu83: "Payment method",
	// 8.支付宝扫码
	saoma84: "Alipay",
	// 9.微信扫码
	weixin85: "Wechat Pay",
	// 10.微信h5
	h586: "Wechat h5",
	// 11.QQ钱包扫码
	qianbao87: "QQ wallet",
	// 12.百度钱包
	baidu88: "Baidu wallet",
	// 13.银联快键
	yinlian89: "Union Pay",
	// 14.在线网银
	zaixian90: "Online banking",
	// 15.需要支付
	xuyao91: "Need to pay",
	// 16.立即支付
	liji92: "Pay now",
	// 17.Copyright © 2021 聚合支付 版权所有
	// 18.API开发文档
	kaifa93: "Document development API",
	// 19.API开发文档简介
	wendang94: "Introduce document development API",
	// 20.接入网关
	jieru95: "Access Gateway",
	// 21.签名算法
	xianming96: "Signature Algorithm",
	// 22.商户统一下单API
	shanghu97: "Merchants unified order API",
	// 23.异步通知
	// 61.单位：元
	yuan98: "RM",
	// 支付体验收银台
	tiyan99: "Payment experience cashier",
	// 253.SDK下载
	xiazai100: "SDK download",
	// 越南语
	// yuenan101:""
	// 关于我们-------------------------------------------------------------------------------
	// 1.关于我们
	women102: "About us",
	// 联系我们
	lianxi103: "Contact us",
	// 4.企业QQ
	qiyeqq104: "Company’s QQ",
	// 5.客服QQ
	kefuqq105: "QQ customer service",
	// 6.咨询热线
	zixun106: "Consult hotline",
	// 7.联系邮箱
	lainxi107: "Email:",
	// 加我领红包
	jiawo108: "Add us to get red packet",
	// 8.公司地址：广州海珠区新港东路1888号中洲交易中心
	adress109: "Address",
	// 支付公司合作
	hezuo110: "Payment companies Cooperated",
	// 11.支付公司合作敬请联系客服人员
	kefu111: "Please contact customer service staff for payment companies cooperated",
	// 国内领先的支付技术解决方案...
	wenan112: "RGPay is a wonderful payment gateway to support your financial transfers and various banking needs. Our service is an excellent option for individuals or e-businesses looking for a reliable and feature-rich payment gateway. You receive financial management, data facilities and end-to-end payments, everything at one wonderful place, offering both local and international payment options.",
	// 13.立即联系
	lianxi113: "Contact now",
	// 流量合作
	liuliang114: "Potential partners",
	// 15.支付流量合作敬请联系客服人员
	kefu115: "Please contact customer service staff for potential partners",
	// 提供支付接口代申请服务
	fuwu116: "RGPay offer various payment options, such as credit cards, debit cards, and online banking with secure and have robust fraud prevention measures.",
	// 17.联系客服
	kefu117: "Contact customer service",
	// ----------------------------------------------
	// 用户登录和注册语言提示
	// 1.请输入用户名
	// userName118:"Xin nhập tên người dùng",
	userName118: "Please enter user name",
	// 2. 请输入密码
	// password119:"Xin nhập mật khẩu",
	password119: "Please enter password",
	// 3. 请重复输入密码
	// argin120:"Xin nhập lại mật khẩu",
	argin120: "Please enter password again",
	// 4. 请输入邮箱
	// email121:"Xin nhập email"
	email121: "Please enter email",
	// 5. 两次输入不一致
	// errorTow:"Hai lần nhập không giống nhau"
	errorTow122: "Two inputs are inconsistent",
	// 邮件格式错误，请重新输入
	// VN: Định dạng email bị lỗi, vui lòng nhập lại. 
	errorMessage123: "Email format is not correct, please enter again.",
	// ----------------------
	// 手机支付页面
	// -CN: 保存此二维码图片
	baocun124: "Save this QR code picture",
	// -CN: 充值内容
	chognzhi125: "Recharge content",
	// -CN: 复制
	copy126: "Copy",
	// -CN: 到期时间
	daoqi127: "Expire time",
	// CN: QR码只可使用一次
	one128: "QR code is only used once for this payment",
	// CN: 请填写充值内容：P2987S（此外，请勿写其他任何东西，以免Zalo Pay钱包出现延误
	qr129: "Please write top up content: P2987S (in addition, do not write anything else to avoid delay in RGPay wallet)",
	// ------------------------------------------
	// 开发者文档页面
	// API开发文档简介
	wandang130: "Introduce document development API",
	// 本文阅读对象：
	duixiang131: "This article is read for: technical architects, R&D engineers, and system operation and maintenance engineers who use RGPay's merchant self-service system. Through this document, merchants can learn about RGPay's access technology, products and services, access procedures, access specifications and other information, so that merchants can successfully complete the access work.",
	// 接入网关
	wanggaun132: "Access Gateway",
	// 请登录商户中心...
	shanghu133: "Please log in to the merchant center and check the gateway address and API connection parameters in the API management development document.",
	// 204.签名算法
	shuanfa134: "Signature Algorithm",
	// 205.签名生成的通用步骤如下：
	buzou135: "The general steps for signature generation are as follows:",
	// 第一步，设所有发.....
	first136: "Step 1, set all the sent or received data as set M, sort the parameters of non-empty parameter values in set M according to the ASCII code of the parameter name from small to large (dictionary order), and use the format of URL key-value pairs (ie key1 =value1&key2=value2...) is concatenated into a string.",
	// 第二步，在stringA...
	two138: "Step 2, is to apply the key to the last splicing of stringA to obtain the stringSignTemp character string, and perform MD5 operation on stringSignTemp, and then convert all the characters of the obtained character string to uppercase to obtain the sign value signValue.",
	// 209.提交方式：POST
	post139: 'Submit way: POST',
	// 35.地址： 接入网关/Pay_Index.html
	wangguan140: "Address: access gateway/Pay_Index.html",
	// 63.异步通知
	yubu141: "Asynchronous Notification",
	// 接收到服务器点对点通讯时
	tongxun142: "When receiving the server peer-to-peer communication, output 'OK' on the page (no double quotation marks, OK two letters are capitalized), otherwise the peer-to-peer notification will be sent repeatedly 5 times.",
	// 85.订单查询接口
	chaxun143: "Checking order gateway",
	// 87.地址： 接入网关/Pay_Trade_query.html
	dizhi144: "Address: Access gateway/ Pay_Trade_query.html",
	// 账户余额查询接口
	zhuanghu145: "Balance checking interface",
	// 210.接口地址：网关地址+/Payment_Dfpay_balance.html
	dizhi146: "Interface address: gateway address +/Payment_Dfpay_balance.html",
	// 238.渠道编码
	qudao147: "Channel Code",
	// 32.请登录商户中心，API管理 开发文档中 查看网关地址和API对接参数。
	canshu148: "Please log in to the merchant center and check the gateway address and API connection parameters in the API management development document.",
	// 订单支付接口
	dingdan149: "Payment gate",
	// 252.API开发文档
	wendang150: "API Development Document",
	// 注册成功,请等待管理员审核.
	shenhe151: "Registration is successful, please wait for the administrator to approve.",
	// 服务器内部错误
	error152: "Server internal error",
	// 此页面无法访问
	wufa153: "This page cannot be accessed",
	// ----------------------------------------------------------
	// 支付相关页面
	// -剩余时间：544秒
	timeOut154: "Time remaining: 544 seconds",
	// -请输入验证码已经发短信到您的手机或者用银行应用登录名：NONE
	shuru155: "Please enter OTP in your message or banking app with RefID: NONE",
	// -请注意，OTP执行时间仅为60秒
	miao156: "Please note that OTP execution time is only 60 seconds",
	// -输入OTP验证码
	yanzheng157: "Enter OTP code",
	// -下一步
	xiayibu158: "Next",
	// Lưu ý 备注  Note
	beizhu159: "Each QR code or deposit information can only be used once, if used again there is a possibility of losing the transaction , it takes 1 week to process. Please follow the Deposit instructions to avoid errors. Thank you. ",
	// 1.交易编号
	bianhao160: "Transaction code",
	// 交易时间
	shijian161: "Transaction time",
	// 金额
	jine162: "Amount",
	// 结果
	jieguo163: "Result",
	// 没有输入信息
	xinxi164: "No account input",
	// Thời gian时间
	shijian165: "time",
	// 交易代码
	daima166: "trasaction code",
	// -请等待您的交易被处理
	wait167: "Please wait for your transaction to be processed",
	// -充值金额
	jine168: "Recharge amount:",
	// -充值编号
	bianhao169: "Transaction code:",
	// -交易成功时间
	shijian170: "Transaction completed time:",
	// -检查交易状态
	zhuangtai171: "Check transaction status.",
	// 支付成功
	success172: "Deposit successfully",
	// 支付失败
	shibai173: "Payment failed",
	// 银行
	bank174: "bank",
	// 支付类型
	leixing175: "Payment Types",

	////////////////////////////////////////////////////////////////////////////////////////////
	// 文档中心新修改文档翻译 不支持英文
	/////////////
	// 目录
	// 1.接口规范
	jiekou176: "Interface Specification",
	// 2. 签名规范
	jianming177: "Signature specification",
	// 3. 请求规范
	qingqiu178: "Request specifications",
	// 4. 服务器接口列表
	jiekou179: "Server interface list",
	// 5. 创建支付订单
	chuangjian180: "Create Payment",
	// 6. 查询支付订单
	chaxun181: "Check payment",
	// 7. 支付结果异步通知
	zhifu182: "Asynchronous notification of payment results",
	// 8. 关闭订单
	guanbi183: "Close the payment",
	// 9. 获取支付渠道列表
	gongong184: "Get a list of payment channels",
	// 10.公共返回码
	fulu185: "Public return codes",
	// 11.附录
	add11fulu: "Appendix",
	// 目录结束 文档开始
	// 商户接入系统时，调用API 必须遵守以下规则
	// 1.接口规范
	guizhe186: "When merchants access the system, using API must comply with the following rules：",
	// 2. 签名规范
	jianming187: "Step 1: Sort the data to be signed according to the ASCII code of the parameter name from smallest to largest (follow dictionary system); then use & concatenate in the form of key-value pairs (key=value) to form a whole string (such as: A=123&B=456) as StringA ；",
	// 第二步
	er188: "Step 2: At the end of StringA, add the corresponding key (such as: A=123&B=456&key=123456) to get StringB; ",
	// 第三步
	san189: "Step 3: Perform MD5 calculation on StringB, then convert the resulting string to uppercase, and the value obtained is the sign value. ",
	// 举例：
	juli190: "For example：",
	// 现有以下数据需要签名：
	xianyou191: "The following data needs to be signed: ",
	// username：admin password：123456 timestamp：1234567890 nonceStr：e134b0c1d94
	// 签名 secret_key:**************** (请向技术人员索取) 
	username192: "username：admin ",
	password193: "password：123456 ",
	timestamp194: "timestamp：1234567890",
	nonceStr195: "nonceStr：e134b0c1d94",
	secret_key196: "Signature secret_key:**************** (please ask for it from the technician) ",
	// 第一步：排序并拼接字符串
	pinjie197: "Step 1：Sort and concatenate strings",
	juli198: "StringA =”password=123456&timestamp=1234567890&nonceStr=e134b0c1d94&username=admin”",
	// 第二步：最后拼接上 key 字段
	ziduan199: "Step 2：Finally, splicing the key field",
	pinjie200: "StringB = StringA + ”&key=****************”",
	// 第三步：MD5 计算，并转为大写
	md5201: "Step 3：MD5 calculation, and converted to uppercase",
	// Sign = strtoupper( md5(StringB) ) 
	Sign202: "Sign = strtoupper( md5(StringB) ) ",
	//////////////////////////////////////////////////////////////////////////////////
	// 3.请求规范 
	guifan203: "1)、The request method is: POST, which supports three content types: form-data, x-www-form-urlencoded, raw (json);",
	guifen204: "2)、Lang/language currently supports: Vietnamese (vi), English (en-Us), Chinese (en-Us) Just add more parameters when requesting: lang=vi or lang=en-Us or lang=zh-cn.",
	/////////////////////////////////////////////////////////////////////////////////////////
	// 4.服务器接口列表 
	// 服务器 
	fuwu205: "Server",
	// 地址 
	dizhi206: "Address",
	// 接口网关
	wangguan207: "Interface gateway",
	// 版本
	banban208: "Version",
	// 请求范例
	guifan209: "Request example",
	fanli210: "1. The requested address is: interface gateway + version + interface address " + '\n',
	fanli211: "2. For example, create a payment order: https://api.rg-pay.com/v1/pay",
	//////////////////////////////////////////////////////////////////////////////////////////
	// 5.创建支付订单 
	dizhi212: "Interface address：/pay",
	// 请求参数：
	qingqiu213: "Requested parameter： ",
	// 表格
	// 表头标题--参数 /参数名/必须/描述
	canshu214: "Parameter ",
	canshuming215: "parameter name",
	bixu216: "Must be ",
	miaoshu217: "Description",
	// 第二列商户id/支付订单号/支付时间/支付通道ID/异步回调地址/页面跳转地址/支付金额/当前UNIX时间戳/随机串/商品描述/支付类型/内容类型/签名
	// 1-7
	lie218: "Merchant ID",
	lie219: "Payment order number",
	lie220: "Payment time",
	lie221: "Payment channel ID",
	lie222: "Asynchronous callback address",
	lie223: "Page jump address",
	lie224: "Please ask the technician",
	// 8-13
	lie225: "Current Vietnamese timestamp in seconds",
	lie226: "Random string",
	lie227: "For details, please check the appendix information at the end of the document",
	lie228: "After the payment is submitted, the payment status data will be asynchronously POSTed to the address",
	lie229: "Content type",
	lie230: "Signature",
	// 第三列   是/否
	yes231: "Yes",
	no232: "No",
	// 第四列
	// 1-7
	// 请向技术人员索取
	suoqu233: "Please ask the technician",
	// 如：20210602101815555456
	ru234: "For example: 20210602101815555456",
	// 如：2021-05-31 10:56:22
	ru235: "For example: 2021-05-31 10:56:22",
	// 具体请查看文档最后的附录信息
	juti236: "For details, please check the appendix information at the end of the document",
	// 支付提交后会把支付状态数据异步POST数据到该地址
	zhifu237: "After the payment is submitted, the payment status data will be asynchronously POSTed to the address",
	// 当有传递该值时，支付提交后会跳转回该地址（注：状态数据会拼到该地址的URI部分里）
	chuandi238: "When the value is passed, the payment will be redirected back to the address after submission (Note: the status data will be spelled into the URI part of the address)",
	// 越南盾(RM)，单位：元
	dun239: "Vietnamese Dong (RM), unit: Dong",
	// 8-13
	// 以秒为单位的时间戳，如：1622429782
	danweimiao240: "Current Vietnamese timestamp in seconds, ex: 1624229782",
	// 随机字符串，长度为10-32位字符，如：nkkMTAkLkR4U
	zifuchuan241: "Random string, 10-32 characters in length, ex: nkkMTAkLkR4U",
	// 商品描述，如：充值
	chognzhi242: "Product description, such as: recharge",
	// 传递1为网页支付、传递2为扫码支付
	cuandi243: "Pass 1 is web payment, pass 2 is scan code payment",
	// 异步回调返回数据内容类型，可选值：1、multipart/form-data（默认）2、application/x-www-form-urlencoded3、text/plain
	huidiao244: "Asynchronous callback returns the data content type, optional values:  " + '\n' +
		"  1, multipart/form-data (default) " + '\n' + "  2. application/x-www-form-urlencoded " + '\n' +
		"  3. application/json or text/plain (both json)",
	// 签名生成算法请查看签名规范（注：除了该参数不参与签名外，其它参数均参与签名）
	qianming245: "For the signature generation algorithm, please check the signature specification (note: except this parameter does not participate in the signature, other parameters are involved in the signature)",

	// 返回参数：
	fenhui246: "Return parameter：  ",
	// 返回码 /返回消息 /返回数据 
	fanhuima247: "Return code",
	fanhuixiaoxi248: "Return message",
	fanhuishuju249: "Return data",
	// 1-5
	// 0代表成功，1代表失败，其它请看公共返回码
	daibiao250: "0 means success, 1 means failure, please see the public return code for others",
	// 返回消息，如：success 
	success251: "Return message, ex: success",
	// 成功时必定返回以下数据 
	successdata252: "The following data must be returned when successful",
	// 带有订单ID的支付页面网址，商户拿到该地址后直接跳转即可。
	dingdan253: "The payment page URL with the order ID can be redirected directly after the merchant obtains the address.",
	// 平台支付订单号，如：20210602101815555456
	pingtai254: "Platform payment order number, ex: 20210602101815555456",
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 6.查询支付订单 
	// 当付完款之后，返回支付错误或支付中时，建议每间隔 3 秒调用本接口来获取当前订单最新的支付状态，直至支........
	fukuan255: " After the payment is completed, when the payment error or payment is returned, it is recommended to call this interface every 3 seconds to obtain the latest payment status of the current order, until the payment is successful, it will prompt that the payment is successful; or actively close the order.",
	// 接口地址：/pay/query 
	jikeou256: "Interface address：/pay/query ",
	// 	// 目录6表格1-请求参数： 
	mulu6: {
		// 参数名 (商户号 /支付订单号/随机串 /时间戳 /签名 )
		canshuming: {
			mulu6shanghu257: "Business number",
			mulu6dingdanhao258: "Payment order number",
			mulu6sunjicuan259: "Random string",
			mulu6shijiancuo260: "Timestamp",
			mulu6qianming261: "signature"
		},
		// 描述 ()
		miaoshu: {
			suoqu262: "Please ask the technician",
			li263: "For example: 20210602101815555456",
			zifucuan264: "Random string, 10-32 characters in length, ex: nkkMTAkLkR4U",
			shijiancuo265: "Current Vietnamese timestamp in seconds, ex: 1624229782",
			suanfa267: "For the signature generation algorithm, please check the signature specification (note: except this parameter does not participate in the signature, other parameters are involved in the signature)"
		}
	},
	// 目录6表格2-返回参数：
	mulu6_2: {
		// 表格名--返回参数
		fanhuicanshu268: "Return parameter： ",
		canshufanhui: {
			column1: {},
			column2: {
				data1: "Return code",
				data2: "Return message",
				data3: "Return data",
				data4: "business number",
				data5: "order number ",
				data6: "Payment amount",
				data7: "Payment success/failure time",
				data8: "Platform order number",
				data9: "Payment Types",
				data10: "Request status",
				data11: "Payment status ",
				data12: "Random string",
				data13: "Timestamp",
				data14: "signature",
			},
			column3: {},
			column4: {
				data1: "0 means success, 1 means failure, please see public return code for others",
				data2: "Return message, such as: success",
				data3: "The following data must be returned when successful",
				data4: "Please ask the technician",
				data5: "Merchant order number",
				data6: "Vietnamese Dong (RM), unit: yuan",
				data7: "For example: 2021-05-31 10:56:22",
				data8: "For example: 20210602101815555456",
				data9: "1 is web payment, 2 is scan code payment",
				data10: "00 means success, others means failure",
				data11: "NORGPay-unpaid  / NORGPay-unpaid   / USERPAYING-Paying  / REFUND-transferred refund  / REVOKED-withdrawn  / CLOSED-closed / PAYERROR-payment error",
				data12: "Random string, 10-32 characters in length, ex: nkkMTAkLkR4U",
				data13: "Current Vietnamese timestamp in seconds, ex: 1624229782",
				data14: "For the signature generation algorithm, please check the signature specification (note: except this parameter does not participate in the signature, other parameters are involved in the signature)",
			}
		}
	},
	///////////////////////////////////////////////////////////////////
	// 目录7.支付结果异步通知
	inform7_269: {
		title1: "If the pay_notifyurl parameter is selected in the order interface,When the customer pays successfully, the platform server will push the payment result data to this address, and the merchant needs to receive and process it. When interacting with background notifications, if the platform receives the merchant’s response that is not a pure string SUCCESS or returns after more than 5 seconds, the platform considers that the notification has failed, and the platform will pass a certain strategy (the notification frequency is 0/5/10/15/20 /25/30...86400, unit: second) Indirectly re-initiate the notification to increase the success rate of the notification as much as possible, but there is no guarantee that the notification will be successful in the end. If you cannot receive the notification for a long time, it is recommended to call the inquiry order actively. ",
		title2: "Due to the situation of re-sending background notifications, the same notification may be sent to the merchant system multiple times. The merchant system must be able to correctly handle duplicate notifications. The recommended approach is to first check the status of the corresponding business data when receiving a notification for processing, to determine whether the notification has been processed, if it has not been processed, then process it, and if it is processed, return the result directly. Before the status check and processing of business data, data locks should be used for concurrency control to avoid data confusion caused by function reentry.  ",
		title3: "Special attention: After receiving the notification parameters, the merchant must verify the order ID and the amount in the received notification parameters with the order and amount of the business system, and update the database order status only after the verification is consistent. And be sure to verify the returned signature to ensure the accuracy of the message.  ",
		// 通知参数
		tongzhi4: "Notification parameters：",
		column2: {
			val1: "Business number",
			val2: "Order number",
			val3: "Payment amount",
			val4: "Payment success/failure time",
			val5: "Platform order number",
			val6: "Payment Types",
			val7: "Request status",
			val8: "Payment status ",
			val9: "Random string",
			val10: "Timestamp",
			val11: "Signature",
		},
		column4: {
			val1: "Please ask the technician",
			val2: "Merchant order number",
			val3: "Vietnamese Dong (RM), unit: Dong",
			val4: "For example: 2021-05-31 10:56:22",
			val5: "For example:: 20210602101815555456",
			val6: "1 is web payment, 2 is scan code payment",
			val7: "00 means success, others means failure",
			val8: "NORGPay-unpaid / SUCCESS-paid / USERPAYING-Paying / USERPAYING-Paying / REVOKED-revoked closed / CLOSED-Closed over timeout / PAYERROR-payment error",
			val9: "Random string, 10-32 characters in length, ex: nkkMTAkLkR4",
			val10: "Current Vietnamese timestamp in seconds, ex: 1624229782",
			val11: "For the signature generation algorithm, please check the signature specification (note: except this parameter does not participate in the signature, other parameters are involved in the signature)",
		},
		title4: "It will be pushed to the pay_notifyurl address with a POST request according to the data format passed in the content_type field when the order is placedIt will be pushed to the pay_notifyurl address with a POST request according to the data format passed in the content_type field when the order is placed"
	},
	////////////////////////////////////////////
	// 目录8.关闭订单 
	CloseTheOrder8_270: {
		title1: "When the waiting time is too long, the customer can actively close the order to avoid overcharging the customer. ",
		title2: "Interface address：/pay/close ",
		title3: "Request parameter：",
		table: {
			column2: {
				val1: "business number ",
				val2: "Payment order number",
				val3: "Random string",
				val4: "Timestamp",
				val5: "signature"
			},
			column4: {
				val1: "Please ask the technician",
				val2: "For example: 20210602101815555456",
				val3: "Random string, 10-32 characters in length, ex: nkkMTAkLkR4U",
				val4: "Current Vietnamese timestamp in seconds, ex: 1624229782",
				val5: "For the signature generation algorithm, please check the signature specification (note: except this parameter does not participate in the signature, other parameters are involved in the signature)",
			}
		},
		title4: "Return parameter： ",
		tableTwo: {
			column2: {
				val1: "Return code",
				val2: "Return message",
			},
			column4: {
				val1: "0 means success, 1 means failure, please see the public return code for others",
				val2: "Return message"
			}
		}
	},

	// 目录9.获取支付渠道列表
	common_9: {
		title1: "Interface address：/pay/product",
		title2: "Return parameter：",
		table1: {
			column2: {
				val1: "business number",
				val2: "Random string",
				val3: "Timestamp",
				val4: "signature ",
			},
			column4: {
				val1: "Please ask the technician",
				val2: "Random string, 10-32 characters in length, ex: nkkMTAkLkR4U",
				val3: "Current Vietnamese timestamp in seconds, ex: 1624229782",
				val4: "For the signature generation algorithm, please check the signature specification (note: except this parameter does not participate in the signature, other parameters are involved in the signature)",
			}
		},
		table2: {
			column2: {
				val1: "Return code",
				val2: "Return message",
				val3: "Return data",
				val4: "Total number of rows",
				val5: "List data",
				val6: "Channel ID",
				val7: "Channel name",
				val8: "Channel LOGO",
				val9: "payment method",
				val10: "Lower-level coding",
			},
			column4: {
				val1: "0 means success, 1 means failure, please see the public return code for others",
				val2: "Return message",
				val3: "The following data must be returned when successful",
				val4: "Return the total number of rows of data",
				val5: "The returned list data has the following fields:",
				val6: "Channel ID, Ex: 926",
				val7: "Channel name, ex: Vietcombank",
				val8: "Channel LOGO URL",
				val9: "The current payment methods supported by the bank, 1 is web payment,2 is scan code payment, multiples are separated by English commas,such as: 1, 2",
				val10: "Bank subordinate code, if none, return empty",
			}
		}
	},
	/////////////////////////////////////////////////////////////////
	// 目录10
	returnCode10: {
		biaptou1: "Return code",
		biaptou2: "Description",
		biaptou3: "solution",
		table1: {
			column1: {
				val1: "0",
				val2: "1",
				val3: "100-600",
			},
			column2: {
				val1: "Success",
				val2: "Fails and returns a failure message",
				val3: "Corresponds to the HTTP status code, please check for details: https://zh.wikipedia.org/wiki/HTTP%E7%8A%B6%E6%80%81%E7%A0%81",
			}
		}
	},
	/////////////////////////////////////////////////////
	// 目录11
	appendix11: {
		title1: "Payment channel：",
		// 表格标题:
		headline1: "Channel ID",
		headline2: "Channel name",
		headline3: "Remark",
		table1: {
			column1: {
				val1: "926",
				val2: "927",
				val3: "928",
				val4: "929",
				val5: "930",
				val6: "931",
				val7: "932",
				val8: "933",
				val9: "934",
				val10: "935",
				val11: "936",
				val12: "937",
				val13: "938",
				val14: "939",
				val15: "940",
				val16: "941",
				val17: "942",
				val18: "943",
			},
			column2: {
				val1: "926",
				val2: "927",
				val3: "928",
				val4: "929",
				val5: "930",
				val6: "931",
				val7: "932",
				val8: "933",
				val9: "934",
				val10: "935",
				val11: "936",
				val12: "937",
				val13: "938",
				val14: "939",
				val15: "940",
				val16: "941",
				val17: "942",
				val18: "943",
			}
		}
	},
	// 目录1-接口规格
	specification: {
		table1: {
			column1: {
				val1: "Transfer method",
				val2: "Submission method",
				val3: "Data Format ",
				val4: "Character Encoding",
				val5: "Signature algorithm",
			},
			column2: {
				val1: "Use HTTPS transmission",
				val2: "Submit using POST method",
				val3: "Both submission and return data support: form-data, x-www-form-urlencoded, raw (json) format, Note: Please make sure to use form-data format when submitting. If you use other formats, please set Content-Type value in the header to x-www-form-urlencoded or application/json.",
				val4: "Use UTF-8 character encoding",
				val5: "Using MD5, refer to 2 for details",
			}
		}
	},
	// 支付页面的翻译validateTheInput.vue
	validateTheInput: {
		// 1.请输入用户名
		userNameNotHave: "Please enter user name",
		// 2.请输入密码
		ppp: "Please enter password",
		// 3.请输入OTP校验码
		peoc: "Please enter OTP code",
		// -----------------------------------------------------
		jine: "Amount",
		// 事务处理时间
		Transaction: "Transaction time",
		// .参考代码
		Reference: "Reference code",
		// 登入
		Log: "Log in",
		// 验证码
		Verification: "Verification code",
		Result: "Result",
		Account: "Account No.",
		Password: "Password",
		// Remain: "Remain time 176 seconds",
		Remain90: "Remain time ",
		Remain91: "seconds",
		// 11-
		Remark: "Remark",
		// 提示
		rishi501: "Please enter the correct account and password. When verifying, please select OTP SMS verification and bank app for click verification. Please follow the deposit instructions to avoid errors. Thank you.",
		OTPcode: "Please enter OTP code",
		// 请输入otp
		otpEnter: "Please enter OTP in your SMS or banking application, or click on Verify:",
		// 按钮下一步
		next: "Next",
		// 请稍等。您的交易正在进行中
		transaction: "Please wait. Your transaction is in progress",
		// 检查事务状态
		Check: "Check transaction status",
		// 支付失败
		failed502: " Payment failed",
		// 请稍等。您的交易正在进行中
		waiting503: "Please wait. Your transaction is in progress",
		success504: "Successful payment",
		jieguo505: "Result",
		shibai506: "Login timed out"

	},
	transfer: {
		// 通过银行转账进行付款
		transfer: "Payment by bank transfer",
		// 网银转账
		banking: "Internet banking payment",
		// 扫码转账
		payment507: "QR Scan payment",
		// 账号
		zhanghao508: "Account number",
		// 账户名字,
		mingzi509: "Account Name",
		// 分行
		fenhang510: "Branch",
		// 金额
		jine511: "Amount",
		// 内容
		neirong512: "Content",
		// 完成
		wanchang513: "Finish",
		// 还有176秒
		shnegyu514: "Remain time ",
		// 转账成功后， 请点击完成按钮， 以便我们尽快处理交易。 感谢您使用我们的服务。
		fuwu515: "After the transfer is successful, please click the Finish button so that we can process the transaction as quickly as possible. Thank you for using our service.",
		// 执行转移命令 -
		zhuanyi516: "Execution of transfer",
		// 访问银行的网上银行或手机银行网站进行转账 -
		fangwen517: "- Access the bank's internet banking or Mobile banking website to make a transfer",
		// 以下内容中的存款代码(GODETOXD8X0ZTK) 自生成后仅在 20 分钟内有效。 -
		youxiao518: "- The deposit code in the content below ",
		jiade5181: "is only valid for",
		jiade5182: "second since it was generated.",
		// 请在下面填写正确的信息以立即更新资金。 -
		dengxin519: "- Please fill in the correct information below to update money immediately.",
		// 注意： 请输入正确的 40,
		// 000 RM 金额， 并选择转账费用“ 人 / 转账单位” 形式以接收准确金额。 如果没有匹配项， 您的订单将被取消。 任何问题请联系客户支持。
		quxiao520: "- Note: Please enter the correct amount of  ",
		quxiao5201: "and choose the form of Transfer Fee “Person/Transfer Unit” to receive the exact amount. If there is no match, your order will be cancelled. Any questions please contact customer support.",
		// 秒
		miao521: "second",
		// -------------------------
		// 内容必须有
		bixu522: "Content",
		// 二维码仅供本次充值使用，请勿保存以备日后充值使用。
		shiyong523: "The QR code is only provided for this recharge, please do not save it for future recharges.",
		// 二维码不能重复用
		chongfu524: "Dont repeat the QR code",
		// 打开QRPay在手机网银APP
		wangyin525: "Open Qrpay on Mobile Banking ",
		// 扫二维码
		erweima526: "Scan QR code",
		// 确认金额完成交易
		wancheng527: "Confirm the amount to finish the transaction.",
		// 复制成功
		fail528: "Copy Successed",
		// 复制失败
		succsee529: "Copy Failed",
		// 复制
		fuzhi530: "Copy",
		// ----------------------------
		// 超时/
		chaoshi531: "Time out",
		// 失败
		shibai532: "Failure",
		// 成功
		chenggong534: "Success",
		result: 'result'
	}
}
