<template>
  <div class="bottom">
    <div class="allBox footer">
      <div>
        <div class="title">{{ $t("m.lianxi40") }}</div>
        <div class="littleTitle" @click="aboutUS">
          {{ $t("m.lianxifangshi41") }}
        </div>
      </div>
      <div class="noneBox">
        <div class="title">{{ $t("m.chanpin42") }}</div>
        <div class="littleTitle" @click="jump(1)">{{ $t("m.juhe43") }}</div>
        <div class="littleTitle" @click="jump(2)">
          {{ $t("m.zishanghu44") }}
        </div>
        <div class="littleTitle" @click="jump(3)">{{ $t("m.dsifu45") }}</div>
      </div>
      <div class="noneBox">
        <div class="title">{{ $t("m.gognsi48") }}</div>
        <div class="littleTitle" @click="aboutUS">{{ $t("m.guanyu46") }}</div>
        <div class="littleTitle" @click="">
          <a :href="'mailto:' + url" id="sendMail">{{ $t("m.jiekou47") }}</a>
        </div>
        <div class="littleTitle">
          <a :href="'mailto:' + url" id="sendMail">{{ $t("m.liuliang49") }}</a>
        </div>
      </div>
      <div class="noneBox">
        <div class="title">{{ $t("m.kaifa50") }}</div>
        <div class="littleTitle" @click="goDemo">{{ $t("m.demo51") }}</div>
        <div class="littleTitle" @click="goDecelop">{{ $t("m.kaifa52") }}</div>
        <div class="littleTitle" @click="goDownload">
          {{ $t("m.xiazai53") }}
        </div>
      </div>
      <!-- <div>
				<div class="title">Nhà phát triển</div>
				<div class="littleTitle">Thanh toán bằng mã QR</div>
			</div> -->
      <div class="PayTheAssistantZ">
        <div class="title erweima">{{ $t("m.sao54") }}</div>
        <img src="../../assets/bottom/telegram.png" class="logoImg" />
        <div class="QRcode">
          <div class="code" style="border-radius: 8px">
            <!-- <div class="qrcode" style="padding:10px;" ref="qrCodeUrl"></div> -->
            <img
              style="width: 100%; border-radius: 8px; height: 100%"
              src="../../assets/index/telegram.png"
            />
          </div>
        </div>
      </div>
      <div class="PayTheAssistantZ">
        <div class="title erweima">{{ $t("m.sao54") }}</div>
        <img src="../../assets/bottom/skype.png" class="logoImg" />
        <div class="QRcode">
          <div class="code">
            <img
              style="width: 100%; border-radius: 8px; height: 100%"
              src="../../assets/index/skype.png"
            />
            <!-- <div class="qrcode" ref="qrCodeUrlskype"></div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="footerTitle">{{ $t("m.banquan55") }}</div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "bottom",
  props: {
    msg: String,
  },
  data() {
    return {
      url: this.apiUrl,
    };
  },
  methods: {
    // 联系我们
    aboutUS() {
      this.$router.push("/aboutUs");
    },
    // creatQrCode() {
    // 	var qrcode = new QRCode(this.$refs.qrCodeUrl, {
    // 		text: 'https://www.score99pay.com',
    // 		width: 85,
    // 		height: 85,
    // 		colorDark: '#000000',
    // 		colorLight: '#ffffff',
    // 		correctLevel: QRCode.CorrectLevel.H
    // 	})
    // },
    // 联系我们
    goAboutUs() {
      // this.$router.push('/aboutUs')
    },
    goDemo() {
      this.$router.push("/pay");
    },
    goDecelop() {
      this.$router.push("/develop");
    },
    goDownload() {
      this.$router.push("/download");
    },
    jump(index) {
      this.$router.push("/product");
      setTimeout(function () {
        let jump = document.querySelectorAll(".anchor");
        // console.log("1")
        let total = jump[index];
        // 实现滚动效果
        total.scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "center", // 上边框与视窗顶部平齐。默认值
        });
      }, 200);
      // this.$router.push({path:'product', query: {jumpIndex: index}})
    },
  },
  mounted() {
    // this.creatQrCode();
  },
};
</script>

<style lang="less" scoped="scoped">
.littleTitle:hover {
  text-decoration: underline;
  opacity: 0.8;
}

.bottom {
  .PayTheAssistantZ {
    position: relative;
    .logoImg {
      position: absolute;
      right: -22px;
      top: 63px;
    }
  }
  .logoImg {
    margin-top: -20px;
    width: 40px;
    margin-bottom: 10px;
  }

  background: #1f2a68;

  .footer {
    display: flex;
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    line-height: 28px;
    justify-content: space-around;
    padding: 50px 0;
    text-align: center;

    .title {
      margin-bottom: 30px;
    }

    .littleTitle {
      font-size: 14px;
      font-family: Helvetica-Light, Helvetica;
      font-weight: 300;
      color: #ffffff;
      line-height: 28px;
      margin-top: 12px;
      cursor: pointer;

      a {
        color: #ffffff;
      }
    }
  }

  .QRcode {
    width: 120px;
    height: 120px;
    background: #424d8c;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;

    .code {
      width: 100px;
      height: 100px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .footerTitle {
    font-size: 14px;
    font-family: Helvetica-Light, Helvetica;
    font-weight: 300;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
    height: 50px;
    background: #144ec6;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
