<template>
  <div id="app">
    <!-- <cellphone></cellphone> -->
    <!-- <HelloWorld></HelloWorld> -->
    <router-view />
    <!-- <van-button type="danger">危险按钮</van-button> -->
    <!-- <join></join> -->
    <bottom v-if="$route.meta.isShow"></bottom>
    <div class="flelBox" v-if="showMessageTalk">
      <div v-if="$route.meta.isShowAboutUs">
        <img src="./assets/mobile/mess.png" @click="goAboutUs" />
      </div>
      <div style="margin-top: 0.9375rem" v-if="top" @click="toTop()">
        <img src="./assets/mobile/top.png" />
      </div>
    </div>
  </div>
</template>
<script>
// const apiUrl = "http://xxxxxx.iscoser.com";
// export default {
// 	apiUrl
// }
// import { Button } from 'vant';
import { WOW } from "wowjs";
import HelloWorld from "@/components/home/HelloWorld";
import join from "@/components/home/join";
import bottom from "@/components/home/bottom";
import cellphone from "@/components/cellphone/top";
export default {
  // apiUrl,
  components: {
    HelloWorld: HelloWorld,
    join: join,
    bottom: bottom,
    atLink: "",
    cellphone: cellphone,
    // [Button.name]: Button
  },
  data() {
    return {
      menu: [],
      gotop: false,
      top: false,
      showMessageTalk: true,
    };
  },
  watch: {
    $route(to, from) {
      let pathVal = to.path;
      let pathList = [
        "/download",
        "/pay",
        "/develop",
        "/about",
        "/loginAndRegister",
      ];
      if (pathList.indexOf(pathVal) !== -1) {
      } else if (pathList == "/") {
        this.toTop();
      } else {
        localStorage.setItem("userId", 0);
      }
      if (pathVal == "/payflow" || pathVal == "/transfer") {
        this.showMessageTalk = false;
      }
    },
  },
  mounted() {
    this.atLink = window.location.href;
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
    // this.$nextTick(() => {
    // 	// 在dom渲染完后,再执行动画
    // 	const wow = new WOW({
    // 		live: false,
    // 		// boxClass: ‘wow‘,
    // 		// animateClass: ‘animated‘,
    // 		offset: 300,
    // 		mobile: true,
    // 		live: true
    // 	})
    // 	wow.init()
    // })
    var THIS = this;
    window.addEventListener("scroll", function () {
      //监听滚动
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 为了兼容
      if (scrollTop >= 108) {
        //如果当前滚动位置大于您的所需要达到的高度则显示置顶元素，反之不显示
        THIS.top = true;
      } else {
        THIS.top = false;
      }
    });
  },
  methods: {
    jump(index) {
      let jump = document.querySelectorAll(".allBox");
      // 获取需要滚动的距离
      let total = jump[index].offsetTop;
      // Chrome
      document.body.scrollTop = total;
      // Firefox
      document.documentElement.scrollTop = total;
      // Safari
      window.pageYOffset = total;
    },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    goAboutUs() {
      this.$router.push("/aboutUs");
    },
  },
};
</script>
<style lang="less">
.el-table .cell {
  white-space: pre-line !important;
}

html,
body,
#app {
  min-height: 100vh;
  overflow-x: hidden;
}

.el-table td div,
.el-table .cell {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  -ms-user-select: text;
}

.develop {
  tr {
    // display: none;
  }
}

.flexBox {
  // flex布局居中
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "./common/css/media.less";
@import "./common/css/cartoon.less";

.el-carousel__container {
  height: 400px;
}

span,
div {
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}

img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.flelBox {
  display: none;
}

body {
  padding: 0;
  margin: 0;
}

// @import './common/css/payType1.less';
// @media screen and (max-width: 500px) {
// 	#app{
// 		background-color: lightblue;
// 	}
// }

// @media screen and (max-width: 750px) {
//     // body {
//     //     background-color:lightblue;
//     // }
// 	import
// }
#app {
  position: relative;

  .btnBox {
    margin-top: 25px;

    .el-button {
      border-radius: 10px;
      padding: 10px 72px;
      // border: 1px solid #7180A1;
    }
  }

  .btnFocus {
    cursor: pointer;
  }
}

.isActiveZq {
  color: #1f2a68 !important;
}

.menu {
  padding: 0 13px;

  .pTitle {
    // width: 180px;
    height: 50px;
    background: #ffffff;
    border-radius: 10px;
    font-size: 14px;
    font-family: Helvetica;
    color: #1f2a68;
    line-height: 28px;
    display: flex;
    // justify-content: center;
    align-items: center;
  }
}

.allBox {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  // min-width: 1200px;
  margin: 0 auto;
}

.body {
  .is-active {
    .el-carousel__button {
      background: #1f2a68 !important;
    }
  }

  .el-carousel__button {
    background: #b6bed3;
  }

  .el-carousel__arrow--right,
  .el-carousel__arrow--left {
    display: none;
  }
}

// @media screen and (max-width: 1200px) {
//     #app {
//       max-width: 1200px;
//       min-width: 1200px;
//     }
// }
</style>
