<template>
  <div class="body">
    <div class="clientele">
      <div>{{ $t("m.payment") }}</div>
      <div class="p1">MULTIPLE PAYMENTS</div>
      <div class="alwaysBox">
        <div class="leftBtn" @click="prev">
          <img src="../../assets/index/left.png" />
        </div>
        <div class="rightBtn" @click="next">
          <img src="../../assets/index/right.png" />
        </div>
        <template>
          <div class="title">{{ $t("m.high") }}</div>
          <van-swipe
            class="my-swipe bannerzq"
            :autoplay="5000"
            indicator-color="#1f2a68"
            @change="onChange"
          >
            <van-swipe-item v-for="item in parseInt(payType.totalPage)">
              <div class="allBoxclient">
                <div
                  class="imgBox"
                  v-if="payType"
                  v-for="items in payType.list"
                >
                  <img style="width: 200%" :src="items.img_url" />
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
          <el-carousel
            id="showBanner"
            ref="img"
            :interval="5000"
            arrow="always"
            height="300px"
            @change="onChange"
            :autoplay="autoplay"
          >
            <el-carousel-item
              v-for="item in parseInt(payType.totalPage)"
              :key="item"
            >
              <v-touch
                v-on:swipeleft="swiperleft"
                v-on:swiperight="swiperright"
                class="wrapper"
              >
                <div class="allBoxclient">
                  <div
                    v-if="payType"
                    class="imgBox"
                    v-for="items in payType.list"
                  >
                    <img :src="items.img_url" />
                  </div>
                </div>
              </v-touch>
            </el-carousel-item>
          </el-carousel>
          <div style="height: 40px" class="showBannerBttom"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/pageRequest/home"; //引入API
import { WOW } from "wowjs";
export default {
  name: "clientele",
  props: {
    msg: String,
  },
  data() {
    return {
      autoplay: true,
      ac: "../../assets/index/banner/checkout.png",
      linkImg: "",
      payType: {
        totalPage: 0,
      },
    };
  },
  methods: {
    prev() {
      this.$refs.img.prev();
      this.getType(index);
    },
    next() {
      this.$refs.img.next();
    },
    swiperleft() {
      // console.log("左划")
      this.$refs.img.next();
    },
    swiperright() {
      // console.log("右滑")
      this.$refs.img.prev();
    },
    // 获取支付商类别
    getType(index) {
      var that = this;
      var num = index;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("page", num);
      data.append("pagesize", "10");
      API.getProduct(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.payType = res.data.data;
            console.log(this.payType);
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChange(index) {
      // console.log('当前 Swipe 索引：' + index);
      var index = index + 1;
      this.getType(index);
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        live: false,
        // boxClass: ‘wow‘,
        // animateClass: ‘animated‘,
        offset: 300,
        mobile: true,
        live: true,
      });
      wow.init();
    });
    this.getType(1);
  },
};
</script>

<style lang="less" scoped="scoped">
.banner,
.showBannerBttom,
.bannerzq {
  display: none;
}

.imgBox:hover {
  // margin-top: -10px;
  // display: none;
  // width:170px !important;
  // height:110px;
  cursor: pointer;
  box-shadow: 0px 0px 20px #e5e5e5;
  z-index: 100;
}

.body {
  padding: 50px 0;
  background: #ebeeff;

  .clientele {
    width: 100%;
    height: auto;
    background-image: url(../../assets/index/map@2x.png);
    background-size: 100% 100%;
    background-color: #ebeeff;

    div {
      text-align: center;
      font-size: 35px;
      font-family: Helvetica-Light, Helvetica;
      font-weight: 300;
      color: #1f2a68;
      line-height: 42px;
      padding: 0;
    }

    .p1 {
      font-size: 20px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #a6b0c9;
      line-height: 28px;
      letter-spacing: 2px;
      margin-top: 12px;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    // background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    // background-color: #d3dce6;
  }

  .alwaysBox {
    position: relative;
    width: 1000px;
    height: 550px;
    background: #ffffff;
    box-shadow: 0px 5px 15px 0px rgba(55, 65, 93, 0.15);
    border-radius: 20px;
    // overflow: hidden;
    margin: 0 auto;
    margin-top: 43px;

    .title {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
      padding: 66px 50px;
    }

    .imgBox {
      box-sizing: border-box;
      width: 150px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .allBoxclient {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 10px 25px;
    }

    .leftBtn {
      position: absolute;
      top: 50%;
      left: -62px;
      margin-top: -32px;
      cursor: pointer;

      img {
        width: 36px;
        height: 64px;
      }
    }

    .rightBtn {
      position: absolute;
      right: -62px;
      top: 50%;
      margin-top: -32px;
      cursor: pointer;

      img {
        width: 36px;
        height: 64px;
      }
    }
  }
}
</style>
