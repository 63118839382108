<template>
	<div style="width: 100%;" class="media">
		<div class="cellphoneTop">
			<div class="right">
				<img src="../../assets/index/top.png" />
				<span style="font-size: 1.4rem;">RGPay</span>
			</div>
			<div>
				<div class="login">登陆</div>
				<van-icon @click="showPopup" name="wap-nav" size="2.5rem" color="#4A5D87" />
			</div>
		</div>
		<div class="bgBox">
			<img src="../../assets/index/bg.png" />
		</div>
		<div>
			<van-popup position="top" v-model="show">内容</van-popup>
		</div>
	</div>

</template>

<script>
	// import  {Button}  from 'vant';
	export default {
		name: "cellphone",
		props: {
			msg: String,
		},
		components: {
			// [Button.name]: Button
		},
		data() {
			return {
				input: '',
				show: false,
			}
		},
		methods: {
			showPopup() {
				this.show = true;
			},
		}
	}
</script>

<style lang="less">
	.media {
		display: none;
	}

	.bgBox {
		img {
			width: 100%;
			height: auto;
		}

		z-index: -1;
		position: absolute;
		top: 0;
		display: block;
		padding: 0 0.9375rem;
	}

	.cellphoneTop {
		display: none;
		align-items: center;
		height: 60px;

		span {
			font-size: 1.5625rem;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #1f2a68;
		}

		img {
			width: 1.5625rem;
			height: 1.5625rem;
			margin-right: 0.625rem;
			object-fit: cover;
		}

		.login {
			width: 6.25rem;
			height: 1.875rem;
			border-radius: 5px;
			border: 1px solid #4A5D87;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.875rem;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #1f2a68;
			margin-right: 0.625rem;
		}
	}
</style>
